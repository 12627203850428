import { useState } from 'react';
import './dropdown-name.css';
import { Button } from '../shared/Button';


export const DropDownPolygonName = ({ onClick }) => {
  const [value, setValue] = useState("");
  return (
    <div className="dropdown-polygon">
      <div className="dropdown-polygon-arrow">
        <div className="dropdown-polygon-icon"></div>
        <div style={{ width: '14px' }}></div>
      </div>
      <div className="dropdown-polygon-content dropdown-name-content">
        <input type="text" value={value} onChange={e => setValue(e.target.value)} className="dropdown-name-input" />
        <Button text={'OK'} 
          onClick={() => {
            setValue("");
            onClick(value);
          }}
        />
      </div>
    </div>
  );
}
