import './styles';
import { BottomButtom, CustomSwitch, ModelBottomSheet } from '../../../components';
import { close, success, exported, warning, arrowHeadRight, arrowHeadLeft, ArrowHeadLeft, ArrowHeadRight } from '../../../assets';
import { useEffect, useState } from 'react';
import {useAppBloc} from "../../../contexts/AppContext";
import { Button } from '../../../components/shared/Button';
import { UserService } from '../../../services/UserService';
import moment from "moment";
import { USER_ROLE, USER_ROLE_OPTIONS } from '../../../contants';
import { Select } from '../../../components/shared/Select';
import { exportToCSV } from '../../../utils';

let ListAccountManagementScreen = [
    {
        index: 1,
        userName: 'Trần Thanh Lộc',
        accountName: 'admin',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true,
        role: 'Vận hành'
    },
    {
        index: 2,
        userName: 'Trần Thanh Lộc',
        accountName: 'user',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true,
        role: 'Giám sát'
    },
    {
        index: 3,
        userName: 'Trần Thanh Lộc',
        accountName: 'user',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true, role: 'Giám sát'
    },
    {
        index: 4,
        userName: 'Trần Thanh Lộc',
        accountName: 'admin',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true, role: 'Vận hành'
    },
    {
        index: 5,
        userName: 'Trần Thanh Lộc',
        accountName: 'user',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true, role: 'Giám sát'
    },
    {
        index: 6,
        userName: 'Trần Thanh Lộc',
        accountName: 'user',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true, role: 'Giám sát'
    },
    {
        index: 7,
        userName: 'Trần Thanh Lộc',
        accountName: 'user',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true, role: 'Giám sát'
    },
    {
        index: 8,
        userName: 'Trần Thanh Lộc',
        accountName: 'user',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true, role: 'Giám sát'
    },
    {
        index: 9,
        userName: 'Trần Thanh Lộc',
        accountName: 'user',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true, role: 'Giám sát'
    },
    {
        index: 10,
        userName: 'Trần Thanh Lộc',
        accountName: 'user',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true, role: 'Giám sát'
    },
    {
        index: 11,
        userName: 'Trần Thanh Lộc',
        accountName: 'user',
        email: 'locthanhtran@gmail.com',
        phoneNumber: '0101010001',
        createAt: '05/06/2021',
        islock: true, role: 'Giám sát'
    },
];

function InputFormFieldAddAccount({ title, value, onChangeValue, type = "text" }) {
    const [_value, _setValue] = useState(value);
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'start', gap: '14px', alignItems: 'center' }}>
            <span className='title-overview-info-commonmanagementpage' style={{ width: '142px' }}>
                {title}
            </span>
            <input value={_value} onChange={(event) => {
                onChangeValue(event.target.value);
                _setValue(event.target.value);
            }} type={type} className='input-form-commonmanagementpage' style={{ width: '388px' }} />
        </div>
    );
}

function SelectorFormAddAccount({ title, roles }) {
    const [isSelect, onSelect] = useState(false);
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'start' }}>
            <span className='title-overview-info-commonmanagementpage' style={{ width: '142px', textAlign: 'end' }}>
                {title}
            </span>
            <div style={{ width: '14px' }}></div>
            <select className='selector-form-commonmanagementpage'>
                {roles.map((role, index) => {
                    return <option key={index} value={role} style={{ border: '1px solid #D6DFF6' }} >{role}</option>;
                })}
            </select>
        </div>
    );
}

function PopError({ error, isOn }) {
    return (
        <div style={{
            boxSizing: 'border-box',
            display: isOn ? 'flex' : 'none',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0px 0px 0px 16px',
            // gap: '8px',
            width: '100%',
            height: '44px',
            background: '#FFF1F0',
            border: '0.8px solid #FF4D4F',
            borderRadius: '4px',

        }} >
            <img src={close} />
            <div style={{ width: '10px' }} ></div>
            <span
                style={{
                    fontFamily: 'SVN-Gilroy',
                    fontWeight: '400',
                    fontStyle: 'normal',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#F5222D',
                    textAlign: 'start'
                }}
            >{error}</span>
        </div>
    );
}

function ModalBottomSheetAddAccount({ onTapClose, onTapDoit, stationName }) {
    const [error, setError] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [phone, setPhone] = useState('');
    const [role, setRole] = useState('ADMIN');
    const [isShowPopup, setIsShowPopup] = useState(false);

    const handleSubmit = () => {
        if (password !== passwordConfirm) {
            setError("Mật khẩu xác nhận chưa đúng, vui lòng nhập lại !");
        } else {
            setError('');
            onTapDoit({
                name: name,
                email: email,
                username: username,
                password: password,
                phone: phone,
                role: role
            })
        }
    }

    const checkIsDisable = () => {
        return !(name && email && username && password && passwordConfirm && phone);
    }

    return (
        <div className='bottom-sheet-add-account-commonmanagementpage'>
            <span
                style={{
                    fontStyle: 'normal',
                    fontWeight: '700',
                    fontSize: '24px',
                    lineHeight: '32px',
                    textAlign: 'center',
                    color: '#3751FF',
                }}
            >TẠO TÀI KHOẢN</span>
            <PopError error={error} isOn={error !== ""} />
            <InputFormFieldAddAccount title={"Tên người dùng"} value={name} onChangeValue={setName} />
            <InputFormFieldAddAccount title={"Tên tài khoản"} value={username} onChangeValue={setUsername} />
            <InputFormFieldAddAccount title={"Địa chỉ Email"} value={email} onChangeValue={setEmail} />
            <InputFormFieldAddAccount title={"Số điện thoại"} value={phone} onChangeValue={setPhone} />
            <InputFormFieldAddAccount type='password' title={"Mật khẩu"} value={password} onChangeValue={setPassword} />
            <InputFormFieldAddAccount type='password' title={"Xác nhận lại"} value={passwordConfirm} onChangeValue={setPasswordConfirm} />
            <div className='section-assign'>
                <span className='section-assign-label' style={{ width: '136px', flexShrink: '0' }}>Vai trò</span>
                <Select className="w-100" options={USER_ROLE_OPTIONS} value={role} onChange={setRole} />
            </div>
            <div className='section-assign'>
                <span className='section-assign-label'>Gán thiết bị</span>
                <Button className="button-apply" text={'Chọn thiết bị'} onClick={() => setIsShowPopup(true)} />
                {isShowPopup && <PopupSelectDevice setIsShowPopup={() => setIsShowPopup(false)} />}
            </div>
            <div className="modal-sheet-bottom">
                <Button className="button-cancel" text={'Hủy bỏ'} onClick={onTapClose} />
                <Button disabled={checkIsDisable()} className="button-apply" text={'Tạo tài khoản'} onClick={handleSubmit} />
            </div>
        </div>
    );
}

function PopupSelectDevice({ setIsShowPopup }) {
    return (<div className="select-device-wrapper">
        <div className="select-device">
            <div className="select-device-content scrollbar-custom-homepage">
                <div className="select-device-item">
                    <input type='checkbox' />
                    <span className="select-device-item-text">Tên trạm 1</span>
                </div>
                <div className="select-device-item">
                    <input type='checkbox' />
                    <span className="select-device-item-text">Tên trạm 2</span>
                </div>
                <div className="select-device-item">
                    <input type='checkbox' />
                    <span className="select-device-item-text">Tên trạm 3</span>
                </div>
                <div className="select-device-item">
                    <input type='checkbox' />
                    <span className="select-device-item-text">Tên trạm 4</span>
                </div>
                <div className="select-device-item">
                    <input type='checkbox' />
                    <span className="select-device-item-text">Tên trạm 5</span>
                </div>
                <div className="select-device-item">
                    <input type='checkbox' />
                    <span className="select-device-item-text">Tên trạm 6</span>
                </div>
            </div>
            <div className='select-device-bottom'>
                <Button className="button-cancel" text={'Hủy bỏ'} onClick={setIsShowPopup} />
                <Button className="button-apply" text={'OK'} onClick={setIsShowPopup} />
            </div>
        </div>
    </div>)
}

function ItemTitleTableAccountManagement() {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '12px',
            width: '1172px',
            height: '43px',
            opacity: '0.8',
            gap: '24px',
            borderBottom: '1px solid #EFF1F1',
        }}>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '42px', textAlign: 'center' }} >STT</span>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '200px', textAlign: 'center' }} >Tên người dùng</span>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '120px', textAlign: 'center' }} >Tên tài khoản</span>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '250px', textAlign: 'center' }} >Email</span>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '120px', textAlign: 'center' }} >Số điện thoại</span>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '100px', textAlign: 'center' }} >Vai trò</span>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '80px', textAlign: 'center' }} >Ngày cấp</span>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '80px', textAlign: 'center' }} >Mở / Khóa</span>
        </div>
    );
}

function ItemContentTableAccountManagement({ index, userName, accountName, email, phoneNumber, role, createAt, isLock }) {
    const [lock, setLock] = useState(isLock);
    return (
        <>
            <div className='row-table-report' style={{ width: '1172px'}}>
                {/* <span className="item-content-table-report-commonmanagementpage" style={{ width: '42px' }} >{index}</span> */}
                <span className="item-content-table-report-commonmanagementpage" style={{ width: '42px', textAlign: 'center' }} >{index}</span>
                <span className="item-content-table-report-commonmanagementpage" style={{ width: '200px', textAlign: 'center' }} >{userName}</span>
                <span className="item-content-table-report-commonmanagementpage" style={{ width: '120px', textAlign: 'center' }} >{accountName}</span>
                <span className="item-content-table-report-commonmanagementpage" style={{ width: '250px', textAlign: 'center' }} >{email}</span>
                <span className="item-content-table-report-commonmanagementpage" style={{ width: '120px', textAlign: 'center' }} >{phoneNumber}</span>
                <span className="item-content-table-report-commonmanagementpage" style={{ width: '100px', textAlign: 'center' }} >{USER_ROLE[role]}</span>
                <span className="item-content-table-report-commonmanagementpage" style={{ width: '80px', textAlign: 'center' }} >{moment(createAt).format("DD/MM/YYYY")}</span>
                <div style={{ width: '80px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <CustomSwitch
                        key={index}
                        onColor={'#3751FF'}
                        isOn={lock}
                        handleToggle={() => { setLock(!lock) }}
                        idx={index}
                        idName={"ItemContentTableAccountManagement" + index + email + phoneNumber}
                    />
                </div>
            </div>

        </>

    )
}

function AccountManagement() {
    const [_pageGroupAccount, _setPageGroupAccount] = useState(0);
    const [_listAccountManagement, _setAccountManagement] = useState([]);
    const tempListSearch = [...ListAccountManagementScreen];
    const [show, setShow] = useState(false);
    const [showSusscess, setShowSuccess] = useState(false);
    const { appBloc } = useAppBloc();
    const userService = new UserService();
    const [textSearch, setTextSearch] = useState("");

    // const [tempListSearch, setTempListSearch]= useState([]);
    useEffect(() => {
    //   appBloc.websocket.send(ProtoCreator.getAccountsUserRequest(appBloc.projectId));
      appBloc.session.streamAccountList.subscribe((v) => {
        if (v) {
          console.log("got data: " + JSON.stringify(v));
        //   _setAccountManagement(v);
        }
      });
      appBloc
    }, []);

    useEffect(() => {
        userService.getListUser().then(data => {
            console.log("ADTA", data);
            if (data && data.data) _setAccountManagement(data.data.users);
        })
    }, []);

    return (
        <>
            <div className='management-action'>
                <input
                    value={textSearch}
                    onChange={(e) => { setTextSearch(e.target.value) }}
                    type={'text'}
                    className='input-placerhold-station-search-commonmanagementpage'
                    placeholder={"Nhập tên tài khoản"}
                />
                <div className='btn-export' onClick={() => exportToCSV(_listAccountManagement.filter(item => item.username.includes(textSearch)), `users-${moment().format('DD-MM-YYYY')}`)}>
                    <img src={exported} /> Export
                </div>
                <BottomButtom
                    title={"Thêm mới"}
                    style={{
                        color: '#FFFFFF',
                        width: '132px',
                        height: '42px',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: '133%',
                    }}
                    onTap={() => { setShow(true) }}
                />
            </div>

            {/* table */}
            <div className='scrollbar-homepage-x scrollbar-custom-homepage account-content'>
                <ItemTitleTableAccountManagement />
                {[..._listAccountManagement]?.filter(item => item.username.includes(textSearch))
                    .splice(_pageGroupAccount * 8, 8)
                    .map((acc, i) => {
                // {_listAccountManagement.splice(_pageGroupAccount * 8, 8).map((acc, i) => {
                    return (
                        <ItemContentTableAccountManagement
                            key={i}
                            index={i + 1}
                            userName={acc.name}
                            accountName={acc.username}
                            email={acc.email}
                            phoneNumber={acc.phone}
                            role={acc.role}
                            createAt={acc.createDate}
                            isLock={acc.islock}
                        />
                    );
                })}
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>
                {(_pageGroupAccount >= 1) && <div onClick={() => { _setPageGroupAccount(_pageGroupAccount >= 1 ? _pageGroupAccount - 1 : 0) }}><ArrowHeadLeft color={_pageGroupAccount >= 1 ? '#3751FF' : '#BFBFBF'} /></div>}

                <div style={{ padding: '0px 8px', margin: '0px 8px' }}>
                    <span className='table-pagi-hero'>{((_pageGroupAccount + 1 - 1) === 0) ? "" : (_pageGroupAccount).toString()}</span>
                </div>
                <div className='table-pagi-label'>
                    <span>{(_pageGroupAccount.valueOf() + 1).toString()}</span>
                </div>
                <div style={{ padding: '0px 8px', margin: '0px 8px' }}>
                    <span className='table-pagi-text'>{(_listAccountManagement.length <= (_pageGroupAccount + 1) * 8) ? "" : (_pageGroupAccount + 2).toString()}</span>
                </div>
                {(_listAccountManagement.length > ((_pageGroupAccount + 1) * 8)) && <div onClick={() => { _setPageGroupAccount(_pageGroupAccount + 1) }}><ArrowHeadRight color={'#3751FF'} /></div>}
            </div>

            <ModelBottomSheet
                key={'ItemContentTableStationManagement'}
                animation={true}
                centered={true}
                size={"lg"}
                show={show}
                children={
                    <ModalBottomSheetAddAccount
                        onTapClose={() => { setShow(false) }}
                        onTapDoit={async (data) => {
                            const result = await userService.createUser(data);
                            if (result.code === 0) {
                                data.id = result.data.id;
                                _listAccountManagement.push(data)
                                _setAccountManagement([..._listAccountManagement]);

                                setShowSuccess(true);
                                setTimeout(function () {
                                    setShowSuccess(false)
                                }, 900)
                            }

                            setShow(false);
                           
                        }}
                    // stationName={this.sta}
                    />
                }
            />

            <ModelBottomSheet
                key={'ItemContentTableStationManagementSussess'}
                animation={true}
                centered={true}
                size={"lg"}
                show={showSusscess}
                children={
                    <div className='btn-create-success'>
                        <img src={success} />
                        <div style={{height:'30px'}} ></div>
                        <span>Tạo tài khoản thành công</span>
                    </div>
                }
            />

        </>
    );
}

export { AccountManagement };
