import { useEffect, useRef, useState } from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import TimeKeeper from "react-timekeeper";
import { clock } from "../../assets";
import './input-timer.css';

export const InputTimer = ({title,value,onChange, isDisable = false}) => {
    const [time, setTime] = useState(null);
    const [show, setShow] = useState(false);

    const handleToggle = () => {
        if (!isDisable) {
            setShow((prev) => !prev);
        }
    };

    useEffect(() => {
        if (value) setTime(value);
    }, [value]);

    return(
        <div className="timer-container">
            <span className="timer-title">{title}</span>
            <div className="timer-content">
                <span className="timer-content-label">{time || value}</span>
                    <OverlayTrigger show={show} onToggle={handleToggle} rootClose={true} trigger="click" placement="bottom-start" overlay={
                        <Popover id="popover-schedule" title="Popover bottom">
                            <div className="timer-keeper">
                                <TimeKeeper
                                    time={time === '--:--' ? "00:00" : time}
                                    onChange={(newTime) => {
                                        console.log(newTime)
                                        setTime(newTime.formatted24.toLocaleUpperCase());
                                        onChange(newTime.formatted24.toLocaleUpperCase());
                                    }}
                                    onDoneClick={() => {
                                        handleToggle();
                                    }}
                                    switchToMinuteOnHourSelect
                                />
                            </div>
                        </Popover>
                    }>
                        <img src={clock} />
                    </OverlayTrigger>
            </div>
        </div>
    );
}
