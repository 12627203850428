import ServiceBase from './service-base';

export class DocumentService extends ServiceBase {
  baseUri = '';

  async getDocuments() {
    return (await this.get('/api/document/v1/getList')).data;
  }

  async createDocument({ name, url }) {
    url = url.startsWith('http') ? url : `${process.env.REACT_APP_API_BASE_URL}${url}`;
    return (await this.post('/api/document/v1/create', { name, url })).data;
  }

  async deleteDocument(id) {
    return (await this.delete('/api/document/v1/delete/' + id)).data;
  }
}
