import ServiceBase from './service-base';

export class ScheduleService extends ServiceBase {
  baseUri = '';

  async getScheduleById(id) {
    return (await this.get('/api/schedule/v1/load/' + id)).data;
  }

  async updateSchedule(obj) {
    return (await this.put('/api/schedule/v1/updateSchedule', obj)).data;
  }
}
