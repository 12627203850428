import './styles';
import {arrowRight, searchEmpty} from '../../../assets';
import { CustomSwitch } from '../../../components';

import { useState } from 'react';
import { useAppBloc } from '../../../contexts/AppContext';
import * as ProtoCreator from '../../ProtoCreator';
import { Image } from 'react-bootstrap';
import { LabelStatus } from '../../../components/ControlPannelPage/LabelStatus';


function ItemStation({ id, idx, name, state, onSetItemList, onSelectItem, currentStation, highlightText }) {
  const [value, setValue] = useState(state);
  const {appBloc} = useAppBloc();

  const getHighlightedText = (text, highlight) => {
    // Split on highlight term and include term into parts, ignore case
    const parts = text?.split(new RegExp(`(${highlight})`, 'gi'));
    return <span> { parts?.map((part, i) => 
      <span key={i} className={part === highlight ? 'high-light-text' : '' }>
        { part }
      </span>)
    } </span>;
  }

  return(
    <div className={`item-station-controlpanelpage ${currentStation === idx ? 'active' : null}`}
      onClick={() => {
        onSelectItem(idx);
        console.log("SELE", id)
        // appBloc.websocket.send(ProtoCreator.CreateGetStationUserRequest("11ECC5FB6CA2ECBF9B16005056034B6A"));
      }}
    >
      <span className="item-station-name">{getHighlightedText(name, highlightText)}</span>
      <div className="d-flex align-items-center justify-content-between">
        <span className="item-station-subname">{name}</span>
        <LabelStatus value={value} />
      </div>
    </div>
  );
}

function ListStationControlPanel({ listData, onSetItemList, onSelectItem, currentStation, highlightText }) {
  return(
    <div className='scrollbar-homepage scrollbar-custom-homepage h-100'
      style={{
        width: '100%',
        maxHeight: '660px',
        paddingRight: '10px',
      }}>
        {listData.length ? listData.map((item, idx) => {
          if (item !== null){
            return <ItemStation highlightText={highlightText} key={item.id} id={item.id} currentStation={currentStation} idx={idx} onSelectItem={onSelectItem} onSetItemList={onSetItemList} name={item.stationName} state={item.online} />
          }
        }) : 
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <Image src={searchEmpty} />
            <nav>Không tìm thấy dữ liệu !</nav>
          </div>
        }
    </div>
  );
}
export { ListStationControlPanel };