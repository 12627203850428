import { useContext, useEffect, useState } from 'react';
import { EyeClose, EyeOpen, success } from '../../../assets';
import { UserService } from '../../../services/UserService';
import './styles';
import moment from "moment";
import { ModelBottomSheet } from '../../../components';
import { AppContext } from '../../../contexts/AppContext';
import { NoteService } from '../../../services/NoteService';
import { ReactSVG } from "react-svg";

function FormFieldProfileInfo({title,hint,type,value,onChangeValue, disabled = false}){
    return(
        <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
            <span className='title-profile-info-profilepage'>
                {title}
            </span>
            <div style={{height:'10px'}}></div>
            <input disabled={disabled} type={type} value={value} onChange={(event)=>onChangeValue(event.target.value)} className='input-form-profile-info-profilepage' placeholder={hint} color={'#172B4D'} />
        </div>
    );
}

function CalenderFieldProfileInfo({title,value,onChangeValue,hint}){
    return(
        <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
            <span className='title-profile-info-profilepage'>
                {title}
            </span>
            <div style={{height:'10px'}}></div>
            <input value={value} placeholder={hint} onChange={(value)=>onChangeValue(value.target.value)} type={'date'} min="2022-01-01" max="2029-12-31" className='input-form-profile-info-profilepage' color={'#172B4D'} />
        </div>
    );
}

function PassWordFieldProfileInfo({title,value,onChangeValue}){
    const [isFocus,onFocus] = useState(false);
    const [isHide,onHide] = useState(true);

    let icon = (!isHide ? <EyeClose fill={isFocus ? '#3751FF' : '#9FA2B4'} /> : <EyeOpen fill={isFocus ? '#3751FF' : '#9FA2B4'} />);
    return(
        <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'center'}}>
            <span className='title-profile-info-profilepage' style={{width:'150px',textAlign:'start'}}>
                {title}
            </span>
            <div style={{width:'10px'}}></div>
            <input value={value} onChange={(event)=>onChangeValue(event.target.value)} onFocus={()=>{onFocus(true)}} onBlur={()=>{onFocus(false)}} type={isHide?'password':'text'} className='password-form-profile-info-profilepage'style={{width:'467px'}}  />
            <button className='hide-password-button-loginpage' style={{ marginLeft: '-42px', marginTop: '0px', float: 'left' }} onClick={()=>{onHide(!isHide)}} type='button'>
                {<i className={value ? 'visible': 'invisible'}>{icon}</i>}
            </button>
        </div>
    );
}

function ProfileInfo({fullName,email,phoneNumber,role,createAt}) {
    const [currentPass,onChangeCurrentPass] = useState("");
    const [newPass,onChangeNewPass] = useState("");
    const [confirmPass,onChangeConfirmPass] = useState("");
    const [showSusscess, setShowSusscess] = useState(false);
    const userService = new UserService();

    const [user, setUser] = useState(null);
    const { fileAvatar, setAvatar } = useContext(AppContext);
    const noteService = new NoteService();

    useEffect(() => {
        userService.getUserInfo().then(res => {
            console.log(res);
            if (res && res.data) setUser(res.data);
        });
    }, []);

    const onTapSave = () => {
        userService.updateUser(user).then(res => {
            console.log(res)
            setShowSusscess(true);
            setTimeout(() => {
                setShowSusscess(false);
            }, 900);
        })
        if (currentPass && newPass && confirmPass && newPass === confirmPass) {
            userService.updatePassword({ currentPassword: currentPass, newPassword: newPass }).then(res => {
                onChangeCurrentPass("");
                onChangeNewPass("");
                onChangeConfirmPass("");
                setShowSusscess(true);
                setTimeout(() => {
                    setShowSusscess(false);
                }, 900);
            })
        }

        if (fileAvatar) {
            noteService.uploadImage(fileAvatar).then(res => {
                console.log("updalodaa", res);
                if (res && res.data) {
                    userService.updateAvatar(res.data.url).then(res => {
                        console.log("updalodaa", res);
                        if (res && res.data) {
                            setAvatar(res.data.avatar);
                        }
                    });
                }
            });
        }
    }

    return(
        <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
            <span className='heading-profile-info-profilepage' style={{textAlign:'start'}} >
                Thông tin tài khoản
            </span>

            <div style={{height:'34px'}} ></div>
            
            <FormFieldProfileInfo type={'text'} onChangeValue={(value) => setUser({...user, name: value})} value={user?.name} title={'Họ và tên'} hint={fullName}/>

            <div style={{height:'20px'}} ></div>

            <div style={{display:'flex',flexDirection:'row', gap: '20px'}} >
                <FormFieldProfileInfo value={user?.email} onChangeValue={(value) => setUser({...user, email: value})} type={'email'} title={'Địa chỉ email'} hint={email}/>
                <FormFieldProfileInfo value={user?.phone} onChangeValue={(value) => setUser({...user, phone: value})} type={'tel'} title={'Số điện thoại'} hint={phoneNumber}/>
            </div>

            <div style={{height:'20px'}} ></div>
            <div style={{display:'flex',flexDirection:'row', gap: '20px'}} >
                <FormFieldProfileInfo disabled value={user?.role} onChangeValue={() => {}} type={'text'} title={'Vai trò'} hint={role}/>
                <FormFieldProfileInfo disabled value={moment(user?.createDate).format("DD/MM/YYYY")} onChangeValue={() => {}} type={'text'} title={'Ngày tạo'} hint={role}/>
                {/* <CalenderFieldProfileInfo value={user?.createDate} onChangeValue={onChangeUserCreate} title={'Ngày tạo'} hint={createAt}/> */}
            </div>

            <div style={{height:'34px'}} ></div>
            <span className='heading-profile-info-profilepage' style={{textAlign:'start'}}>
                Đổi mật khẩu
            </span>

            
            <div style={{height:'34px'}} ></div>
            <PassWordFieldProfileInfo value={currentPass} onChangeValue={onChangeCurrentPass} title={'Mật khẩu hiện tại'}/>
            <div style={{height:'20px'}} ></div>
            <PassWordFieldProfileInfo value={newPass} onChangeValue={onChangeNewPass}  title={'Mật khẩu mới'}/>
            <div style={{height:'20px'}} ></div>
            <PassWordFieldProfileInfo value={confirmPass} onChangeValue={onChangeConfirmPass}  title={'Xác nhận lại'}/>

            <div style={{height:'20px'}} ></div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <button 
                    onClick={onTapSave}
                    type='button' 
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '12px 71px',
                        backgroundColor: '#3751FF',
                        borderRadius: '4px',
                        borderWidth:'0px',
                    }} 
                >
                    <span
                        style={{
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '133%',
                            color: '#FFFFFF',
                        }}
                    >Lưu lại</span>
            </button>
            </div>
            
            <ModelBottomSheet
                key={'be'}
                animation={true}
                centered={true}
                size={"lg"}
                show={showSusscess}
                children={
                    <div className='btn-create-success'>
                        <ReactSVG src={success} />
                        <div style={{height:'30px'}} ></div>
                        <span>Cập nhật thành công</span>
                    </div>
                }
            />
        </div>

    );
}
export {ProfileInfo};