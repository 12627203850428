import React, {useEffect, useState} from 'react';
import './styles';
import { dragDrop, info, close, taskListDocumentText, clock } from '../../../assets';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { CustomSwitch } from '../../../components';
import {useAppBloc} from "../../../contexts/AppContext";
import { InputTimer } from '../../../components/SchedulePage/InputTimer';
import { Button } from '../../../components/shared/Button';
import { FormList } from '../../../components/SchedulePage/FormList';


const strategyInWeek = [
  { id: 0, name: 'A' },
  { id: 1, name: 'B' },
  { id: 2, name: 'C' },
  { id: 3, name: 'D' },
  { id: 4, name: 'E' },
  { id: 5, name: 'F' },
  { id: 6, name: 'G' },
  { id: 7, name: 'H' },
];

function filterSelectedStrategy(schedule, index) {
  const res = [];
  const timeForm = schedule?.timeForm;
  if (!timeForm || index < 0) {
    return res;
  }
  if (timeForm.length <= index) {
    return res;
  }
  const target = timeForm[index];
  const oneTime = target?.oneTime;
  if (!oneTime) {
    return res;
  }
  oneTime.forEach(
    (data, idx) => {
      res.push({
        name: 'Biểu mẫu ' + (data?.target + 1),
        startTime: {
          hour: data?.start?.split(':')[0],
          minute: data?.start?.split(':')[1]
        },
        enfor: data.isEnable
      })
    }
  )

  return res;
}

function StrategyWithTime({ timeForms, cycleForms, setTimeForms }) {
  const [schedule, setSchedule] = useState();
  const { appBloc } = useAppBloc();
  const [indexTime, setIndexTime] = useState(0);
  // const [listDataIndate, onChangeIndexDataIndate] = useState([]);
  const [tableInTime, setTableInTime] = useState([]);
  const [data, setData] = useState();

  useEffect(() => {
    if (timeForms && timeForms.length) setTableInTime(timeForms[indexTime]?.cycleForms);
  }, [timeForms, indexTime]);

  // useEffect(() => {
  //   appBloc.session.streamSchedule.subscribe((v) => {
  //     setSchedule(v);
  //     let tableData = filterSelectedStrategy(v, 0);
  //     setIndexTime(0);
  //     setTableInTime(tableData);
  //     setData(v?.timeForm);
  //   });
  // }, []);

  const strategyList = [];
  for (let i = 0; i < 32; i++) {
    strategyList.push(
      {
        name: 'Biểu mẫu ' + (i + 1),
        startTime: {
          hour: 12,
          minute: 0,
          second: 0,
        },
        enfor: true
      }
    );
  }

  return (
    <div>
      <div className='tab-bar-date-week-strategypage mb-4'>
        {strategyInWeek.map((strategy, idx) => (
          <div
            className={`item-tab-bar-date-week-strategypage ${indexTime === idx ? 'active' : ''}`}
            key={idx} onClick={() => { setIndexTime(idx) }}>
            {strategy.name}
          </div>
        ))}
      </div>

      <DragDropContext onDragEnd={(result) => {
        if (result.source.droppableId === "droppableId-0" && result.destination?.droppableId === "droppableId-0") {
          // let listTemp = [...strategyList];
          // let element = listTemp[result.source.index];
          // listTemp.splice(result.source.index, 1);
          // listTemp.splice(result.destination.index, 0, element);
        }
        if (result.source.droppableId === "droppableId-0" && result.destination?.droppableId === "droppableId-1") {
          let listTemp = [...tableInTime];

          const index = listTemp.findIndex(item => item.activeTime === null);
          if (index === -1) return;

          listTemp[index] = {
            activeTime: "00:00",
            cycleFormIndex: result.source.index,
          }

          timeForms[indexTime].cycleForms = listTemp.splice(0, 8);
          setTimeForms([...timeForms]);
        }
      }}
     >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '20px' }} >
          {/* Left Component */}
          <FormList strategyList={strategyList} cycleForms={cycleForms} />
          {/* Right Component */}
          <div className="m-0 p-0">
            <div className='table-strategy-date-strategypage'>
              <div className="header-strategy-time">
                <span className='title-table-strategy-date-strategypage'>Tên biểu mẫu</span>
                <span className='title-table-strategy-date-strategypage'>Thời gian áp dụng</span>
                <span className='title-table-strategy-date-strategypage'></span>
              </div>
              <Droppable droppableId='droppableId-1'>
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}
                    className={"scrollbar-homepage scrollbar-custom-homepage strategy-time-content"}
                  >
                    {tableInTime.map((item, i) =>
                      <div
                        key={item.toString()}
                        className={`item-detail-srtategy-date-strategypage ${tableInTime[i]?.cycleFormIndex === -1 ? 'empty' : ''}`}
                      >
                        <span>{tableInTime[i]?.cycleFormIndex !== -1 ? 'Biểu mẫu ' + (tableInTime[i]?.cycleFormIndex + 1) : '----'}</span>
                        <div className='strategy-time-group'>
                          <div className='strategy-time-clock'>
                            <InputTimer title={""} value={tableInTime[i]?.activeTime ? `${tableInTime[i].activeTime}`: '--:--'} onChange={(time) => {
                              let listTemp = [...tableInTime];
                              listTemp[i].activeTime = time.padStart(5, '0');
                              timeForms[indexTime].cycleForms = listTemp;
                              setTimeForms([...timeForms]);
                            }} />
                          </div>
                          <div className='strategy-time-close'>
                            <img src={close} onClick={() => {
                              let listTemp = [...tableInTime];
                              listTemp.splice(i, 1);
                              listTemp.push({
                                activeTime: null,
                                cycleFormIndex: -1
                              })
                              timeForms[indexTime].cycleForms = listTemp.splice(0, 8);
                              setTimeForms([...timeForms]);
                              // setTableInTime(listTemp);
                            }} />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
              {/* <div className="footer-strategy-time">
                <Button text={'Áp dụng'} />
              </div> */}
            </div>
          </div>
        </div>
      </DragDropContext>
    </div>
  );
}
export { StrategyWithTime };
