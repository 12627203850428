import './tooltip-form.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { InputRange } from '../shared/InputRange';

export const TooltipTime = ({ data, children }) => {
  const popoverRight = (
    <Popover id="popover-positioned-right" title="Popover bottom" className="popover-schedule">
      <div className="activity-header tooltip-form-header justify-content-start">
        <nav className="activity-text mr-3">Số pha : <span className="color-green">{data?.numPhase}</span></nav>
        <nav className="activity-text">Tổng chu kỳ : <span className="color-green">{data?.total} giây</span></nav>
      </div>
      <div className="activity-content tooltip-form-content scrollbar-homepage scrollbar-custom-homepage">
        {data.phaseCounters.map((phase, idx) => 
          { if (idx >= data?.numPhase) return;
            return <div key={idx} className="activity-item">
              <span className="text-medium">Pha {idx + 1}</span>
              <InputRange className="input-tooltip"
                greenTime={phase.greenTime}
                yellowTime={phase.yellowTime}
                redTime={phase.total - phase.greenTime - phase.yellowTime - phase.startGreen}
                startGreen={phase.startGreen}
                // greenTime={phase.startGreen}
                // yellowTime={phase.startYellow - phase.startGreen}
                // redTime={data?.total - phase.startYellow}
              />
            </div>
          }
        )}
      </div>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="hover" placement="right-start" overlay={popoverRight}>
      {children}
    </OverlayTrigger>
  );
}
