import { warning } from "../../assets";

export const ChildConfirm = ({ title, description, onTapClose, onTapDoit }) => {
    return(
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        maxHeight: '266px',
        backgroundColor:'#FFFFFF',
        borderRadius:'14px',
      }}>
  
        <img src={warning} />
        <span
          style={{
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '20px',
            textAlign: 'center',
            color: '#212633',
          }}
        >{title}</span>
        <span
          style={{
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            textAlign: 'center',
            color: '#212633',
          }}
        >{description}</span>
  
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '30px',justifyContent:'space-between' }} >
          <div className="station-cancel" onClick={onTapClose}>Hủy bỏ</div>
          <div className="station-apply" onClick={onTapDoit}>Thực hiện</div>
        </div>
      </div>
    );
}
