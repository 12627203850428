import './styles';
import { BottomButtom, ModelBottomSheet } from '../../../components';
import { close, exported, ArrowHeadLeft, ArrowHeadRight } from '../../../assets';
import {  useEffect, useState } from 'react';
import {useAppBloc} from "../../../contexts/AppContext";
import { ChildConfirm } from '../../../components/ModelBottomSheet/ChildConfirm';
import { StationService } from '../../../services/StationService';
import moment from "moment";
import { exportToCSV } from '../../../utils';

let ListStationManagementPage = [
  {
    index: 1,
    name: 'Ngã Tư Nguyễn Văn - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
  {
    index: 2,
    name: 'Đường Nguyễn Văn Linh - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
  {
    index: 3,
    name: 'Ngã Tư Nguyễn Văn Trỗi - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
  {
    index: 4,
    name: 'Ngã Tư Phạm Văn Đồng - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
  {
    index: 5,
    name: 'Ngã Tư Nguyễn Văn Linh - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
  {
    index: 6,
    name: 'Ngã Tư Nguyễn Văn Linh - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
  {
    index: 7,
    name: 'Ngã Tư Nguyễn Văn Linh - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
  {
    index: 8,
    name: 'Ngã Tư Nguyễn Văn Linh - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
  {
    index: 9,
    name: 'Ngã Tư Nguyễn Văn Linh - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
  {
    index: 10,
    name: 'Ngã Tư Nguyễn Văn Linh - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
  {
    index: 11,
    name: 'Ngã Tư Nguyễn Văn Linh - Làng Đúc Bưu',
    code: 'GW-eb:a7:71:2f',
    version: '[Jul 14 2021 15:29:06]',
    createAt: '05/06/2021',
  },
];

function ItemTitleTableStationManagement() {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '12px',
      width: '960px',
      height: '43px',
      opacity: '0.8',
      gap: '24px',
      borderBottom: '1px solid #EFF1F1',
    }}>
      <span className="item-title-table-report-commonmanagementpage" style={{ width: '42px' }} >STT</span>
      <span className="item-title-table-report-commonmanagementpage" style={{ width: '300px' }} >Tên trạm</span>
      <span className="item-title-table-report-commonmanagementpage" style={{ width: '120px' }} >Tên thiết bị</span>
      <span className="item-title-table-report-commonmanagementpage" style={{ width: '200px' }} >Phiên bản</span>
      <span className="item-title-table-report-commonmanagementpage" style={{ width: '80px' }} >Ngày tạo</span>
      <span className="item-title-table-report-commonmanagementpage" style={{ width: '68px' }} >Thao tác</span>
    </div>
  );
}

function ItemContentTableStationManagement({ index, stationName, deviceName, version, createAt, onDelete }) {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className='row-table-report'>
        <span className="item-content-table-report-commonmanagementpage" style={{ width: '42px' }} >{index}</span>
        <span className="item-content-table-report-commonmanagementpage" style={{ width: '300px' }} >{stationName}</span>
        <span className="item-content-table-report-commonmanagementpage" style={{ width: '120px' }} >{deviceName}</span>
        <span className="item-content-table-report-commonmanagementpage" style={{ width: '200px' }} >{version}</span>
        <span className="item-content-table-report-commonmanagementpage" style={{ width: '80px' }} >{createAt}</span>
        <div className="item-content-table-report-commonmanagementpage" style={{ width: '68px' }} ><img onClick={() => { setShow(true) }} src={close} /></div>
      </div>


      <ModelBottomSheet
        key={'ItemContentTableStationManagement'}
        animation={true}
        centered={true}
        // size={"sm"}
        show={show}
        children={
          <ChildConfirm
            title={'Xóa trạm'}
            description={`Bạn có chắc chắn rằng muốn thực hiện xóa trạm ${stationName}`}
            onTapClose={() => { setShow(false) }}
            onTapDoit={() => { onDelete(); setShow(false); }}
          />
        }
      />
    </>

  )
}

function StationManagement() {
  const [_pageGroupStation, _setPageGroupStation] = useState(0);
  const [_listStationManagement, _setStationManagement] = useState([]);
  const tempListSearch = [...ListStationManagementPage];
  const { appBloc } = useAppBloc();
  // const [tempListSearch, setTempListSearch]= useState([]);
  const stationService = new StationService();
  const [textSearch, setTextSearch] = useState("");

  useEffect(async () => {
    const res = await stationService.getStations();
    if (res && res.data) {
      _setStationManagement(res.data.stations);
    }
  }, []);
  
  function onSearch(value) {
    if (value.replace(/\s/g, '') !== "") {
      let tempRet = [];
      for (let index = 0; index < tempListSearch.length; index++) {
        if (tempListSearch[index] !== null) {
          let check = tempListSearch[index].name.toLowerCase().search(value.toLowerCase());
          if (check >= 0) {
            tempRet.push(tempListSearch[index]);
          }
        }
      }
      console.log(tempRet);
      _setStationManagement(tempRet);
    } else {
      if (_listStationManagement.length < tempListSearch.length) {
        _setStationManagement(tempListSearch);
      }
    }
  }

  let tempListGroup = [..._listStationManagement].filter(item => item.stationName.includes(textSearch));
  return (
    <>
      <div className='management-action' >
        <input
          onChange={(e) => { setTextSearch(e.target.value) }}
          type={'text'}
          className='input-placerhold-station-search-commonmanagementpage'
          placeholder={"Nhập tên trạm"}
        />
        <div className='btn-export'
          onClick={() => exportToCSV(_listStationManagement.filter(item => item.stationName.includes(textSearch)), `stations-${moment().format('DD-MM-YYYY')}`)}
        ><img src={exported} /> Export</div>
        <BottomButtom
          title={"Thêm trạm"}
          style={{
            color: '#FFFFFF',
            width: '132px',
            height: '42px',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '133%',
          }}
        />
      </div>

      {/* table */}
      <div
        className='scrollbar-homepage-x scrollbar-custom-homepage'
        style={{
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '12px 0px 12px 0px',
          border: '1px solid #EFF1F1',
          borderRadius: '8px',
          scrollbarWidth:'5px',
          width: '100%',
          height:'478px',
        }}
      >
        <ItemTitleTableStationManagement />
        {tempListGroup.splice(_pageGroupStation * 8,8).map((station, i) => {
          return (
            <ItemContentTableStationManagement
              key={i}
              index={i + 1}
              stationName={station.stationName}
              deviceName={station.hardwareId}
              createAt={moment(station.createDate).format("DD/MM/YYYY")}
              version={station.version}
              onDelete={() => {
                console.log("hihis");
                let tempList = [..._listStationManagement];
                tempList.splice(i, 1);
                
                _setStationManagement(tempList);
                if (((_pageGroupStation * 8) >= tempList.length) && (_pageGroupStation!==0)) {
          
                  _setPageGroupStation(_pageGroupStation-1);
                }
                stationService.deleteStation(station.id);
              }}
            />
          );
        })}
      </div>
      
      <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center',width:'100%'}}>
        {(_pageGroupStation >= 1) && <div onClick={() => { _setPageGroupStation(_pageGroupStation >= 1 ? _pageGroupStation - 1 : 0) }}><ArrowHeadLeft color={_pageGroupStation >= 1 ? '#3751FF' : '#BFBFBF'} /></div>}

        <div style={{
          padding: '0px 8px',
          margin: '0px 8px',
        }}>
          <span
            style={{
              width: '22px',
              height: '20px',
              fontWtyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '133%',
              textSlign: 'center',
              color: '#595959',
            }}
          >{((_pageGroupStation + 1 - 1) === 0) ? "" : (_pageGroupStation ).toString()}</span>
        </div>
        <div style={{
          padding:'0px 8px',
          margin:'0px 8px',
          borderLeftWidth:'1px',
          borderLeftStyle:'solid',
          borderLeftColor:'#EFF1F1',
          borderRightWidth: '1px',
          borderRightStyle: 'solid',
          borderRightColor: '#EFF1F1',
        }}>
          <span style={{
            width: '22px',
            height: '20px',
            fontWtyle: 'normal',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '133%',
            textSlign: 'center',
            color: '#3751FF',
          }}>{(_pageGroupStation.valueOf() +1).toString()}</span>
        </div>
        <div style={{
          padding: '0px 8px',
          margin: '0px 8px',
        }}>
          <span
            style={{
              width: '22px',
              height: '20px',
              fontWtyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '133%',
              textAlign: 'center',
              color: '#595959',
            }}
          >{(_listStationManagement.length <= (_pageGroupStation + 1) * 8) ? "" : (_pageGroupStation + 2).toString()}</span>
        </div>
        {(_listStationManagement.length > ((_pageGroupStation + 1) * 8)) && <div onClick={() => { _setPageGroupStation(_pageGroupStation + 1) }}><ArrowHeadRight color={'#3751FF'} /></div>}
      </div>

    </>
  );
}

export { StationManagement };