import React, { useContext, useRef, useState } from 'react';
import '../../styles/profile_page_style.css';

import * as ImgIc from '../../assets/index';
import { ItemOption} from './components';
import { Outlet } from 'react-router-dom';
import {NoteService} from "../../services/NoteService";
import {UserService} from "../../services/UserService";
import { AppContext } from '../../contexts/AppContext';

const listOption = [
  {
    title: "Thông tin tài khoản",
    path: "/setting/profile/information",
  },
  // {
  //     title: 'Lịch sử truy cập',
  //     path: '/setting/profile/history-access'
  // }
];

function ProfilePage({ children }) {
    const [optionIndex, setOptionIndex] = useState(0);
    const refAvatar = useRef(null);
    const { fileAvatar, setFileAvatar, avatar } = useContext(AppContext);

    const handleChangeAvatar = (e) => {
        setFileAvatar(e.target.files[0]);
    }


    return (<div className='drawer-option'>
        <div className='drawer-option-profilepage'>
            {/* avata */}
            <div className='drawer-option-profilepage-avatar'>
                <img className='drawer-option-profilepage-avatar' style={{ width: '156px', height: '156px' }} src={fileAvatar ? URL.createObjectURL(fileAvatar) : (avatar || null)} />
                <div
                    className='drawer-option-profilepage-avatar-image'
                    onClick={() => {
                        refAvatar.current.click();
                    }}
                >
                    <input className='d-none' type='file' onChange={handleChangeAvatar} ref={refAvatar} />
                    <ImgIc.Camera color={'#3751FF'} />
                </div>
            </div>

            {/* List option */}
            {listOption.map((option, index) => {
                return (
                    <ItemOption
                        path={option.path}
                        key={index}
                        title={option.title}
                        onTap={() => { setOptionIndex(index) }}
                        isSelected={index === optionIndex ? true : false}
                    />
                );
            })}
        </div>
        <div className='content-table-profilepage'>
            {children}
            <Outlet />
        </div>
    </div>)
}

export default ProfilePage;
