import Dropdown from 'react-bootstrap/Dropdown';
import './style.css';

function FormDropdown({value, options, onChange}) {
  return (
    <Dropdown onSelect={onChange}>
      <Dropdown.Toggle className="dropdown-label">
        {options.find(item => item.key == value).text}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map(option => (<Dropdown.Item key={option.key} eventKey={option.key}>{option.text}</Dropdown.Item>))}
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default FormDropdown;
