import React from 'react';

import { ItemOption } from './components';
import { Outlet } from 'react-router-dom';

const listOption = [
    // {
    //     title: 'Tổng quan',
    //     path: '/setting/system-setting/overview'
    // },
    {
        title: 'Giao diện',
        path: '/setting/system-setting/display'
    },
    // {
    //     title: 'Cấu hình quyền',
    //     path: '/setting/system-setting/role-setting'
    // }
]

class SystemSettingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            optionIndex: 0,
        };
        this.onSelectOption = this.onSelectOption.bind(this);

    }

    onSelectOption(index) {
        this.setState(state => state.optionIndex = index);
    }

    render() {
        return (<div className='drawer-option'>
            <div className='drawer-option-profilepage'>
                {listOption.map((option, index) => {
                    return (
                        <ItemOption
                            path={option.path}
                            key={index}
                            title={option.title}
                            onTap={() => { this.onSelectOption(index) }}
                            isSelected={(index === this.state.optionIndex) ? true : false}
                        />
                    );
                })}
            </div>
            <div className='content-table-profilepage'>
                <span className='heading-profile-info-profilepage' style={{ textAlign: 'start' }} >
                    Cấu hình hệ thống
                </span>
                <div style={{ height: '34px' }} ></div>
                {this.props.children}
                <Outlet />
            </div>
        </div>)
    }
}

export default SystemSettingPage;
