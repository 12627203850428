import { Form } from "react-bootstrap";
import { EyeClose, EyeOpen } from "../../../assets";
import './style.css';

export const FormPassWord = ({
  title,
  hintText,
  value,
  onChange,
  isHide,
  onTapHidePass,
  onFocus,
  onBlur,
  isFocus,
}) => {
  const icon = isHide ? <EyeClose fill={isFocus ? "#3751FF" : "#9FA2B4"} /> : <EyeOpen fill={isFocus ? "#3751FF" : "#9FA2B4"} />;

  return (
    <div>
      <span className="input-label">{title}</span>
      <div style={{ flexDirection: "row" }}></div>
      <Form.Control
        className="input-form-loginpage"
        style={{ float: "left", paddingRight: "40px" }}
        type={isHide ? "password" : "text"}
        placeholder={hintText}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <button
        className="hide-password-button-loginpage"
        style={{ marginLeft: "-42px", marginTop: "17px", float: "left" }}
        onClick={onTapHidePass}
        type="button"
      >
        <i> {icon}</i>
      </button>
    </div>
  );
}