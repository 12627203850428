import './tooltip-date.css'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

export const TooltipDate = ({ children, data }) => {
    function tConvert(time) {
        if (!time) return "";
        // Check correct time format and split into components
        time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
      
        if (time.length > 1) { // If time format correct
          time = time.slice (1);  // Remove full string match value
          time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
          time[0] = +time[0] % 12 || 12; // Adjust hours
        }
        return time.join (''); // return adjusted time or original string
    }

    const popoverRight = (
        <Popover id="popover-positioned-bottom" title="Popover bottom" className="popover-schedule">
            <div className='tooltip-date'>
                <div className='tooltip-date-content'>
                    {data?.cycleForms?.map((form, idx) => {
                        return form.cycleFormIndex >= 0 ? <div className='tooltip-date-item' key={idx}>
                            <nav className='tooltip-date-text color-green'>{tConvert(form.activeTime)}</nav>
                            <nav className='tooltip-date-text'>Biểu mẫu {form.cycleFormIndex + 1}</nav>
                        </div> :  <div className='tooltip-date-item'>
                            <nav className='tooltip-date-text color-green'>-- : --</nav>
                            <nav className='tooltip-date-text'>--------</nav>
                        </div>
                    })}
                </div>
            </div>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="hover" placement="bottom-start" overlay={popoverRight}>
            {children}
        </OverlayTrigger>
    );
}
