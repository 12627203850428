import React, { useState } from "react";
import { SignalType } from "..";
import './styles';

function DropDownPolygon({ listPrefix, listSuffix, listOption, addAction, rightPolygon }) {
    const [option, setOption] = useState(-1);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end',
                width: '319px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end',
                }}
            >
                <div
                    style={{
                        height: '21px',
                        width: '21px',
                        clipPath: 'polygon(50% 0, 0% 100%, 100% 100%)',
                        backgroundColor: '#FFFFFF',
                    }}
                >
                </div>
                <div
                    style={{
                        width: rightPolygon
                    }}
                >

                </div>
            </div>


            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: '16px 0px',
                    width: '319px',
                    background: '#FFFFFF',
                }}
            >
                {
                    listOption.map((datum, index) => {
                        return (
                            <div
                                onClick={() => { 
                                    setOption(index);  
                                    switch (index) {
                                        case 0:
                                            addAction(SignalType.turnLeft);
                                            break;
                                        case 1:
                                            addAction(SignalType.turnRight);
                                            break;
                                        case 2:
                                            addAction(SignalType.goAhead);
                                            break;
                                        case 3:
                                            addAction(SignalType.countdown);
                                            break;
                                    }
                                }}
                                key={index}
                                className="polygon-item"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        flexGrow: '1',
                                    }}
                                >
                                    <img src={listPrefix[index]} />
                                    <div style={{ width: '14px' }} ></div>
                                    <span>
                                        {listOption[index]}
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        {listSuffix[index]}
                                    </span>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

function DropDownPolygonSetting({ listOption, listAction, rightPolygon }) {
    const [option, setOption] = useState(-1);
    return (
        <div className="dropdown-polygon">
            <div className="dropdown-polygon-arrow">
                <div className="dropdown-polygon-icon"></div>
                <div style={{ width: rightPolygon }}></div>
            </div>
            <div className="dropdown-polygon-content">
                {listOption.map((datum, index) => {
                    return (
                        <div className="dropdown-polygon-item"
                            onClick={() => {
                                setOption(index);
                                listAction[index]();
                            }}
                            key={index}
                        >
                            <span>{listOption[index]}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export { DropDownPolygon, DropDownPolygonSetting };