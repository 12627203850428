export const SCHEDULE_FORM = {
  dateForm: [255, 255, 255, 255, 255, 255, 255],
  timeForm: [
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '06:00',
          isEnable: false,
          target: 0
        },
        {
          start: '18:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '06:00',
          isEnable: false,
          target: 0
        },
        {
          start: '18:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '06:00',
          isEnable: false,
          target: 0
        },
        {
          start: '18:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '06:00',
          isEnable: false,
          target: 0
        },
        {
          start: '18:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    },
    {
      selectedTimeForm: 0,
      oneTime: [
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        },
        {
          start: '12:00',
          isEnable: false,
          target: 0
        }
      ]
    }
  ],
  signalForm: [
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
    {
      greenTime: [20, 25, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 4,
      clearanceTime: 4,
      isModified: false,
    },
    {
      greenTime: [30, 35, 20, 20, 20, 20, 20, 20],
      nSide: 2,
      yellowTime: 5,
      clearanceTime: 5,
      isModified: false,
    },
  ],
  selectedTimeForm: 0,
  selectedSignalForm: 0,
}

// export const SIGN_SETTING_DEFAULT = [
//   {
//     label: {
//       text: 'Tuyến Đinh Tiên Hoàng',
//       x: 68,
//       y: 350,
//     },
//     trafficLight: {
//       channel: 1,
//       x: 220,
//       y: 448
//     },
//     countdown: {
//       channel: 1,
//       x: 350,
//       y: 448
//     },
//     walking: {
//       channel: 1,
//       x: 295,
//       y: 393
//     },
//     railway: {
//       channel: 1,
//       x: 295,
//       y: 338
//     },
//     turnLeft: {
//       channel: 1,
//       x: 350,
//       y: 283
//     },
//     turnRight: {
//       channel: 1,
//       x: 350,
//       y: 393
//     },
//     goAhead: {
//       channel: 1,
//       x: 350,
//       y: 338
//     },
//   },
//   {
//     label: {
//       text: 'Tuyến 2',
//       x: 475,
//       y: 655,
//     },
//     trafficLight: {
//       channel: 1,
//       x: 610,
//       y: 563
//     },
//     countdown: {
//       channel: 1,
//       x: 610,
//       y: 508
//     },
//     walking: {
//       channel: 1,
//       x: 555,
//       y: 563
//     },
//     railway: {
//       channel: 1,
//       x: 500,
//       y: 563
//     },
//     turnLeft: {
//       channel: 1,
//       x: 445,
//       y: 508
//     },
//     turnRight: {
//       channel: 1,
//       x: 555,
//       y: 508
//     },
//     goAhead: {
//       channel: 1,
//       x: 500,
//       y: 508
//     },
//   },
//   {
//     label: {
//       text: 'Tuyến 3',
//       x: 885,
//       y: 350,
//     },
//     trafficLight: {
//       channel: 1,
//       x: 720,
//       y: 248
//     },
//     countdown: {
//       channel: 1,
//       x: 665,
//       y: 248
//     },
//     walking: {
//       channel: 1,
//       x: 720,
//       y: 303
//     },
//     railway: {
//       channel: 1,
//       x: 720,
//       y: 358
//     },
//     turnLeft: {
//       channel: 1,
//       x: 665,
//       y: 303
//     },
//     turnRight: {
//       channel: 1,
//       x: 665,
//       y: 413
//     },
//     goAhead: {
//       channel: 1,
//       x: 665,
//       y: 358
//     },
//   },
//   {
//     label: {
//       text: 'Tuyến 4',
//       x: 485,
//       y: 20,
//     },
//     trafficLight: {
//       channel: 1,
//       x: 402,
//       y: 68
//     },
//     countdown: {
//       channel: 1,
//       x: 402,
//       y: 193
//     },
//     walking: {
//       channel: 1,
//       x: 457,
//       y: 138
//     },
//     railway: {
//       channel: 1,
//       x: 512,
//       y: 138
//     },
//     turnLeft: {
//       channel: 1,
//       x: 457,
//       y: 193
//     },
//     turnRight: {
//       channel: 1,
//       x: 567,
//       y: 193
//     },
//     goAhead: {
//       channel: 1,
//       x: 512,
//       y: 193
//     },
//   }
// ]

export const SIGN_SETTING_DEFAULT = [
  {
    label: {
      text: 'Tuyến Đinh Tiên Hoàng',
      x: 68,
      y: 350,
    },
    trafficLight: {
      channel: 1,
      x: 220,
      y: 448
    },
    countdown: {
      channel: 1,
      x: 350,
      y: 448
    },
    walking: {
      channel: 1,
      x: 295,
      y: 393
    },
    railway: {
      channel: 1,
      x: 295,
      y: 338
    },
    turnLeft: {
      channel: 1,
      x: 350,
      y: 283
    },
    turnRight: {
      channel: 1,
      x: 350,
      y: 393
    },
    goAhead: {
      channel: 1,
      x: 350,
      y: 338
    },
  },
  {
    label: {
      text: 'Tuyến 2',
      x: 475,
      y: 655,
    },
    trafficLight: {
      channel: 1,
      x: 610,
      y: 563
    },
    countdown: {
      channel: 1,
      x: 610,
      y: 508
    },
    walking: {
      channel: 1,
      x: 555,
      y: 563
    },
    railway: {
      channel: 1,
      x: 500,
      y: 563
    },
    turnLeft: {
      channel: 1,
      x: 445,
      y: 508
    },
    turnRight: {
      channel: 1,
      x: 555,
      y: 508
    },
    goAhead: {
      channel: 1,
      x: 500,
      y: 508
    },
  },
  {
    label: {
      text: 'Tuyến 3',
      x: 885,
      y: 350,
    },
    trafficLight: {
      channel: 1,
      x: 720,
      y: 248
    },
    countdown: {
      channel: 1,
      x: 665,
      y: 248
    },
    walking: {
      channel: 1,
      x: 720,
      y: 303
    },
    railway: {
      channel: 1,
      x: 720,
      y: 358
    },
    turnLeft: {
      channel: 1,
      x: 665,
      y: 303
    },
    turnRight: {
      channel: 1,
      x: 665,
      y: 413
    },
    goAhead: {
      channel: 1,
      x: 665,
      y: 358
    },
  },
  {
    label: {
      text: 'Tuyến 4',
      x: 485,
      y: 20,
    },
    trafficLight: {
      channel: 1,
      x: 402,
      y: 68
    },
    countdown: {
      channel: 1,
      x: 402,
      y: 193
    },
    walking: {
      channel: 1,
      x: 457,
      y: 138
    },
    railway: {
      channel: 1,
      x: 512,
      y: 138
    },
    turnLeft: {
      channel: 1,
      x: 457,
      y: 193
    },
    turnRight: {
      channel: 1,
      x: 567,
      y: 193
    },
    goAhead: {
      channel: 1,
      x: 512,
      y: 193
    },
  },
  {
    label: {
      text: 'Tuyến Đinh Tiên Hoàng',
      x: 68,
      y: 350,
    },
    trafficLight: {
      channel: 1,
      x: 220,
      y: 448
    },
    countdown: {
      channel: 1,
      x: 350,
      y: 448
    },
    walking: {
      channel: 1,
      x: 295,
      y: 393
    },
    railway: {
      channel: 1,
      x: 295,
      y: 338
    },
    turnLeft: {
      channel: 1,
      x: 350,
      y: 283
    },
    turnRight: {
      channel: 1,
      x: 350,
      y: 393
    },
    goAhead: {
      channel: 1,
      x: 350,
      y: 338
    },
  },
  {
    label: {
      text: 'Tuyến 2',
      x: 475,
      y: 655,
    },
    trafficLight: {
      channel: 1,
      x: 610,
      y: 563
    },
    countdown: {
      channel: 1,
      x: 610,
      y: 508
    },
    walking: {
      channel: 1,
      x: 555,
      y: 563
    },
    railway: {
      channel: 1,
      x: 500,
      y: 563
    },
    turnLeft: {
      channel: 1,
      x: 445,
      y: 508
    },
    turnRight: {
      channel: 1,
      x: 555,
      y: 508
    },
    goAhead: {
      channel: 1,
      x: 500,
      y: 508
    },
  },
  {
    label: {
      text: 'Tuyến 3',
      x: 885,
      y: 350,
    },
    trafficLight: {
      channel: 1,
      x: 720,
      y: 248
    },
    countdown: {
      channel: 1,
      x: 665,
      y: 248
    },
    walking: {
      channel: 1,
      x: 720,
      y: 303
    },
    railway: {
      channel: 1,
      x: 720,
      y: 358
    },
    turnLeft: {
      channel: 1,
      x: 665,
      y: 303
    },
    turnRight: {
      channel: 1,
      x: 665,
      y: 413
    },
    goAhead: {
      channel: 1,
      x: 665,
      y: 358
    },
  },
  {
    label: {
      text: 'Tuyến 4',
      x: 485,
      y: 20,
    },
    trafficLight: {
      channel: 1,
      x: 402,
      y: 68
    },
    countdown: {
      channel: 1,
      x: 402,
      y: 193
    },
    walking: {
      channel: 1,
      x: 457,
      y: 138
    },
    railway: {
      channel: 1,
      x: 512,
      y: 138
    },
    turnLeft: {
      channel: 1,
      x: 457,
      y: 193
    },
    turnRight: {
      channel: 1,
      x: 567,
      y: 193
    },
    goAhead: {
      channel: 1,
      x: 512,
      y: 193
    },
  }
]

export const STATION_VALUE_DEFAULT = {
  isOnline: false,
  stationId: null,
  deviceId: null,
  walkingMode: 0,
  trainWayMode: 1,
  activeTimeBegin: '00:00',
  activeTimeEnd: '00:00',
  control: {
    lane: 1,
    onOff: false,
  },
  stationInfo: {
    stationName: 'Ngã tư Võ Thị Sáu',
    hardwareId: 'GW-12:23:34:45',
    type: 'DC 24V',
    numPhase: 2,
    isVarient: false,
    cpuTime: '12:34',
    activeTime: '00:00 23:00',
    signSetting: SIGN_SETTING_DEFAULT,
    backgroundSrc: null,
    nCard: 2,
  },
  outputSignal: [0, 0, 0, 0],
  timer: {
    setting: [
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      },
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      },
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      },
      {
        greenTime: -1,
        redTime: -1,
        yellowTime: -1,
        clearanceTime: -1,
      }
    ],
    current:
      [
        {
          index: 1,
          trafficLight: 'green',
          walking: 'red',
          mappingLight: 'green',
          counter: 23,
        },
        {
          index: 2,
          trafficLight: 'red',
          walking: 'green',
          mappingLight: 'red',
          counter: 25,
        },
      ]
  },
  logMessage: "[I] Device reached stable state",
  errorStatus: 0,
  noteList: [],
  diaryText: '',
  accessories: []
};

const ACTION_MODE = {
  NONE: 0,
  MONITOR: 1,
  STATION_CONFIG: 2,
  DIARY: 3,
  SCHEDULE: 4,
}

const SIGN_TYPE = {
  NONE: 0,
  COUNTDOWN: 1,
  TRAFFIC_LIGHT: 2,
  WALKING: 3,
  RAILWAY: 4,
  TURN_LEFT: 5,
  GO_AHEAD: 6,
  TURN_RIGHT: 7,
  LABEL: 8
};

const SAMPLE_DEVICE = [
  {
    name: 'Đèn xanh D100',
    model: 'D100:12:33:22',
    by: 'Sitech',
    posNum: 'Trụ 1',
    position: 'Trên thân',
    installDate: '10/10/2020',
    state: 'Đang hoạt động'
  },
  {
    name: 'Đèn đếm ngược',
    model: 'DN00:12:33:22',
    by: 'Sitech',
    posNum: 'Trụ 2',
    position: 'Tay vươn',
    installDate: '10/10/2020',
    state: 'Đang hoạt động'
  },
]