import {useEffect, useState} from 'react';
import './styles';
import {useAppBloc} from "../../../contexts/AppContext";
import { FormList } from '../../../components/SchedulePage/FormList';
import { InputTimer } from '../../../components/SchedulePage/InputTimer';


let strategyInWeek = [
  {
    date: 'Chủ nhật',
    data:
      [
        {
          name: "Chiến lược trạm số 01",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
      ],
  },
  {
    date: 'Thứ 2',
    data:
      [
        {
          name: "Chiến lược trạm số 01",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
        {
          name: "Chiến lược trạm số 02",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
        {
          name: "Chiến lược trạm số 03",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
        {
          name: "Chiến lược trạm số 04",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
        {
          name: "Chiến lược trạm số 05",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
        {
          name: "Chiến lược trạm số 06",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
        {
          name: "Chiến lược trạm số 07",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
        {
          name: "Chiến lược trạm số 08",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
      ],
  },
  {
    date: 'Thứ 3',
    data:
      [
        {
          name: "Chiến lược trạm số 01",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
        {
          name: "Chiến lược trạm số 02",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
      ],
  },
  {
    date: 'Thứ 4',
    data:
      [
        {
          name: "Chiến lược trạm số 01",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
      ],
  },
  {
    date: 'Thứ 5',
    data:
      [
        {
          name: "Chiến lược trạm số 02",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
      ],
  },
  {
    date: 'Thứ 6',
    data:
      [
        {
          name: "Chiến lược trạm số 01",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
      ],
  },
  {
    date: 'Thứ 7',
    data:
      [
        {
          name: "Chiến lược trạm số 01",
          startTime: {
            hour: 12,
            minute: 0,
            second: 0,
          },
          enfor: true,

        },
      ],
  },
];

function filterSelectedStrategy(schedule, strategyList, index) {
  const res = [];
  console.log(`select strategy: ${schedule} - index: ${index}`)
  const timeStrategy = schedule?.dateForm;
  if (!timeStrategy || index < 0) {
    return res;
  }
  if (timeStrategy.length < index) {
    return res;
  }
  const selected = timeStrategy[index];
  if (selected < 0 || selected > 7) {
    return res;
  }
  res.push(strategyList[selected]);
  return res;
}

function filterData(schedule) {
  const res = [0, 0, 0, 0, 0, 0, 0];
  if (!schedule || !schedule.dateForm) {
    return res;
  }
  if (schedule.dateForm.length !== 7) {
    return res;
  }
  return schedule.dateForm;
}

function StrategyWithDate({ cycleForms, dateForms, timeForms, setDateForms }) {
  const strategyList = [];
  const labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
  const [schedule, setSchedule] = useState();
  const { appBloc } = useAppBloc();
  const [date, onChoosedate] = useState(0);
  const [tableInDate, onUpdateTable] = useState([]);
  const [data, setData] = useState([0, 0, 0, 0, 0, 0, 0]);

  // console.log(timeForms[dateForms[date]?.timeFormIndex]?.cycleForms);
  // console.log(cycleForms)

  const parseToStrategyList = () => {
    return labels.map((item, idx) => ({
      name: 'Giản đồ ' + item,
      index: idx,
    }))
  }

  useEffect(() => {
    appBloc.session.streamSchedule.subscribe((v) => {
      setSchedule(v);
      onChoosedate(0);
      onUpdateTable(filterSelectedStrategy(v, strategyList, 0));
      setData(filterData(v));
    });
  }, []);

  const setIndexDate = (index) => {
    console.log(index);
    console.log(dateForms);
    dateForms[date].timeFormIndex = index;
    setDateForms([...dateForms]);
  }

  return (
    <div>
      <div className='tab-bar-date-week-strategypage'>
        {strategyInWeek.map((strategy, idx) => {
          return (
            <div
              className={`item-tab-bar-date-week-strategypage ${date === idx ? 'active' : ''}`}
              key={idx} onClick={() => { onChoosedate(idx), onUpdateTable(filterSelectedStrategy(schedule, strategyList, idx)) }}>
              {strategy.date}
            </div>
          );
        })}
      </div>

      <div style={{ height: '24px' }} ></div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: '20px' }} >
        {/* Left Component */}
        <FormList strategyList={parseToStrategyList()} isDate={true} timeForms={timeForms} indexSelected={dateForms[date]?.timeFormIndex} setIndexDate={setIndexDate} />
        {/* Right Component */}
        <div className='table-strategy-date-strategypage'>
          <div className="header-strategy-time justify-content-start">
            <span className='title-table-strategy-date-strategypage'>{parseToStrategyList()[dateForms[date]?.timeFormIndex || 0]?.name}</span>
          </div>
          <div className="scrollbar-homepage scrollbar-custom-homepage strategy-date-content">
            <div className="timeline">
              {timeForms[dateForms[date]?.timeFormIndex]?.cycleForms.map((item, idx) => {
                return item.cycleFormIndex >= 0 ?
                  <div key={idx} className="timeline-item" style={{ zIndex: '3'}}>
                    <div className="timeline-item-top">
                      <span>Biểu mẫu {item.cycleFormIndex + 1}</span>
                      <InputTimer title={""} value={item.activeTime} isDisable={true} />
                    </div>
                    <div className="timeline-item-content">
                      <div className="timeline-item-left">
                        {cycleForms[item.cycleFormIndex]?.phaseCounters?.map((phase, idx2) => {
                          if (idx2 >= cycleForms[item.cycleFormIndex]?.numPhase) return;
                          return <div className="timeline-left-content" key={idx2}>
                            <span>Pha {idx2 + 1} :</span>
                            <span className="d-flex align-items-center"><span className='status-green mr-2'></span>{phase.greenTime}s</span>
                            <span className="d-flex align-items-center"><span className='status-yellow mr-2'></span>{phase.yellowTime}s</span>
                            <span className="d-flex align-items-center"><span className='status-red mr-2'></span>{phase.total - phase.greenTime - phase.yellowTime}s</span>
                          </div>
                        })}
                      </div>
                      <div className="timeline-item-right">Giải tỏa : {cycleForms[item.cycleFormIndex]?.clearance}s</div>
                    </div>
                  </div>
                : <div className="timeline-item" style={{ zIndex: '1'}}></div> 
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { StrategyWithDate };
