import './custom-switch-component.css';

function CustomSwitch({ isOn, handleToggle, onColor, idx, idName }) {
    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '51px'}} >
            <input
                checked={isOn}
                onChange={handleToggle}
                className="custom-switch-checkbox-component"
                id={idName + idx}
                type="checkbox"
            />
            <label
                style={{ background: isOn && onColor }}
                className="custom-switch-label-component"
                htmlFor={idName + idx}
            >
                <span className="custom-switch-button-component" />
            </label>
        </div>
    );
};

export default CustomSwitch;