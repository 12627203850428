import { useState } from "react";
import { NoteService } from "../../../services/NoteService";
import './styles';

function ItemConfigBackground({onTapCancel,onTapDone}) {
    const [isChoosen, onChoosen] = useState(true);
    const [_filename, _getFileName] = useState('');
    const [file, setFile] = useState(null);
    const noteService = new NoteService();

    const submitImage = async () => {
        const result = await noteService.uploadImage(file);
        console.log(result);
        if (result && result.data) {
            onTapDone(result.data.url);
        }
    }

    return (
        <div className="modal-overlap">
            <div className="config-bg-container">
                <span
                    style={{
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '24px',
                        lineHeight: '32px',
                        textAlign: 'center',
                        color: '#3751FF',
                    }}
                >
                    Thiết lập hình nền
                </span>
                <div className="my-4" style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'start', alignItems: 'center' }}>
                    <span 
                        className="title-overview-info-controlpanel"
                        style={{width:'142px'}}
                    >
                        Tải ảnh lên
                    </span>
                    <div style={{ width: '14px' }}></div>

                    <div className='custom-file-input-controlpanelpage' style={{ backgroundColor: !isChoosen ? '#BFBFBF' : '#3751FF'}}>
                        <label className='upload' style={{ backgroundColor: !isChoosen ? '#BFBFBF' : '#3751FF', borderRadius:'4px' }}>
                            <input disabled={!isChoosen} type="file" id="image_uploads" name="image_uploads" accept="image/png, image/jpeg"
                                onChange={(e) => {
                                    setFile(e.target.files[0]);
                                    _getFileName(e.target.files[0].name);
                                }}
                            />
                            Chọn tệp
                        </label>
                    </div>
                    <div style={{ width: '10px' }}></div>
                    <span style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'start',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '14px',
                        lineHeight: '133%',
                        textAlign: 'center',
                        textDecorationLine: 'underline',
                        color: !isChoosen ? '#8C8C8C' : '#3751FF',
                        whiteSpace: 'nowrap',
                        width: '300px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}>
                        {_filename}
                    </span>
                </div>
        
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '30px', justifyContent: 'space-between' }} >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '12px 16px',
                            gap: '10px',
                            height: '42px',
                            background: '#FFFFFF',
                            borderRadius: '4px',
                            border: '1px solid #3751FF',
                            width: '262px',
                            cursor: 'pointer',
                        }}
                        onClick={onTapCancel}
                    >
                        <span style={{
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: '20px',
                            textSlign: 'center',
                            color: '#3751FF',
                        }}>Hủy bỏ</span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '8px 16px',
                            gap: '10px',
                            height: '42px',
                            background: '#3751FF',
                            borderRadius: '4px',
                            width: '262px',
                            cursor: 'pointer',
                        }}
                        onClick={submitImage}
                    >
                        <span style={{
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '16px',
                            lineHeight: '20px',
                            textSlign: 'center',
                            color: '#FFFFFF',
                        }}>Áp dụng</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ItemConfigBackground };
