import ServiceBase from './service-base';

export class LicenseService extends ServiceBase {
  baseUri = '';

  async checkLicense(machineId, license) {
    return (await this.post('/api/license/v1/check', {
      machineId: machineId,
      license: license
    })).data;
  }

}
