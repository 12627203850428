import React, { useEffect, useState } from 'react';

import '../../styles/home_page_style.css';
import { bell, menu} from '../../assets/index';
import FormDropdown from '../../components/shared/Dropdown';
import ActivityNotifyList from '../../components/HomePage/ActivityNotify';
import SlideShow from '../../components/HomePage/SlideShow';
import StationTable from '../../components/HomePage/StationTable';
import ActivityTable from '../../components/HomePage/ActivityTable';
import AccountTable from '../../components/HomePage/AccountTable';
import { StatisticService } from '../../services/StatisticService';

function HomePage() {
    const [filter, setFilter] = useState("ACCESS");
    const options = [{ key: "ACCESS", text: "Tài khoản"}, { key: "ACTION", text: "Hành động" }];
    const [phase, setPhase] = useState(null);
    const [activity, setActivity] = useState(null);
    const [account, setAccount] = useState(null);
    const [notification, setNotification] = useState([]);
    const statisticService = new StatisticService();


    useEffect(async () => {
        statisticService.getAllStatistic().then(dataStatistic => {
            if (dataStatistic?.data) {
                setPhase({
                    ndevice2Phase: dataStatistic.data.ndevice2Phase,
                    ndevice3Phase: dataStatistic.data.ndevice3Phase,
                    ndevice4Phase: dataStatistic.data.ndevice4Phase,
                    ndevice5Phase: dataStatistic.data.ndevice5Phase,
                })
                setActivity({
                    nonline: dataStatistic.data.nonline,
                    nwarning: dataStatistic.data.nwarning,
                    noffline: dataStatistic.data.noffline,
                })
                setAccount({
                  noperator: dataStatistic.data.noperator,
                  nviewer: dataStatistic.data.nviewer,
                  nadmin: dataStatistic.data.nadmin,
                });
            }
        });
        
        statisticService.getNotification().then(dataNoti => {
            if (dataNoti?.data) {
                setNotification(dataNoti.data.items.filter(item => !item.content.includes('số 9')) || []);
            }
        })
    }, []);

    return (
        <div className="content-wrapper">
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{display:'flex'}}>
                    <SlideShow />
                <div className='new-activities-list-homepage-tablet' >
                    <div className="d-flex justify-content-between w-100">
                        <div className="d-flex align-items-center">
                            <img src={bell} style={{ marginRight: '8px' }} />
                            <span  className="activity-title">Hoạt động</span>
                        </div>
                        <FormDropdown value={filter} options={options} onChange={setFilter} />
                    </div>
                <ActivityNotifyList />
            </div>
                </div>
                <div className='statistical-table-homepage' >
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <img src={menu} style={{ marginRight: '8px' }} />
                        <span style={{
                            height: '27px',
                            width: '100%',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '20px',
                            lineHeight: '133%',
                            textAlign: 'start',

                            color: '#172B4D'
                        }}>Thống kê</span>
                    </div>
                    <div className="statistical-content">
                        <StationTable data={phase} />
                        <ActivityTable data={activity} />
                        <AccountTable data={account} />
                    </div>
                </div>
            </div>
            <div className='new-activities-list-homepage'>
                <div className="d-flex justify-content-between w-100">
                    <div className="d-flex align-items-center">
                        <img src={bell} style={{ marginRight: '8px' }} />
                        <span className="activity-title">Hoạt động</span>
                    </div>
                    <FormDropdown value={filter} options={options} onChange={setFilter} />
                </div>
                <div className="h-line-16"></div>
                <ActivityNotifyList data={notification.filter(item => item.type === filter)} />
            </div>
        </div>
    );
}

export default HomePage;

