import { useEffect, useState } from 'react';
import './style.css';

export const InputRange = ({ className = '', greenTime = 60, yellowTime = 20, redTime = 40, onChange, isDisable = false, startGreen = 10 }) => {
    const max = startGreen + greenTime + yellowTime + redTime;
    const [scope, setScope] = useState({
        config: {
            min: 0,
            max: max,
            gap: 10,
            step: 1,
            precision: 1,
        },
        _model: [startGreen, startGreen + greenTime, startGreen + greenTime + yellowTime],
        _values: { min: 0, max: max },
        _labels: { min: 0, max: max },
        _step: 1,
        _gap: 0,
        _which: 0,
    })

    useEffect(() => {
        const max = startGreen + greenTime + yellowTime + redTime;
        _updateModel([startGreen, startGreen + greenTime, startGreen + greenTime + yellowTime], max);
    }, [startGreen, greenTime, yellowTime, redTime]);

    useEffect(() => {
        _updateModel([startGreen, startGreen + greenTime, startGreen + greenTime + yellowTime]);
    }, []);

    const _updateModel = function _updateModel(model, max = scope.config.max) {
        scope.config.max = max;
        scope._values.max = max;
        scope._labels.max = max;
        scope._model = model;
        
        scope._handleMinX = ((scope._model[0] - scope._values.min) / (scope._values.max - scope._values.min)) * 100;
        scope._handleGapX = ((scope._model[1] - scope._values.min) / (scope._values.max - scope._values.min)) * 100;
        scope._handleMaxX = ((scope._model[2] - scope._values.min) / (scope._values.max - scope._values.min)) * 100;

        scope._handleMinX = scope._handleMinX < 0 ? 0 : scope._handleMinX;
        scope._handleMaxX = scope._handleMaxX > 100 ? 100 : scope._handleMaxX;

        scope._handleMaxX = scope._handleMaxX + (scope._handleMaxX - 50) * 0.00001;
        scope._handleMinX = scope._handleMinX - (50 - scope._handleMinX) * 0.00001;

        setScope({...scope});
    };

    const handleModelChange = (model0, model1, model2, mode = 0) => {
        // User was moving the first slider.
        if (mode === 0) {
            const gap = parseFloat(model0)*1 - scope._model[0];
            if (model2 + gap > scope.config.max && gap > 0) return;

            scope._model[0] = parseFloat(model0)*1;
            scope._model[1] += gap;
            scope._model[2] += gap;
        } 
        // User was moving the second slider.
        else if (mode === 1) {
            const gap = parseFloat(model1)*1 - scope._model[1];
            if (model2 + gap > scope.config.max && gap > 0) return;
            if (parseFloat(model1)*1 < model0) return;

            scope._model[1] = parseFloat(model1)*1;
            scope._model[0] = parseFloat(model0)*1;
            scope._model[2] += gap;
        } else {
            if (parseFloat(model2)*1 < model1) return;

            scope._model[0] = parseFloat(model0)*1;
            scope._model[1] = parseFloat(model1)*1;
            scope._model[2] = parseFloat(model2)*1;
        }

        // Otherwise they were moving the second slider.
        if (mode === 2 && scope._model[0] + scope._gap > scope._model[1]) {
            scope._model[0] = scope._model[1] - scope._gap;
        }

        if (mode === 2 && scope._model[1] + scope._gap > scope._model[2]) {
            scope._model[1] = scope._model[2] - scope._gap;
        }

        // Constrain to the min/max values.
        (function constrainMinMax() {
            if (scope._model[0] < scope._values.min) {
                scope._model[0] = scope._values.min;
            }

            if (scope._model[1] < scope._values.min) {
                scope._model[1] = scope._values.min;
            }

            if (scope._model[2] < scope._values.min) {
                scope._model[2] = scope._values.min;
            }

            if (scope._model[0] > scope._values.max) {
                scope._model[0] = scope._values.max;
            }

            if (scope._model[1] > scope._values.max) {
                scope._model[1] = scope._values.max;
            }

            if (scope._model[2] > scope._values.max) {
                scope._model[2] = scope._values.max;
            }

            // mind the gap
            var _m0Gap = scope._model[0] + scope._gap;
            var _m1Gap = scope._model[1] - scope._gap;

            if (scope._model[0] > _m1Gap) {
                scope._model[0] = _m1Gap;
            }

            if (scope._model[1] < _m0Gap) {
                scope._model[1] = _m0Gap;
            }
        })();

        // Update the model!
        _updateModel(scope._model);
        onChange({ 
            startGreen: scope._model[0],
            greenTime: scope._model[1] - scope._model[0], 
            yellowTime: scope._model[2] - scope._model[1], 
            redTime: scope.config.max - scope._model[2]
        });
    };

    const handleChangeMin = (e) => {
        // if (parseFloat(e.target.value) <= scope._values.min + 0) return;
        handleModelChange(e.target.value, scope._model[1], scope._model[2], 0);
    }

    const handleChangeMax = (e) => {
        // if (parseFloat(e.target.value) >= scope._values.max - 0) return;
        handleModelChange(scope._model[0], scope._model[1], e.target.value, 2);
    }

    const handleChangeGap = (e) => {
        // if (parseFloat(e.target.value) >= scope._values.max - 0) return;
        handleModelChange(scope._model[0], e.target.value, scope._model[2], 1);
    }

    return (
        <div className="pt-2 pb-1 flex-grow-1">
            <div className={`range-slider ${className}`}>
                <div className="range-slider-set">
                    <div className="span-max" style={{ "left": 0, "right": `${Math.min(100, 100 - scope._handleMinX)}%` }}></div>
                    <div className="span-min" style={{ "left": `${Math.max(0, scope._handleMinX)}%`, "right":`${Math.max(0, 100 - scope._handleGapX)}%` }}></div>
                    <div className="span-gap" style={{ "left": `${Math.max(0, scope._handleGapX)}%`, "right":`${Math.max(0, 100 - scope._handleMaxX)}%` }}></div>
                    <div className="span-max" style={{ "left": `${Math.max(0, scope._handleMaxX)}%`, "right": 0 }}></div>

                    <input disabled={isDisable} type="range" onChange={handleChangeMin} className="slider-min" value={scope._model[0]} min={scope._values.min} max={scope._values.max} step={scope._step} />
                    <input disabled={isDisable} type="range" onChange={handleChangeGap} className="slider-max" value={scope._model[1]} min={scope._values.min} max={scope._values.max} step={scope._step} />
                    <input disabled={isDisable} type="range" onChange={handleChangeMax} className="slider-max" value={scope._model[2]} min={scope._values.min} max={scope._values.max} step={scope._step} />

                    <div className="handle from-value" style={{ 'left': `${scope._handleMinX / 2.0}%` }}><span>{scope._model[0]}s</span></div>
                    <div className="handle mid-value" style={{ 'left': `${(scope._handleMinX + scope._handleGapX) / 2.0}%` }}><span>{scope._model[1] - scope._model[0]}s</span></div>
                    <div className="handle mid-value" style={{ 'left': `${(scope._handleGapX + scope._handleMaxX) / 2.0}%` }}><span>{scope._model[2] - scope._model[1]}s</span></div>
                    <div className="handle to-value" style={{ 'left': `${(100 + scope._handleMaxX) / 2.0}%` }}><span>{scope._values.max - scope._model[2]}s</span></div>
                </div>
            </div>
        </div>
    );
}
