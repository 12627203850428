import { Modal } from "react-bootstrap";
import './model-bottom-sheet-component.css';
function ModelBottomSheet({ scrollable=false, children, size, centered, animation, show }){
    return(
        <Modal 
            onHide={() => {}}
            children={children}
            scrollable={scrollable}
            show={show}
            size={size}
            centered={centered}
            animation={animation}
            // className= 'model-bottom-sheet-component'
            // backdropClassName='model-bottom-sheet-component'
            // dialogClassName='model-bottom-sheet-component'
            contentClassName='model-bottom-sheet-content-component'
        />
    );
}
export default ModelBottomSheet;