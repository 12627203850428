import React, { useState, useEffect } from "react";

import Header from "./Header.js";
import Footer from "./Footer.js";
import { Outlet } from "react-router-dom";

function PageLayout({ children }) {
  const [isControlPage, setIsControlPage] = useState(false);
  useEffect(() => {
    if (window.location.href.includes("control?stationid")) {
      setIsControlPage(true);
    } else {
      setIsControlPage(false);
    }
  }, [window.location.href]);
  return (
    <div
      style={{ backgroundColor: "#FAFAFA" }}
      className={`${isControlPage ? "control-wrappers" : ""}`}
    >
      <Header />
      {children}
      <Outlet />
      <Footer />
    </div>
  );
}

export default PageLayout;
