import './styles';
import {useEffect, useState} from "react";
import {CloseCustom, cpu, infoBlack, StatusIcon} from "../../../assets";
import {CustomSwitch} from "../../../components";
import {useAppBloc} from '../../../contexts/AppContext';
import * as ProtoCreator from "../../ProtoCreator";
import { Tab, Tabs } from 'react-bootstrap';
import { InputRange } from '../../../components/shared/InputRange';
import { TableStatus } from '../../../components/ControlPannelPage/TableStatus';
import { Button } from '../../../components/shared/Button';
import { LabelStatus } from '../../../components/ControlPannelPage/LabelStatus';
import moment from 'moment/moment';
import { StationService } from '../../../services/StationService';
import { SignalService } from '../../../services/SignalService';


function checkTrafficLightType(type, stationState) {
  // console.log(`counter: ${JSON.stringify(stationState)}`);
  let setting = stationState?.timer?.setting;
  let ret = {
    name: '',
    color: '#52C41A',
  };
  switch (type) {
    case 1:
      ret = {
        name: 'Đèn xanh',
        color: '#52C41A',
        counter: []
      }
      if (setting) {
        setting.forEach(item => ret.counter.push(item.greenTime));
      }
      break;

    case 2:
      ret = {
        name: 'Đèn đỏ',
        color: '#F5222D',
        counter: []
      }
      if (setting) {
        setting.forEach(item => ret.counter.push(item.redTime));
      }
      break;
    case 3:
      ret = {
        name: 'Đèn vàng',
        color: '#FADB14',
        counter: []
      }
      if (setting) {
        setting.forEach(item => ret.counter.push(item.yellowTime));
      }
      break;
    case 4:
      ret = {
        name: 'Giải toả',
        color: '#FADB14',
        counter: []
      }
      if (setting) {
        setting.forEach(item => ret.counter.push(item.clearanceTime));
      }
      break;
  }
  return ret;
}

function ItemTableChannelOutput({stationState}) {
  const [output, updateOutput] = useState([
    {
      type: 1,

    },
    {
      type: 2,

    },
    {
      type: 3,
    },
    {
      type: 4,
    },

  ]);
  return (
    <div className="scrollbar-homepage scrollbar-custom-homepage">
      {output.map((data, index) => {
        let ret = checkTrafficLightType(data.type, stationState);
        return (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '0px 8px',
              gap: '37px',
              width: '389.5px',
              height: '32px',
            }}
          >
            <span className="item-content-cpu-info-controlpanel" style={{width: '30px', color: '#172B4D'}}>
                {index + 1}
            </span>
            <div onClick={() => {
              let tmp = [...output];
              let count = tmp[index].type;
              if (count === 3) {
                tmp[index].type = 1;

              } else {
                tmp[index].type++;
              }
              updateOutput(tmp)
            }}
                 style={{
                   width: '60px',
                   display: 'flex',
                   flexDirection: 'row',
                   justifyContent: 'center',
                   alignItems: 'center'
                 }}>
              <div style={index !== 3 ? {width: '24px', height: '24px', backgroundColor: ret.color, borderRadius: '24px'} : {}}>
                {index === 3 ? ret.name: ''}
              </div>
            </div>
            <div style={{}}>
              <input
                onChange={() => {
                }}
                value={ret.counter[0]}
                className='input-form-channel-output-controlpanel'
                style={{
                  width: '60px',
                  fontFamily: 'SVN-Gilroy',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '32px',
                  textAlign: 'center',
                  color: '#172B4D',
                }}
                type={'text'}
              />
            </div>

            <div style={{}}>
              <input
                onChange={() => {
                }}
                value={ret.counter[1]}
                className='input-form-channel-output-controlpanel'
                style={{
                  width: '60px',
                  fontFamily: 'SVN-Gilroy',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '32px',
                  textAlign: 'center',
                  color: '#172B4D',
                }}
                type={'text'}
              />
            </div>

          </div>
        );
      })}
    </div>
  );
}

function ItemTableChannelOutputEdit({stationState}) {
  const {appBloc} = useAppBloc();
  let retGreen = checkTrafficLightType(1, stationState);
  let retYellow = checkTrafficLightType(3, stationState);
  let retClearence = checkTrafficLightType(4, stationState);
  const [greenData, setGreenData] = useState(retGreen.counter.length >= 2 ? retGreen.counter.splice(0, 2) : []);
  const [yellowData, setYellowData] = useState(retYellow.counter[0]);
  const [clearData, setClearData] = useState(retClearence.counter[0]);
  return (
    <div className="scrollbar-homepage scrollbar-custom-homepage">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0px 8px',
          gap: '37px',
          width: '389.5px',
          height: '32px',
        }}
      >
        <span className="item-content-cpu-info-controlpanel" style={{width: '30px', color: '#172B4D'}}>
            {1}
        </span>
        <div
           style={{
             width: '60px',
             display: 'flex',
             flexDirection: 'row',
             justifyContent: 'center',
             alignItems: 'center'
           }}>
          <div style={{width: '24px', height: '24px', backgroundColor: '#52C41A', borderRadius: '24px'}}>

          </div>
        </div>
        {greenData.map((item, index) => {
          return <div style={{}}>
            <input
              onChange={(value) => {
                let green = [...greenData];
                green[index] = value.target.value;
                setGreenData(green);
              }}
              value={item}
              className='input-form-channel-output-controlpanel'
              style={{
                width: '60px',
                fontFamily: 'SVN-Gilroy',
                fontStyle: 'normal',
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '32px',
                textAlign: 'center',
                color: '#172B4D',
              }}
              type={'text'}
            />
          </div>
        })}

      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0px 8px',
          gap: '37px',
          width: '389.5px',
          height: '32px',
        }}
      >
        <span className="item-content-cpu-info-controlpanel" style={{width: '30px', color: '#172B4D'}}>
            {2}
        </span>
        <div
           style={{
             width: '60px',
             display: 'flex',
             flexDirection: 'row',
             justifyContent: 'center',
             alignItems: 'center'
           }}>
          <div style={{width: '24px', height: '24px', backgroundColor: '#FADB14', borderRadius: '24px'}}>

          </div>
        </div>
        <div style={{}}>
          <input
            onChange={(value) => {
              setYellowData(value.target.value)
            }}
            value={yellowData}
            className='input-form-channel-output-controlpanel'
            style={{
              width: '160px',
              fontFamily: 'SVN-Gilroy',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '32px',
              textAlign: 'center',
              color: '#172B4D',
            }}
            type={'text'}
          />
        </div>

      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0px 8px',
          gap: '37px',
          width: '389.5px',
          height: '32px',
        }}
      >
        <span className="item-content-cpu-info-controlpanel" style={{width: '30px', color: '#172B4D'}}>
            {3}
        </span>
        <div
          style={{
            width: '60px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <div style={{}}>
            Giải toả
          </div>
        </div>
        <div style={{}}>
          <input
            onChange={(value) => {setClearData(value.target.value)}}
            value={clearData}
            className='input-form-channel-output-controlpanel'
            style={{
              width: '160px',
              fontFamily: 'SVN-Gilroy',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '14px',
              lineHeight: '32px',
              textAlign: 'center',
              color: '#172B4D',
            }}
            type={'text'}
          />
        </div>

      </div>
      <div className="item-button-channel-output-controlpanel"
         style={{
           width: '400px',
           // height: '44px',
           textAlign: 'center',
           margin: 'auto'
         }}
         onClick={() => {
           appBloc.websocket.send(ProtoCreator.CreateSetCycleConfigUserRequest(appBloc.deviceId, 2, yellowData, clearData, greenData));
          }
         }
      >
        <span
          style={{
            // width: '62px',
            height: '20px',
            fontFamily: 'SF Pro Text',
            fontStyle: 'normal',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '20px',
            color: '#FFFFFF',
          }}
        >Cấu hình trạm</span>
      </div>
    </div>
  );
}

function TableChannelOutput({stationState, editTime}) {
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '0px 8px',
          gap: '37px',
          width: '389.5px',
          height: '32px',
        }}
      >
        <span className="item-content-cpu-info-controlpanel" style={{width: '30px'}}>
            Ngõ
        </span>
        <span className="item-content-cpu-info-controlpanel" style={{width: '60px'}}>
            Tín hiệu
        </span>
        <span className="item-content-cpu-info-controlpanel" style={{width: '209.5px'}}>
            Cấu hình thời gian
        </span>

      </div>
      {editTime ? <ItemTableChannelOutputEdit stationState={stationState}/> :
        <ItemTableChannelOutput stationState={stationState}/>
      }
    </>
  );
}

function StationOverview({ station, dataDevideSocket, isStatusOutput = false }) {
  const [dataStation, setDataStation] = useState(null);
  const [infoActivity, setInfoActivity] = useState(null);
  const [numSensor, setNumSensor] = useState(0);
  const [dataSensor, setDataSensor] = useState([]);
  const stationService = new StationService();
  const signalService = new SignalService();

  useEffect(async () => {
    console.log(station?.id)
    if (station) {
      stationService.getStationById(station.id).then(data => {
        setDataStation(data.data);
      });
      
      stationService.getCurrentCycleForm(station.id).then(data => {
        setInfoActivity(data.data)
      })

      signalService.getListSignal(station.id).then(data => {
        console.log(data);
        if (data && data.data) {
          setDataSensor(data.data.signalSensors);
          setNumSensor(data.data.signalSensors.length);
        }
      })
    }
  }, [station?.id]);

  const handleSaveSensor = () => {
    signalService.updateSignal({
      stationId: station.id,
      numberSensor: parseInt(numSensor),
      signalSensors: dataSensor.map(item => ({ name: item.name })),
    })
  }

  const handleCheck = () => {
    signalService.check(station.id);
  }

  if (isStatusOutput) {
    return (
      <Tabs
        defaultActiveKey="statusOutput"
        className="mb-3 station-navs"
      >
        <Tab eventKey="statusOutput" title="Trạng thái ngõ ra">
          <div className="status-tab">
            <div className="status-header">
              <StatusIcon color={(dataDevideSocket && dataDevideSocket?.outputSensorList.every(item => item === 0)) ? '#52C41A' : '#F5222D'} />
              <nav className="status-text ml-2">Số ngõ ra sử dụng</nav>
              <input value={numSensor} onChange={(e) => setNumSensor(e.target.value)} className="ml-20 mr-20 status-input" type='number' />
              <Button text={'Lưu'} onClick={handleSaveSensor} />
              <Button className='ml-3 button-base-disable' text={'Tổng'} onClick={handleCheck} disabled={dataDevideSocket && dataDevideSocket?.outputSensorList.every(item => item === 0)} />
            </div>
            <div className="status-content-table item-homepage-content scrollbar-custom-homepage">
              <TableStatus stationId={station.id} dataSensor={dataSensor} dataDevideSocket={dataDevideSocket} />
            </div>
          </div>
        </Tab>
      </Tabs>
    );
  }

  return (
    <Tabs
      defaultActiveKey="cpuInfo"
      className="mb-3 station-navs"
    >
      <Tab eventKey="cpuInfo" title="Thông tin CPU">
        <div className="mx-auto" style={{ width: '480px'}}>
          <div className="text-center d-flex align-items-center justify-content-center mb-4">
            <span className="info-title mr-4">{dataStation?.stationName || ""}</span>
            <LabelStatus value={dataStation?.online} />
          </div>
          <div className="info-item mb-3">
            <span className="info-item-left">Mã thiết bị</span>
            <span className="info-item-right">{dataStation?.hardwareId}</span>            
          </div>
          <div className="info-group-item">
            <div className="info-item mb-3">
              <span className="info-item-left">Kiểu CPU</span>
              <span className="info-item-right">{dataStation?.cpuType}</span>            
            </div>
            <div className="info-item mb-3">
              <span className="info-item-left">Số pha</span>
              <span className="info-item-right">{dataStation?.numPhase}</span>            
            </div>
          </div>
          <div className="info-item">
            <span className="info-item-left">Thời gian kích hoạt</span>
            <span className="info-item-right">{moment(dataStation?.createDate).format('DD/MM/YYYY')}</span>            
          </div>
          <div className="pl-18 mt-3 d-flex">
            <span className="info-item-left">Cảnh báo: </span>
            <span className="info-text-green ml-3">Không</span>
          </div>
        </div>
      </Tab>
      <Tab eventKey="activityInfo" title="Thông tin hoạt động">
        <div className="activity-tab">
          <div className="activity-header">
            <nav className="activity-text">Biểu mẫu đang áp dụng : <span className="color-green">Biểu mẫu {infoActivity?.cycleForm + 1}</span></nav>
            <nav className="activity-text">Tổng chu kỳ : <span className="color-green">{infoActivity?.total} giây</span></nav>
          </div>
          <div className="activity-content scrollbar-homepage scrollbar-custom-homepage">
            {infoActivity?.phaseCounters?.map((phase, idx) => {
              return <div className="activity-item">
                <span className="text-medium">Pha {idx + 1}</span>
                <InputRange
                  greenTime={phase.greenTime}
                  yellowTime={phase.yellowTime}
                  redTime={phase.total - phase.greenTime - phase.yellowTime - phase.startGreen}
                  isDisable={true}
                  startGreen={phase.startGreen}
                />
              </div>
            })}
          </div>
        </div>
      </Tab>
    </Tabs>
  );
}

export {StationOverview};