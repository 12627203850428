import { Spinner } from 'react-bootstrap';
import './style.css';

export const Button = ({ text, onClick, className = "", isLoading = false, disabled = false }) => {
  return (
    <button className={"button-base" + ' ' + className} onClick={onClick} disabled={disabled}>
      {text}
      {isLoading && <Spinner className="ml-2" animation="border" size="sm" />}
    </button>
  );
}
