import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, Image } from 'react-bootstrap'
import { Link, NavLink, useLocation, useSearchParams } from "react-router-dom";
import '../styles/header.css';

import { userSetting, ArrowDown, sdkdLogo, overviewOff, overviewOn, controllPanelOn, controllPanelOff, managementOn, managementOff, statisticOn, statisticOff, strategyOn, strategyOff } from '../assets';
import { AuthService } from '../services/AuthService';
import { UserService } from '../services/UserService';
import { AppContext } from '../contexts/AppContext';
import { ReactSVG } from "react-svg";

function DropDownButton({isHover}) {
    return (
        <div style={{
            display:'flex',
            width: '24px',
            height: '24px',
            background: isHover ? 'rgba(55, 81, 255, 0.2)' : '#00000000',
            borderRadius: '4px',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <ArrowDown color={isHover ? '#3751FF' : '#C4C5CE'} />
        </div>
    )
}

function CustomDropDown() {
    const [hover, onhover] = useState(false);
    const { setIsAuthenticated } = useContext(AppContext);
    const authService = new AuthService();

    const onLogout = async () => {
        await authService.logout();
        setIsAuthenticated(false);
    }

    return (
        <div className='d-flex flex-column justify-content-center' onMouseOver={() => { onhover(true) }} onMouseOut={() => { onhover(false) }}>
            <DropDownButton isHover={hover} />
            <div className={`${hover ? 'd-flex' : 'd-none'} dropdown-header-content`}>
                <div className='dropdown-header'>
                    <Link to={'/setting/profile'} className='dropdown-header-item'><span>Hồ sơ tài khoản</span></Link>
                    <Link to={"/setting/system-setting"} className='dropdown-header-item'><span>Cấu hình hệ thống</span></Link>
                    <Link to={"/setting/user-manual"} className='dropdown-header-item'><span>Hướng dẫn sử dụng</span></Link>
                    <Link to={"/setting/support"} className='dropdown-header-item'><span>Hỗ trợ kĩ thuật</span></Link>
                    <Nav.Link onClick={() => onLogout()} className='dropdown-header-item text-logout'><span>Đăng xuất</span></Nav.Link>
                </div>
            </div>
        </div>
    )
}

const HeaderProfile = ({ userName, avatar }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
            <div className="user-avt" style={{ backgroundSize: 'cover', height: '48px', width: '48px', borderRadius: '12px', backgroundImage: `url(${avatar})` }} ></div>
            <div className="user-profile" style={{ }} >
                <span className="header-profile">{userName}</span>
                <div style={{ height: '8px' }} ></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={userSetting} />
                    <span
                        style={{
                            marginLeft: '5px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '15px',
                            textAlign: 'center',
                            letterSpacing: '0.4px',
                            color: '#3751FF',
                        }}
                    >Admin</span>
                </div>
            </div>
        </div>
    );
}

function TabItem({ path, label, maxWidth, activeIcon, defaultIcon }) {
    const location = useLocation();

    return (
        <>
            <Nav.Link className={ (location?.pathname === path) && "active" } as={Link} to={path} >
                <div className="nav-content"> 
                    <ReactSVG beforeInjection={(svg) => {
                        svg.setAttribute('style', 'width: 20px')
                    }} src={(location?.pathname === path) ? activeIcon : defaultIcon} />
                    <span className="nav-label" style={{ color: ((location?.pathname === path) ? '#3751FF' : '#424245') }}>{label}</span>
                </div>
                <div style={{ width: { maxWidth }, height: '2px', backgroundColor: ((location?.pathname === path) ? '#3751FF' : '#424245'), display: (location?.pathname === path) ? 'block' : 'none' }} ></div>
            </Nav.Link>
        </>
    );
}

export default function Header() {
    const userService = new UserService();
    const { setIsAuthenticated, avatar, setAvatar } = useContext(AppContext);
    const authService = new AuthService();
    const [name, setName] = useState("");
    const [isShowHeader, setIsShowHeader] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();


    useEffect(async () => {
        try {
            const data = await userService.getUserInfo();
            setName(data?.data?.name);
            setAvatar(data?.data?.avatar);
        } catch (error) {
            if (error?.status === 400 && error?.data?.message === '400 Bad Request: "{"error":"invalid_grant","error_description":"Token is not active"}"') {
                await authService.logout();
                setIsAuthenticated(false);
            }
        }

        if (searchParams.get("hidemenu")) {
            setIsShowHeader(false);
        }
    }, []);
    
    return (
        <>
            <div>
                <Navbar className="header-wrapper" expand="lg" >
                    <Navbar.Brand>
                        <NavLink to={"/home"}>
                            <Image src={sdkdLogo} style={{ maxWidth: '149.24px', maxHeight: 'auto' }}></Image>
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarScroll" />
                    <Navbar.Collapse id="navbarScroll" style={{ visibility: isShowHeader ? 'visible' : 'hidden' }}>
                        <Nav className="nav-bar" navbarScroll>
                            <TabItem defaultIcon={overviewOff} activeIcon={overviewOn} label={"TỔNG QUAN"} path={"/home"} maxWidth={'144px'} />
                            <TabItem defaultIcon={controllPanelOff} activeIcon={controllPanelOn} label={"DANH SÁCH TRẠM"} path={"/control"} maxWidth={'182px'} />
                            <TabItem defaultIcon={managementOff} activeIcon={managementOn} label={"LẬP LỊCH"} path={"/strategy"} maxWidth={'182px'} />
                            <TabItem defaultIcon={strategyOff} activeIcon={strategyOn} label={"QUẢN LÝ CHUNG"} path={"/management"} maxWidth={'182px'} />
                            <TabItem defaultIcon={statisticOff} activeIcon={statisticOn} label={"QUẢN LÝ THIẾT BỊ"} path={"/device"} maxWidth={'185px'} />
                        </Nav>
                    </Navbar.Collapse>

                    <div style={{ display: 'inline-flex', flexDirection: 'row', maxWidth: '279px', }}>
                        <HeaderProfile userName={name} avatar={avatar} />
                        <div style={{ width: '12px' }} ></div>
                        <CustomDropDown />
                    </div>
                </Navbar>
            </div>
        </>
    );
}
