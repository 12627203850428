import { useState } from 'react';
import { Plus} from '../../../assets';
import './styles';

const listRole=[
    'Admin',
    'User',
]

const listLanguage=[
    'Tiếng Việt',
    'Tiếng Anh',
]

function InputFormFieldSystemSetting({title,value,onChangeValue}){
    return(
        <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'start'}}>
            <span className='title-overview-info-systemsettingpage' style={{width:'250px',textAlign:'start'}}>
                {title}
            </span>
            <div style={{width:'10px'}}></div>
            <input value={value} onChange={(event)=>onChangeValue(event.target.value)} type={'text'} className='password-form-overview-info-systemsettingpage'style={{width:'467px'}}  />
        </div>
    );
}

function InputFormAddFile({title,value,onChangeValue,fileName}){
    const [_filename,_getFileName] = useState(fileName);
    return(
        <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'start'}}>
            <span className='title-overview-info-systemsettingpage' style={{width:'250px',textAlign:'start'}}>
                {title}
            </span>
            <div style={{width:'10px'}}></div>

            <div className='custom-file-input-systemsettingpage'>
                <label className='upload'>
                    <input type="file" accept="image/*" id="image_uploads" name="image_uploads" onChange={(e)=>{ _getFileName(e.target.files[0].name)}} />
                    Chọn tệp
                </label>
            </div>
            <div style={{width:'10px'}}></div>
            <span style={{
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'start',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '133%',
                textAlign: 'center',
                textDecorationLine: 'underline',
                color: '#3751FF',
            }}>
                {_filename}
            </span>
        </div>
    );
}

function InputFormAddFilePreview({title,value,onChangeValue,fileName}){
    const [_filename1,_getFileName1] = useState('');
    const [_filename2,_getFileName2] = useState('');
    const [_filename3,_getFileName3] = useState('');
    const [_filename4,_getFileName4] = useState('');
    return(
        <div style={{display:'flex',flexDirection:'column',width:'100%',justifyContent:'start'}}>
            <span className='title-overview-info-systemsettingpage' style={{width:'250px',textAlign:'start'}}>
                {title}
            </span>
            <div style={{height:'20px'}}></div>

            <div style={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
                <div className='custom-file-input-systemsettingpage'>
                    <label className='preview' style={{borderWidth:_filename1===''?'1px':'0px'}}>
                        <input type="file" accept="image/*" onChange={(e)=>{ 
                            if (e.target.files && e.target.files.length > 0) {
                                _getFileName1(e.target.files[0]);
                            }
                        }} />
                        <Plus fill={'#BFBFBF'} />
                        {_filename1 && <img height={'200px'} width={'200px'}  src={URL.createObjectURL(_filename1)} style={{position:'absolute',width:'200px',height:'200px',display:_filename1===''?'none':'flex',top:'0px',left:'0px'}} />}
                    </label>
                </div>
                <div style={{width:'20px'}}></div>
                <div className='custom-file-input-systemsettingpage'>
                    <label className='preview' style={{borderWidth:_filename2===''?'1px':'0px'}}>
                        <input type="file" accept="image/*" onChange={(e)=>{ 
                            if (e.target.files && e.target.files.length > 0) {
                                _getFileName2(e.target.files[0]);
                            }
                        }} />
                        <Plus fill={'#BFBFBF'} />
                        {_filename2 && <img height={'200px'} width={'200px'}  src={URL.createObjectURL(_filename2)} style={{position:'absolute',width:'200px',height:'200px',display:_filename2===''?'none':'flex',top:'0px',left:'0px'}} />}
                    </label>
                </div>
                <div style={{width:'20px'}}></div>
                <div className='custom-file-input-systemsettingpage'>
                    <label className='preview' style={{borderWidth:_filename3===''?'1px':'0px'}}>
                        <input type="file" accept="image/*" onChange={(e)=>{ 
                            if (e.target.files && e.target.files.length > 0) {
                                _getFileName3(e.target.files[0]);
                            }
                        }} />
                        <Plus fill={'#BFBFBF'} />
                        {_filename3 && <img height={'200px'} width={'200px'} src={URL.createObjectURL(_filename3)} style={{position:'absolute',width:'200px',height:'200px',display:_filename3===''?'none':'flex',top:'0px',left:'0px'}} />}
                    </label>
                </div>
                <div style={{width:'20px'}}></div>
                <div className='custom-file-input-systemsettingpage'>
                    <label className='preview' style={{borderWidth:_filename4===''?'1px':'0px'}}>
                        <input type="file" accept="image/*" onChange={(e)=>{ 
                            if (e.target.files && e.target.files.length > 0) {
                                _getFileName4(e.target.files[0]);
                            }
                        }} />
                        <Plus fill={'#BFBFBF'} />
                        {_filename4 && <img height={'200px'} width={'200px'} src={URL.createObjectURL(_filename4)} style={{position:'absolute',width:'200px',height:'200px',display:_filename4===''?'none':'flex',top:'0px',left:'0px'}} />}
                    </label>
                </div>
            </div>
        </div>
    );
}



function DisplaySetting({websiteName,websiteUrl,sologan,managerEmail,role,lannguage}) {
    const [_websiteName,_setWebsiteName] = useState(websiteName);
    const [_sologan,_setSologan] = useState(sologan);
    const [_websiteUrl,_setWebsiteUrl] = useState(websiteUrl);
    const [_managerEmail,_setManagerEmail] = useState(managerEmail);

    function onTapSave(){

    }

    return(
        <div style={{display:'flex',flexDirection:'column',width:'100%'}}>

            <InputFormAddFile title={"Logo"} />
            <div style={{height:'20px'}} ></div>
            <InputFormAddFile title={"Banner đăng nhập"} />
            <div style={{height:'20px'}} ></div>
            <InputFormFieldSystemSetting value={_websiteName} onChangeValue={_setWebsiteName} title={'Tên website'}/>
            <div style={{height:'20px'}} ></div>

            <InputFormAddFilePreview title={"Banner trang chủ"}/>

            <div style={{height:'20px'}} ></div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <button 
                    onClick={onTapSave}
                    type='button' 
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '12px 71px',
                        backgroundColor: '#3751FF',
                        borderRadius: '4px',
                        borderWidth:'0px',
                    }} 
                >
                    <span
                        style={{
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '133%',
                            color: '#FFFFFF',
                        }}
                    >Lưu lại</span>
                </button>
            </div>
            

        </div>

    );
}
export {DisplaySetting};