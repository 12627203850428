import ServiceBase from './service-base';

export class SettingService extends ServiceBase {
  baseUri = '';

  async getContact() {
    return (await this.get('/api/setting/v1/contact')).data;
  }

  async updateContact(email, phone) {
    return (await this.put(`/api/setting/v1/contact`, {
      'email': email,
      'phone': phone
    })).data;
  }
}
