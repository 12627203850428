import ServiceBase from './service-base';

export class AccessoryService extends ServiceBase {
  baseUri = '';

  async getList() {
    return (await this.get('/api/accessory/v1/getList')).data;
  }

  async create({ stationName, model, madeBy, pillar, position, installDate = new Date(), status = "NORMAL" }) {
    return (await this.post('/api/accessory/v1/create', { stationName, model, madeBy, pillar, position, installDate, status })).data;
  }

  async update({ id, stationName, model, madeBy, pillar, position, installDate, status }) {
    return (await this.put('/api/accessory/v1/update', { id, stationName, model, madeBy, pillar, position, installDate, status })).data;
  }

  async deleteById(id) {
    return (await this.delete('/api/accessory/v1/delete/' + id)).data;
  }
}
