import { useState } from 'react';
import './custom-calender-component.css';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';

function CustomCalender ({icon,width=0,height=0,dateTime=new Date(), startDateFilter,  endDateFilter, setStartDateFilter, setEndDateFilter}){
    const [_dateTime, _setDateTime] = useState(dateTime);
    const [_isTap,_onTap] = useState(false);
    const [_hoverTitle, _onHoverTitle] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const handleChangeDate = ({ selection }) => {
        setStartDate(selection.startDate);
        setEndDate(selection.endDate);
    }

    return(
        <div className="custom-calender-button-component" style={{ height:height,cursor:'pointer',position:'relative'}}>
            <div onMouseLeave={() => { _onHoverTitle(false) }} onMouseOver={() => { _onHoverTitle(true) }} onClick={() => { if (_hoverTitle) { _onTap(!_isTap) } }} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: width, height: height, cursor: 'pointer', }}>
                <span>{moment(startDateFilter).format('DD/MM/YYYY') + ' - ' + moment(endDateFilter).format('DD/MM/YYYY')}</span>
                {icon}
            </div>
            <div 
                className='custom-calender-component' 
                style={{
                    marginTop:'12px',
                    display: _isTap?'flex':'none',
                    top: (height),
                    right: '0',
                }} 
            >
                <DateRangePicker
                    ranges={[{
                        startDate: startDate,
                        endDate: endDate,
                        key: 'selection',
                    }]}
                    onChange={handleChangeDate}
                    className="custom-calender-range"
                    showSelectionPreview={false}
                    showDateDisplay={false}
                    showPreview={false}
                />
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '30px', justifyContent: 'space-between' }} >
                    <div className="station-cancel" onClick={() => { _onTap(false) }}>Hủy bỏ</div>
                    <div className="station-apply" onClick={() => { 
                        setStartDateFilter(startDate);
                        setEndDateFilter(endDate);
                        _onTap(false);
                    }}>Áp dụng</div>
                </div>
            </div>
        </div>
    );
}

export default CustomCalender;
