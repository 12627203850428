import {useEffect, useState} from "react";
import { BottomButtom } from '../../../components';
import {VersionService} from "../../../services/VersionService";
import {SettingService} from "../../../services/SettingService";

function InputFormAddFile({ title, file, setFile}) {
    const [_filename, _getFileName] = useState("");

    useEffect(() => {
        _getFileName(file ? file.name : "");
    }, [file]);

    return (
        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start'}}>
            <span
                style={{
                    fontStyle: 'normal',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '133%',
                    textAlign: 'center',
                    color: '#172B4D',
                }}
            >{title}</span>

            <div style={{ height: '12px' }} ></div>
            
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}>
                <div className='custom-file-input-commonmanagementpage'>
                    <label className='upload'>
                        <input type="file" id="file_upload" onChange={(e) => { e.target.files[0] ? setFile(e.target.files[0]) : null }} />
                        Chọn tệp
                    </label>
                </div>
                <div style={{ width: '10px' }}></div>
                <span style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'start',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '133%',
                    textAlign: 'center',
                    textDecorationLine: _filename === "" ? 'none' : 'underline',
                    color: _filename === "" ? '#595959' : '#3751FF',
                }}>
                    {_filename === "" ? "Chưa có file nào được tải lên" : _filename}
                </span>
            </div>
        </div>
        
    );
}

function FormInputReceiveOption({ hint, title, value, onChangeValue }) {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'start', alignItems: 'start' }} >
            <span
                style={{
                    fontStyle: 'normal',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '133%',
                    textAlign: 'center',
                    color: '#172B4D',
                }}
            >{title}</span>
            <div style={{ height: '12px' }} ></div>
            <input type={'text'} value={value} onChange={(event) => onChangeValue(event.target.value)} className='input-form-profile-info-commonmanagementpage' style={{ width: '307px' }} placeholder={hint} color={'#172B4D'} />
        </div>
    );
}

function FormInputPushFile() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }} >
            <span
                style={{
                    fontStyle: 'normal',
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '133%',
                    textAlign: 'center',
                    color: '#172B4D',
                }}
            >Tải tệp lên</span>
            <div style={{ height: '12px' }} ></div>

            <div style={{ display: 'flex', flexDirection: 'row', }}>
                
            </div>

        </div>
    );
}

function SoftwareOption() {

  const versionService = new VersionService();

  const [version, setVersion] = useState('');
  const [file, setFile] = useState(null);
  const [versionName, setVersionName] = useState('');

  useEffect(async () => {
    const res = await versionService.getLatestVersion();
    console.log(res);
    if (res && res.data) {
      setVersion(res.data.version);
    }
  }, []);

  const uploadVersion = async () => {
    if (version && file) {
        const res = await versionService.uploadVersion({
            type: "AC",
            version: versionName,
            file: file
        })

        if (res && res.code === 0) {
            setVersionName("");
            setFile(null);
            const result = await versionService.getLatestVersion();
            if (result && result.data) {
            setVersion(result.data.version);
            }
        } else {
            alert(res.message);
        }
        console.log("RES", res);
    }
  }

    const [update, setUpdate] = useState(true);
    return (
        <>
            <span
                style={{
                    fontStyle: 'normal',
                    fontWeight: '400',
                    fontSize: '14px',
                    lineHeight: '133%',
                    textAlign: 'center',
                    color: '#172B4D',
                }}
            >
                Version hiện tại <span style={{ fontWeight: '700' }}>{version}</span>
            </span>
            {/*<BottomButtom*/}
            {/*    title={"Cập nhật"}*/}
            {/*    style={{*/}
            {/*        color: '#FFFFFF',*/}
            {/*        width: '132px',*/}
            {/*        height: '42px',*/}
            {/*        fontSize: '16px',*/}
            {/*        fontStyle: 'normal',*/}
            {/*        fontWeight: '700',*/}
            {/*        lineHeight: '133%',*/}
            {/*        backgroundColor: update ? "#3751FF" : "#BFBFBF"*/}
            {/*    }}*/}
            {/*    onTap={() => { setUpdate(false) }}*/}
            {/*/>*/}

            <span style={{
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '20px',
                lineHeight: '133%',
                textAlign: 'center',
                color: '#3751FF',
            }}>
                Tải firmware mới
            </span>

            <div style={{ display: 'flex', flexDirection: 'row', gap: '24px', justifyContent: 'start', alignItems: 'center', width: '100%' }} >
                <FormInputReceiveOption title={"Phiên bản:"} hint={""} value={versionName} onChangeValue={setVersionName} />
                <InputFormAddFile title={"Tải tệp lên :"} file={file} setFile={setFile} />
            </div>
            <BottomButtom
                title={"Xác nhận"}
                style={{
                    color: '#FFFFFF',
                    width: '132px',
                    height: '42px',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '133%',
                }}
                onTap={uploadVersion}
            />

        </>
    );
}
export { SoftwareOption };