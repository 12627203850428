import './custom-table-component.css';
import '../../styles/scroll_list.css';

/** style: for table
 *  titleItem: title bar
 *  isDivider : if you do not want to cutom divider
 *  children : list conntent
 */

function CustomTable({style,titleItem,children, isDivider}) {
  return (
    <div className="custom-table-component" style={style}>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
      }} >
        {titleItem}
      </div>

      {
        isDivider ? <div style={{ height: '1px', backgroundColor: ' #EFF1F1', width: '100%', margin: '0px 8px 0px 8px' }}></div> : <></>
      }
      
      <div className='scrollbar-homepage scrollbar-custom-homepage'>
        {children}
      </div>

    </div>
  )
}

export default CustomTable ;