import React, {useEffect, useState, useContext} from "react";
import { StrategyWithTime } from "./StrategyWithTime";
import { StrategyWithDate } from "./StrategyWithDate";
import './styles';
import {AppContext, useAppBloc} from "../../../contexts/AppContext";
import { Tab, Tabs } from "react-bootstrap";
import { Button } from "../../../components/shared/Button";
import { ChartContent } from "../../../components/SchedulePage/ChartContent";
import { ScheduleService } from "../../../services/ScheduleService";
import * as ProtoCreator from "../../ProtoCreator";
import { config } from "../../../configs/Config";
import Cookies from 'js-cookie';


function StationDetail({ station, idx }) {
  const [cycleForms, setCycleForms] = useState([]);
  const [currentForm, setCurrentForm] = useState(0);
  const [dateForms, setDateForms] = useState([]);
  const [timeForms, setTimeForms] = useState([]);
  const scheduleService = new ScheduleService();
  const [isLoading, setIsLoading] = useState(false);
  const [disabledAsync, setDisabledAsync] = useState(true);
  const { socket, setSocket } = useContext(AppContext);
  const [logUpdate, setLogUpdate] = useState(null);


  useEffect(() => {
    // ----- Socket
    socket.websocket = new WebSocket(config.HOST_WS);
    socket.websocket.binaryType = "arraybuffer";
    socket.websocket.onopen = () => {};
    socket.websocket.onmessage = evt => {
      let mainMessage = proto.MainMessage.deserializeBinary(evt.data);
      console.log("MAIN", mainMessage?.toObject());
      if (mainMessage?.hasUsermessage() && mainMessage.getUsermessage().hasDeviceonchangeusermessage()) {
        const deviceData = mainMessage.getUsermessage().getDeviceonchangeusermessage().toObject();
        // console.log("DEVICE DATA", deviceData);
        console.log("Update DATA", deviceData);
        if (deviceData?.logupdate && (deviceData?.logupdate.message == "Successful update schedule to app" || deviceData?.logupdate.message == "mtfc finish startup system")) {
          setIsLoading(false);
        } else if (deviceData?.logupdate) {
          setLogUpdate(deviceData?.logupdate);
          setIsLoading(true);
        }

        // updateDataDevideSocket(deviceData?.devicestateonchange?.update1second);
      }
    }
    socket.websocket.onclose = e => {
      console.log("CLOSE SOCKET");
    };
    // websocket onerror event listener
    socket.websocket.onerror = err => {
      console.log("EOORRR SOCKET", err?.message);
      socket.websocket?.close();
    };

    setSocket(socket);

    return () => {
      console.log("UNMOUNTED SCHEDULE");
      socket.websocket?.close();
    }
  }, []);

  useEffect(async () => {
    if (station?.id) {
      const result = await scheduleService.getScheduleById(station?.id);
      setCycleForms(result.data?.cycleForms || []);
      setDateForms(result.data?.dateForms || []);
      setTimeForms(result.data?.timeForms || []);

      socket.websocket.send(
        ProtoCreator.CreateLoginMessage(Cookies.get('token'), station?.id)
      );
    }
  }, [station?.id]);

  const handleChartContentChange = (data) => {
    cycleForms[currentForm] = data;
    setDisabledAsync(false);
    setCycleForms([...cycleForms]);
  }

  const handleUpdateSchedule = async () => {
    setIsLoading(true);
    const result = await scheduleService.updateSchedule({
      stationId: station?.id,
      cycleForms: cycleForms,
      timeForms: timeForms,
      dateForms: dateForms,
    });

    if (result.code === 402) {
      setIsLoading(false);
    }
    setDisabledAsync(true);
  }

  return (
    <div className="d-flex flex-column justify-content-between position-relative w-100" style={{ height: 'calc(100% - 43px)'}}>
      <Button disabled={disabledAsync} className="schedule-async" text={'Đồng bộ'} isLoading={isLoading} onClick={handleUpdateSchedule} />

      <div>
        <Tabs
          defaultActiveKey="chart"
          className="station-navs schedule-navs"
        >
          <Tab eventKey="chart" title="Biểu mẫu">
            <ChartContent
              currentForm={currentForm} 
              setCurrentForm={setCurrentForm} 
              data={cycleForms[currentForm]}
              setData={handleChartContentChange}
            />
          </Tab>
          <Tab eventKey="timeChart" title="Giản đồ thời gian">
            <StrategyWithTime
              cycleForms={cycleForms}
              setTimeForms={(data) => {
                setDisabledAsync(false);
                setTimeForms(data);
              }}
              timeForms={timeForms}
              onChangeTime={(time) => setTimeSetting(time)}
            />
          </Tab>
          <Tab eventKey="assignChart" title="Gán giản đồ">
            <StrategyWithDate cycleForms={cycleForms} dateForms={dateForms} timeForms={timeForms} 
              setDateForms={(data) => {
                setDisabledAsync(false);
                setDateForms(data);
              }}
            />
          </Tab>
        </Tabs>
      </div>

      <div className="chart-footer">
        <div className="chart-footer-title">Console</div>
        <div className="chart-footer-content">
          <div className="chart-footer-item">
            <nav className="chart-footer-item-text">Hiện thông tin truyền MTFC</nav>
            <nav className="chart-footer-item-text">{logUpdate?.message}</nav>
          </div>
          <div className="chart-footer-item scrollbar-homepage scrollbar-custom-homepage">
            <nav className="chart-footer-item-text">Số pha : {cycleForms[currentForm]?.numPhase}</nav>
            {cycleForms[currentForm]?.phaseCounters?.map((phase, idx) => {
              if (cycleForms[currentForm].numPhase <= idx) return;
              return (phase.total) > 0 ? <nav key={idx} className="chart-footer-item-text">[ Pha {idx + 1} ] : Bắt đầu xanh: {phase.startGreen}s, Xanh: {phase.greenTime}s , Vàng: {phase.yellowTime}s , Đỏ: {phase.total - phase.greenTime - phase.yellowTime}s , Tổng chu kỳ: {cycleForms[currentForm]?.total}s</nav>
              : <></>
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export {StationDetail};
