import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <>
        <div style={{
          backgroundColor:'#FFFFFF',
          gap:'10px',
          padding: '14px 0px 14px 0px',
          flexDirection:'row',
          width:'100%',
          height:'50px',
          display: 'flex', 
          justifyContent: 'center',
        }}>
          <span style={{
            height:'22px',
            fontSize: '14px',
            fontStyle:'normal',
            lineHeight:'22px',
            fontWeight:'400',
            textAlign:'center',
            color:'#172B4D',
            alignContent:'center',
          }}>
            © Công Ty SDKD  2023. All Rights Reserved.
          </span>

        </div>
      </>
    );
  }
}

export default Footer;
