import React, { Children } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import Cookies from 'js-cookie';

export class AppBloc {
    constructor(props){
        this.session = props.session;
        this.websocket = props.websocket;
        this.projectId = props.projectId;
        this.deviceId = null;
    }
}

export const AppContext = React.createContext();

export const AppContextProvider = ({children, appProps}) => {
    const [_appProps, _setAppProps] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [stationList, setStationList] = useState([]);
    const [fileAvatar, setFileAvatar] = useState(null);
    const [avatar, setAvatar] = useState("");

    const [socket, setSocket] = useState({
        websocket: null,
    });

    const handleAppProps = () => _setAppProps(appProps);

    useEffect(() => {
        handleAppProps();
        // return () => {
        //     _appProps?.session.closeAll();
        // }
    }, []);

    useEffect(() => {
        setIsAuthenticated(!!Cookies.get('token'));
    }, []);

    return (
        <AppContext.Provider value={{
            appProps,
            isAuthenticated,
            setIsAuthenticated,
            stationList,
            setStationList,
            socket,
            setSocket,
            fileAvatar,
            setFileAvatar,
            avatar,
            setAvatar,
        }}>
            {children}
        </AppContext.Provider>
    )
}

export const useAppBloc = () => {
    const { appProps } = useContext(AppContext);
    return { appBloc: appProps };
}
