
import { useState } from "react";
import { halfCircleGradient } from '../../../assets';

let listItem = [
    {
        name: "T",
        color: '#F5222D',
    }, {
        name: "T",
        color: '#F5222D',
    }, 
    {
        name: "T",
        color: '#F5222D',
    }, {
        name: "T",
        color: '#F5222D',
    }, {
        name: "T",
        color: '#F5222D',
    }, {
        name: "T",
        color: '#F5222D',
    }, {
        name: "T",
        color: '#F5222D',
    }, {
        name: "T",
        color: '#F5222D',
    }
];

function ItemConfigFlow({ onTapDone, onTapCancel, numPhase, lane}) {
  console.log(`numphase: ${numPhase}`)
    const [data, setData] = useState(listItem);
    const [select, setSelect] = useState(lane);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '58px 38px',
                position: 'absolute',
                width: '464px',
                height: '464px',
                left: '250px',
                top: '179px',
                background: '#FFFFFF',
                boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.25)',
                borderRadius: '18.4381px',
                // gap: '60px',
            }}
        >

            <div
                style={{
                    width: '418px',
                    height: '300px',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    padding: '36px 36px'
                }}
            >

                {
                    data.map((datum,i)=>{
                        return(
                            <div
                                key={i}
                                style={{
                                    position: 'absolute',
                                    bottom: '100px',
                                    left: '0px',
                                    width: '418px',
                                    height: '40px',
                                    transform: `rotateZ(${23*(i) + 10}deg)`
                                }}
                                onClick={() => {
                                  console.log(`get: ${i}, cur: ${select}`)
                                  if (i + 1 > numPhase) {
                                    return;
                                  }
                                  if (select === i + 1) {
                                    setSelect(0);
                                  } else {
                                    setSelect(i + 1);
                                  }
                                }}
                            >
                                <div
                                    style={{
                                        width: '40px',
                                        height: '40px',
                                        borderRadius: '80px',
                                        backgroundColor: `${select === i + 1 ? 'green' : i + 1 > numPhase ? 'gray' : 'red'}`,
                                        transform: `rotateZ(${-25 * (i) -3}deg)`,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span
                                        style={{
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '19.1359px',
                                            lineHeight: '133%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            textAlign: 'center',
                                            color: '#FFFFFF',
                                        }}
                                    >
                                        {data[i].name + (i + 1)}
                                    </span>

                                </div>
                            </div>
                        );
                    })
                }
                
                <img src={halfCircleGradient} style={{ margin: '16px' }} />
                <div
                    style={{
                        height: '24px',
                    }}
                ></div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '12px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '3.58798px 4.18598px',
                            gap: '2.99px',
                            width: '78.04px',
                            height: '32.18px',
                            background: '#3751FF',
                            borderRadius: '2.39199px',
                            cursor: 'pointer',
                        }}
                    >
                        <span
                            style={{
                                fontStyle: 'normal',
                                fontWeight: '700',
                                fontSize: '19.1359px',
                                lineHeight: '133%',
                                textAlign: 'center',
                                color: '#FFFFFF',
                            }}
                        >
                            {select === 0 ? 'Auto' : select}
                        </span>

                    </div>
                    <span
                        style={{
                            fontStyle: 'normal',
                            fontWeight: '700',
                            fontSize: '24px',
                            lineHeight: '32px',
                            textAlign: 'center',
                            color: '#3751FF',
                        }}
                    >
                        Điều khiển ưu tiên
                    </span>
                </div>

            </div>

            <div
                style={{
                    height: '36px',
                }}
            >

            </div>

            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '80px', justifyContent: 'center' }} >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '12px 16px',
                        gap: '10px',
                        height: '42px',
                        background: '#FFFFFF',
                        borderRadius: '4px',
                        border: '1px solid #3751FF',
                        width: '121px',
                        cursor: 'pointer',
                    }}
                    onClick={onTapCancel}
                >
                    <span style={{
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '16px',
                        lineHeight: '20px',
                        textSlign: 'center',
                        color: '#3751FF',
                    }}>Hủy bỏ</span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '8px 16px',
                        gap: '10px',
                        height: '42px',
                        background: '#3751FF',
                        borderRadius: '4px',
                        width: '121px',
                        cursor: 'pointer',
                    }}
                    onClick={() => {onTapDone(select)}}
                >
                    <span style={{
                        fontStyle: 'normal',
                        fontWeight: '700',
                        fontSize: '16px',
                        lineHeight: '20px',
                        textSlign: 'center',
                        color: '#FFFFFF',
                    }}>Áp dụng</span>
                </div>
            </div>
        </div>
    );
}

export { ItemConfigFlow };

