import ServiceBase from './service-base';

export class SignalService extends ServiceBase {
  baseUri = '';

  async getListSignal(id) {
    return (await this.get('/api/signal/v1/getList?stationId=' + id)).data;
  }

  async check(id) {
    return (await this.post('/api/signal/v1/v1/check', { stationId: id })).data;
  }

  async updateSignal(obj) {
    // {
    //   "stationId": "string",
    //   "numberSensor": 0,
    //   "signalSensors": [
    //     {
    //       "name": "string"
    //     }
    //   ]
    // }
    return (await this.put('/api/signal/v1/update', obj)).data;
  }
}
