import { useState } from 'react';
import { PowerIcon, Subtract } from '../../assets';
import { Button } from '../shared/Button';
import './traffic-bottom.css';
import { StationService } from '../../services/StationService';

export const TrafficBottom = ({ dataDevideSocket, stationId }) => {
    const [value, setValue] = useState(null);
    const [power, setPower] = useState(null);
    const stationService = new StationService();

    const onSubmit = async () => {
        if (value !== null) {
            stationService.addLaneControl({
                stationId: stationId,
                lane: value,
            });
        }
        if (power !== null) {
            stationService.powerControl({
                stationId: stationId,
                power: power ? "ON" : "OFF",
            })
        }
    }

    return (
      <div className="traffic-bottom">
        <div className="h-line-16"></div>
        <div className="traffic-bottom-content">
          {[0, 1, 2, 3, 4, 5, 6, 7].map((item) => {
            const check = value !== null ? value : dataDevideSocket?.laneactive;
            return (
              <span
                key={item}
                onClick={() => {
                  if (dataDevideSocket?.cpuActiveMode === 0) {
                    return;
                  }
                  if (value === item + 1) {
                    setValue(0);
                    stationService.addLaneControl({
                      stationId: stationId,
                      lane: 9,
                    });
                  } else {
                    setValue(item + 1);
                    stationService.addLaneControl({
                      stationId: stationId,
                      lane: item + 1,
                    });
                  }
                }}
                className={`traffic-bottom-label t${item + 1} ${
                  dataDevideSocket?.numphase > item
                    ? (item + 1 === check
                      ? "green"
                      : (check === 0 ? "grey": "red"))
                    : ""
                }`}
              >
                T{item + 1}
              </span>
            );
          })}
          <div className="traffic-bottom-subtract">
            <Subtract />
          </div>
          <div
            className="traffic-bottom-power"
            onClick={() => {
              let target = !(power !== null
                ? power
                : dataDevideSocket?.cpuActiveMode === 1);
              setPower(target);
              stationService.powerControl({
                stationId: stationId,
                power: target ? "ON" : "OFF",
              });
            }}
          >
            <PowerIcon
              isOn={
                power !== null ? power : dataDevideSocket?.cpuActiveMode !== 0
              }
            />
          </div>
        </div>
        <nav className="traffic-bottom-hero">ĐIỀU KHIỂN ƯU TIÊN</nav>
        {/*
        <div className="d-flex justify-content-center" style={{ gap: "44px" }}>
          <Button
            text={"Hủy bỏ"}
            className="button-cancel"
            onClick={() => {
              setValue(null);
              setPower(null);
            }}
          />
          <Button
            text={"Áp dụng"}
            className="button-apply"
            onClick={onSubmit}
          />
        </div>*/}
      </div>
    );
}
