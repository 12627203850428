import { Droppable, Draggable } from 'react-beautiful-dnd';
import { dragDrop, taskListDocumentText } from '../../assets';
import './form-list.css';
import { TooltipDate } from './TooltipDate';
import { TooltipTime } from './TooltipTime';

export const FormList = ({ strategyList, isDate, cycleForms, timeForms, indexSelected, setIndexDate }) => {
  const renderContent = (strategyList) => {
    return isDate ? <div className='scrollbar-homepage scrollbar-custom-homepage' style={{
                      maxHeight: '365px',
                      paddingRight: '18px',
                    }}>
                      {strategyList.map((item, i) =>
                        <TooltipDate data={timeForms[item.index]}>
                          <div key={i} className={`item-in-list-strategy-date-strategypage ${indexSelected == i ? 'active': ''}`} onClick={() => setIndexDate(i)}>
                            <img src={dragDrop} />
                            <span className='ml-2'>{item.name}</span>
                          </div>
                        </TooltipDate>
                      )}
                    </div>
            : <Droppable droppableId='droppableId-0'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className='scrollbar-homepage scrollbar-custom-homepage' style={{
                  maxHeight: '365px',
                  paddingRight: '18px',
                }} >
                  {cycleForms.map((item, i) =>
                    (<Draggable key={"droppableId-0" + i} draggableId={"droppableId-0" + i.toString()} index={i} >
                      {(provided, snapshot) => (
                        <TooltipTime data={item}>
                          <div key={i} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                            className='item-in-list-strategy-date-strategypage'
                          >
                            <img src={dragDrop} />
                            <span className='ml-2'>Biểu mẫu {i + 1}</span>
                          </div>
                        </TooltipTime>
                      )}
                    </Draggable>))
                  }
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
  }

  return (
    <div className="formlist-container">
      <div className='d-flex w-100 align-items-center mb-4'>
        <img src={taskListDocumentText} alt="" />
        <span className="formlist-title ml-2">Danh sách {isDate ? 'giản đồ' : 'biểu mẫu'}</span>
      </div>
      {renderContent(strategyList)}
    </div>
  );
}
