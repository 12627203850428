import ServiceBase from './service-base';

export class VersionService extends ServiceBase {
  baseUri = '';

  async getLatestVersion() {
    return (await this.get('/api/ota/v1/getLatestVersion?type=AC')).data;
  }
  
  async getVersions() {
    return (await this.get('/api/ota/v1/getList')).data;
  }

  async uploadVersion({ type, version, file }) {
    const data = new FormData();
    data.append("file", file);
    data.append("type", type);
    data.append("version", version);
    
    return (await this.post('/api/ota/v1/upload', data, true)).data;
  }
}
