import { useState } from "react";
import { loading, mapLocation } from "../../assets";
import { useAppBloc } from "../../contexts/AppContext";
import './bottom-sheet-add-station.css';
import Checkbox from "../shared/Checkbox";
import * as ProtoCreator from "../../pages/ProtoCreator";
import { DeviceService } from "../../services/DeviceService";


const AddStationState = {
  NONE: 0,
  INPUT_STATION: 1,
  VERIFY_DEVICE: 2,
  SUBMIT: 3,
}

export const BottomSheetAddStation = ({ onClose, onCheck, onConfirm }) => {
  const [_loading, _setLoading] = useState(false);
  const [_loadData, _setLoadingData] = useState(false);
  const [stationName, setStationName] = useState('');
  const [hardwareId, setHardwareId] = useState('');
  const [addState, setAddState] = useState(AddStationState.NONE);
  const [deviceResult, setDeviceResult] = useState();
  const [isRemovePre, setIsRemovePre] = useState(false);
  const { appBloc } = useAppBloc();
  const deviceService = new DeviceService();

  // useEffect(() => {
  //   appBloc.session.streamCheckDevice.subscribe((v) => {
  //     setDeviceResult(v);
  //   });
  // }, []);

  const handleSubmit = async () => {
    /*if (_loadData === false) {
      _setLoading(true);
      setTimeout(() => {
        _setLoading(false);
        _setLoadingData(true);
      }, 800);
    }*/
    if (addState === AddStationState.NONE) {
      if (hardwareId !== '' && stationName !== '') {
        // if (appBloc && appBloc.websocket) {
        //   appBloc.websocket.send(ProtoCreator.createGetDeviceUserRequest(hardwareId));
        // }
        _setLoading(true);
        const result = await deviceService.checkDevice(hardwareId);
        if (result && result.data) {
          _setLoadingData(true);
          setDeviceResult(result.data);
          setAddState(AddStationState.INPUT_STATION);
        }

        _setLoading(false);
      }
    } else if (addState === AddStationState.INPUT_STATION) {
      onConfirm({
        hardwareId: hardwareId,
        stationName: isRemovePre ? stationName : deviceResult.assignedStationName
      });
      // appBloc.websocket.send(ProtoCreator.createAddStationUserRequest(stationName, hardwareId, deviceResult?.lat, deviceResult?.log));
      // onClose();
    }
  }

  return (
    <>
      <div className="bottom-sheet-add-station-controlpanel">
        <span className="add-station-title">THÊM TRẠM MỚI</span>
        <div style={{ height: '30px' }} ></div>
        <InputFormFieldAddAccount title={"Tên trạm"} value={stationName} onChangeValue={setStationName} placeholder="Nhập ngã tư..." />
        <div style={{ height: '20px' }} ></div>
        <InputFormFieldAddAccount 
          title={"Mã thiết bị"} 
          value={hardwareId} 
          onChangeValue={setHardwareId} 
          placeholder="GW-12:34:56:78" 
          isDisabled={_loadData}
        />
        <div style={{ height: '20px' }} ></div>

        <div style={{ display: _loading ? 'flex' : 'none', width: '28px', height: '28px' }}>
          <img src={loading} />
        </div>

        <span className={`add-station-loading ${_loading ? 'd-flex' : 'd-none'}`}>Đang kiểm tra mã</span>
        <div style={{ width: '100%', display: _loadData ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
          <InputFormFieldFix title={"Tình trạng"} value={deviceResult?.online ? "Online" : "Offline"} isDisabled={true} />
          <div style={{ height: '20px' }} ></div>
          <InputFormFieldFix title={"Loại CPU"} value={deviceResult?.cpuType} isDisabled={true} />
          <div style={{ height: '20px' }} ></div>
          <InputFormFieldLocation title={"Tọa độ"} x={deviceResult?.locationLat} y={deviceResult?.locationLong} />
          <div className="mr-auto mt-3 mb-5">
            <Checkbox value={isRemovePre} onChange={(event) => setIsRemovePre(event.target.checked)} text={`Xác nhận xóa trạm ${deviceResult?.assignedStationName}`} />
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '30px', justifyContent: 'space-between', marginTop: '30px' }} >
          <div className="add-station-cancel" onClick={onClose}>Hủy bỏ</div>
          <div className="add-station-check" onClick={handleSubmit}>
            {addState === AddStationState.NONE ? 'Kiểm tra' : 'Xác nhận'}
          </div>
        </div>
      </div>
    </>
  );
}

function InputFormFieldAddAccount({ title, value, onChangeValue, isDisabled = false, placeholder = "" }) {
  const [_value, _setValue] = useState(value);
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '544px', justifyContent: 'flex-start', alignItems: 'center' }}>
      <span className='title-overview-info-controlpanel' style={{ width: '142px', textAlign: 'end' }}>
        {title}
      </span>
      <div style={{ width: '14px' }}></div>
      <input value={_value} onChange={(event) => {
        onChangeValue(event.target.value);
        _setValue(event.target.value);
      }} type={'text'} className='input-form-controlpanel' style={{ width: '467px' }} disabled={isDisabled} placeholder={placeholder} />
    </div>
  );
}

function InputFormFieldFix({ title, value, isDisabled = false }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '544px', justifyContent: 'flex-start', alignItems: 'center' }}>
      <span className='title-overview-info-controlpanel' style={{ width: '142px', textAlign: 'end' }}>
        {title}
      </span>
      <div style={{ width: '14px' }}></div>
      <input value={value} type={'text'} className='input-form-controlpanel' style={{ width: '467px' }} disabled={isDisabled} />
    </div>
  );
}

function InputFormFieldLocation({ title, x, y }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', width: '544px', justifyContent: 'start', alignItems: 'center' }}>
      <div style={{ width: '138px', paddingRight: '14px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        <span className='title-overview-info-controlpanel' style={{ textAlign: 'end' }}>
          {title}
        </span>
      </div>
      <div style={{ width: '48px', height: '48px', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#3751FF' }}>
        <img src={mapLocation} />
      </div>

      <div style={{ width: '14px' }}></div>
      <input value={x} type={'text'} className='input-form-controlpanel' style={{ width: '160px' }} disabled />
      <div style={{ width: '14px' }}></div>
      <input value={y} type={'text'} className='input-form-controlpanel' style={{ width: '160px' }} disabled />
    </div>
  );
}
