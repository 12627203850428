import { useState } from 'react';
import { Select } from '../../../components/shared/Select';
import './styles';

const listRole = [
    {
        key: 'admin',
        text: 'Admin',
    }, {
        key: 'user',
        text: 'User',
    }
]

const listLanguage = [
    {
        key: 'vn',
        text: 'Tiếng Việt',
    }, {
        key: 'en',
        text: 'Tiếng Anh',
    }
]

function InputFormFieldSystemSetting({title,value,onChangeValue}){
    return(
        <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'start'}}>
            <span className='title-overview-info-systemsettingpage' style={{width:'250px',textAlign:'start'}}>
                {title}
            </span>
            <div style={{width:'10px'}}></div>
            <input value={value} onChange={(event)=>onChangeValue(event.target.value)} type={'text'} className='password-form-overview-info-systemsettingpage'style={{width:'467px'}}  />
        </div>
    );
}

function SelectorForm({title, roles}){
    const [isSelect,onSelect] = useState(false);
    return (
        <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'start'}}>
            <span className='title-overview-info-systemsettingpage' style={{width:'250px',textAlign:'start'}}>
                {title}
            </span>
            <div style={{width:'10px'}}></div>
            <Select className="w-467 gray-5" options={roles} onChange={() => {}} />
        </div>     
    );
}

function Overview({websiteName,websiteUrl,sologan,managerEmail,role,lannguage}) {
    const [_websiteName,_setWebsiteName] = useState(websiteName);
    const [_sologan,_setSologan] = useState(sologan);
    const [_websiteUrl,_setWebsiteUrl] = useState(websiteUrl);
    const [_managerEmail,_setManagerEmail] = useState(managerEmail);

    function onTapSave(){

    }

    return(
        <div style={{display:'flex',flexDirection:'column',width:'100%'}}>
            <InputFormFieldSystemSetting value={_websiteName} onChangeValue={_setWebsiteName} title={'Tên website'}/>
            <div style={{height:'20px'}} ></div>
            <InputFormFieldSystemSetting value={_sologan} onChangeValue={_setSologan}  title={'Khẩu hiệu'}/>
            <div style={{height:'20px'}} ></div>
            <InputFormFieldSystemSetting value={_websiteUrl} onChangeValue={_setWebsiteUrl}  title={'Địa chỉ website (URL)'}/>
            <div style={{height:'20px'}} ></div>
            <InputFormFieldSystemSetting value={_managerEmail} onChangeValue={_setManagerEmail}  title={'Địa chỉ Email quản trị'}/>

            <div style={{height:'20px'}} ></div>
            <SelectorForm title={'Vai trò'} roles={listRole} />
            <div style={{height:'20px'}} ></div>
            <SelectorForm title={'Ngôn ngữ'} roles={listLanguage} />

            <div style={{height:'20px'}} ></div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <button 
                    onClick={onTapSave}
                    type='button' 
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '12px 71px',
                        backgroundColor: '#3751FF',
                        borderRadius: '4px',
                        borderWidth:'0px',
                    }} 
                >
                    <span
                        style={{
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '133%',
                            color: '#FFFFFF',
                        }}
                    >Lưu lại</span>
            </button>
            </div>
        </div>
    );
}

export { Overview };
