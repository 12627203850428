import axios from 'axios';
import Cookies from 'js-cookie';

const urlsNotAuthHeader = ['api/auth/v1/refreshToken', 'api/auth/v1/login/user', 'api/auth/v1/login/admin'];

axios.interceptors.request.use(function (config) {
  if (!urlsNotAuthHeader.some(item => config.url.includes(item))) {
    const token = Cookies.get('token');
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
  }
  
  return config;
}, (error) => Promise.reject(error));

axios.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const config = error?.config;

  if (error === undefined || error?.response?.status === 400 && error?.response?.data.message === '400 Bad Request: "{"error":"invalid_grant","error_description":"Token is not active"}"') {
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    window.location.reload();
  }

  // Get token when expired
  if (error?.response?.status === 401 && !config?._retry) {
    config._retry = true;
    const token = Cookies.get('refreshToken');
    const result = (await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/v1/refreshToken`, { refreshToken: token })).data;      

    if (result?.data?.token) {
      Cookies.set('token', result?.data?.token);
      Cookies.set('refreshToken', result?.data?.refreshToken);
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${result?.data?.token}`,
      };
    } else {
      Cookies.remove('token');
      Cookies.remove('refreshToken');
      window.location.reload();
    }
    return axios(config);
  }

  return Promise.reject(error?.response);
});

export default class ServiceBase {
  baseUri = "";

  async findAll(searchCondition) {
    const requestUrl = this.baseUri + this.createQuery(searchCondition ?? {});
    return (await this.get(requestUrl)).data;
  }

  async get(url) {
    const requestUrl = url.startsWith('http') ? url : `${process.env.REACT_APP_API_BASE_URL}${url}`;
    try {
      const result = await axios.get(requestUrl);
      return result;
    } catch (error) {
      this.errorHandling(error);
    }
  }

  async post(url, data, isFormData = false) {
    const requestUrl = url.startsWith('http') ? url : `${process.env.REACT_APP_API_BASE_URL}${url}`;
    try {
      const result = await axios.post(requestUrl, data, {
        headers: { "Content-Type": isFormData ? "multipart/form-data" : "application/json" }
      });
      return result;
    } catch (error) {
      this.errorHandling(error);
    }
  }

  async put(url, data) {
    const requestUrl = url.startsWith('http') ? url : `${process.env.REACT_APP_API_BASE_URL}${url}`;
    try {
      const result = await axios.put(requestUrl, data);
      return result;
    } catch (error) {
      this.errorHandling(error);
    }
  }

  async delete(url) {
    const requestUrl = url.startsWith('http') ? url : `${process.env.REACT_APP_API_BASE_URL}${url}`;
    try {
      const result = await axios.delete(requestUrl);
      return result;
    } catch (error) {
      this.errorHandling(error);
    }
  }

  createQuery(condition) {
    const query = Object.entries(condition)
      .filter(e => (Array.isArray(e[1]) ? e[1][0] !== '' : e[1]))
      .filter(([_, value]) => {
        if (Array.isArray(value)) {
          return value.join(',').length !== 0;
        }
        return value !== '';
      })
      .map(([key, value]) => [encodeURI(key), encodeURI('' + value)].join('='))
      .join('&');
    return query ? '?' + query : '';
  }

  errorHandling(error) {
    console.log(error);
    throw error;
  }
}

