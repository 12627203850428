// define all error label here
export const ERROR_USER_NAME =
  "Bạn chưa nhập “TÊN NGƯỜI DÙNG”, vui lòng nhập để tiếp tục !";
export const ERROR_LOGIN = "Tài khoản hoặc mật khẩu chưa chính xác, vui lòng thử lại !";

export const USER_ROLE = {
  ADMIN: "Quản trị",
  OPERATOR: "Vận hành",
  VIEWER: "Giám sát",
};

export const USER_ROLE_OPTIONS = [
  {
    key: "ADMIN",
    text: "Quản trị",
  },
  {
    key: "OPERATOR",
    text: "Vận hành",
  },
  {
    key: "VIEWER",
    text: "Giám sát",
  },
];

export const signColors = {
  0: "#2f2f2f",
  1: "#52C41A",
  2: "#F5222D",
  3: "#FADB14",
};

