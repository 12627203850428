import './bottom-buttom-component.css';
function BottomButtom({onTap,style,title,classNameText}) {
    return(
        <div onClick={onTap} style={style} className="bottom-buttom-component" 
             >
            <span className={classNameText}>{title}</span>
        </div>
    )
}

export default BottomButtom