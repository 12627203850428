import React, { useState } from 'react';
import {Carousel} from 'react-bootstrap';

import { slideshowHomepage } from '../../assets';
import './styles';

const listItems = [
  {
    title: 'hệ thống quản lý tín hiệu đèn giao thông '.toUpperCase(),
    content: 'Quản lý đèn tín hiệu giao thông xem lại trạng thái tín hiệu của đèn tại từng thời điểm. Xem lại cấu hình hiện tại đang cài đặt, cấu hình điều khiển nút đèn giao thông từ xa.',
    src: slideshowHomepage,
  },
  {
    title: 'hệ thống quản lý tín hiệu đèn giao thông '.toUpperCase(),
    content: 'Quản lý đèn tín hiệu giao thông xem lại trạng thái tín hiệu của đèn tại từng thời điểm. Xem lại cấu hình hiện tại đang cài đặt, cấu hình điều khiển nút đèn giao thông từ xa.',
    src: slideshowHomepage,
  },
  {
    title: 'hệ thống quản lý tín hiệu đèn giao thông '.toUpperCase(),
    content: 'Quản lý đèn tín hiệu giao thông xem lại trạng thái tín hiệu của đèn tại từng thời điểm. Xem lại cấu hình hiện tại đang cài đặt, cấu hình điều khiển nút đèn giao thông từ xa.',
    src: slideshowHomepage,
  },
];

const ItemSlideShow = ({ title, content, srcImage }) => {
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    }} >
      <div>
        <img
          className='image-slide'
          src={srcImage}
          style={{
            borderRadius: '8px',
          }}
        />
      </div>
      <div style={{
        position: 'absolute',
        color: 'white',
        top: '25%',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <span className='title-item_slide-show-homepage'>{title}</span>
        <span className='content-item_slide-show-homepage' >{content}</span>
      </div>
    </div>
  )

}

const SlideShow = () => {
  const [indexSlide, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  }

  return (
    <div 
      className="slider-wrapper"
     >
      <Carousel
        indicators={false}
        touch={true}
        activeIndex={indexSlide}
        controls={false}
        interval={2000}
        style={{
          borderRadius: '8px',
          maxWidth: '892px',
          maxHeight: '460px',
          position:'relative',
        }}>
        {listItems.map((item, idx) => {
          return (
            <Carousel.Item key={idx}>
              <ItemSlideShow content={item.content} title={item.title} srcImage={item.src} />
            </Carousel.Item>
          )
        })}
        <div className="carousel-indicators-homepage">
          {listItems.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => handleSelect(index)}
                style={{ opacity: '1', margin: '0px 6px 0px 6px', backgroundColor: index === indexSlide ? '#3751FF' : '#F0F0F0', height: '10px', width: '10px', borderRadius: '100%' }}
              >
              </div>
            );
          })}
        </div>
      </Carousel>
    </div>
  );
}

export default SlideShow;
