import { useState } from 'react';
import './edit-arrow.css';
import { Button } from '../shared/Button';
import { Select } from '../shared/Select';
import { GoCrossTraffic, Train } from '../../assets';
import { FormInput } from '../shared/FormInput';


export const EditArrow = ({ data, onCancel, onSubmit }) => {
    const [value, setValue] = useState(data.phase || 0);
    const [item, setItem] = useState(data);
        
    const options = [
        { key: 0, text: 1 },
        { key: 1, text: 2 },
        { key: 2, text: 3 },
        { key: 3, text: 4 },
        { key: 4, text: 5 },
        { key: 5, text: 6 },
        { key: 6, text: 7 },
        { key: 7, text: 8 },
    ]

    const handleChangeText = (e) => {
        setItem({
            ...item,
            content: e.target.value,
        })
    }

    const handleChangePhase = (value) => {
        setValue(value);
        setItem({
            ...item,
            phase: parseInt(value),
        })
    }

    return (
        <div className="edit-arrow">
            <nav className="edit-arrow-title">{data?.type === "TEXT" ? "Cấu hình text" : "Cấu hình mũi tên"}</nav>
                {data?.type === "TEXT" ? (
                    <div className="mt-3">
                        <FormInput title={"Name"} value={item.content} onChange={handleChangeText} />
                    </div>
                ) : (
                <div className="edit-arrow-content">
                    <div className="edit-arrow-content-left">
                        <span>Chọn pha :</span>
                        <Select options={options} value={value} onChange={handleChangePhase} />
                    </div>
                    <div className="edit-arrow-content-right">
                        <div className="edit-arrow-right-item">
                            <div className="traffic-green">
                                <GoCrossTraffic color="#fff" />
                            </div>
                            <input type="checkbox" />
                        </div>
                        <div className="edit-arrow-right-item">
                            <div className="traffic-red">
                                <Train color="#fff" />
                            </div>
                            <input type="checkbox" />
                        </div>
                    </div>
                </div>)}
            <div className="edit-arrow-bottom">
                <Button text={'Hủy bỏ'} className="button-cancel" onClick={onCancel} />
                <Button text={'Áp dụng'} onClick={() => onSubmit(item)} />
            </div>
        </div>
    );
}
