import { useState } from "react";
import { Plus } from "../../assets";
import ModelBottomSheet from "../ModelBottomSheet/ModelBottomSheet";
import { InputSearch } from "../shared/InputSearch";
import { BottomSheetAddStation } from "./BottomSheetAddStation";

export const ListStationOption = ({ isChoosen, onSelect, value, setValue, onConfirm }) => {
  const [show, setShow] = useState(false);

  const handleConfirm = async (data) => {
    await onConfirm(data);
    setShow(false);
  }

  return (
    <>
      <div className="h-line-16"></div>
      <div className="d-flex justify-content-between align-items-center w-100" style={{ gap: '18px'}}>
        <InputSearch value={value} onChange={value => setValue(value)}  />
        <div onClick={() => { setShow(true) }} className="plus-icon">
          <Plus fill={"#FFFFFF"} />
        </div>
      </div>
      <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }} >
        <div className="option-group">
          <div className="radio-group">
            <div className={`radio-group-icon ${isChoosen === 0 ? "active": ""}`} onClick={() => onSelect(0)}></div>
            <span className="radio-group-text">Tất cả</span>
          </div>
          <div className="radio-group">
            <div className={`radio-group-icon ${isChoosen === 1 ? "active": ""}`} onClick={() => { onSelect(1) }}></div>
            <span className="radio-group-text">Online</span>
          </div>
          <div className="radio-group">
            <div className={`radio-group-icon ${isChoosen === 2 ? "active": ""}`} onClick={() => { onSelect(2) }}></div>
            <span className="radio-group-text">Offline</span>
          </div>
        </div>

        {/*  bottom sheet add station */}
        <ModelBottomSheet
          key={'ModelBottomSheetControlpanel'}
          animation={true}
          centered={true}
          size={"lg"}
          show={show}
          children={<BottomSheetAddStation onClose={() => { setShow(false) }} onConfirm={handleConfirm} />}
        />
      </div>
    </>
  );
}
