import { settingExpend } from "../../assets";

export const SideBarCollased = ({ isExpanded, setIsExpanded, children }) => {
    return (
        <div className={`drawer-option-controlpanelpage ${isExpanded ? '' : 'hide'}`} style={{ width: isExpanded ? '340px' : '46px' }} >
            {!isExpanded && <img src={settingExpend} style={{ cursor: 'pointer' }} onClick={() => { setIsExpanded(true) }} />}
            {isExpanded && children}
        </div>        
    );
}
