import ServiceBase from './service-base';

export class NoteService extends ServiceBase {
  baseUri = '';

  async getList() {
    return (await this.get('/api/note/v1/getList')).data;
  }

  async createNote({ message }) {
    return (await this.post('/api/note/v1/create', { message })).data;
  }

  async uploadImage(file) {
    const data = new FormData();
    data.append("file", file);
    
    return (await this.post('/api/media/v1/upload', data, true)).data;
  }
}
