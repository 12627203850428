import { CustomCalender, CustomTable } from "../../../components";
import { exported, calender } from '../../../assets';
import { useEffect, useRef, useState } from "react";
import { StatisticService } from "../../../services/StatisticService";
import moment from "moment";
import { exportToCSV } from "../../../utils";

let listContentTableReportCommonManagementPage = [
    {
        id: '1',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '2',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '3',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '4',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '5',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '6',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '7',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '8',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '9',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '10',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '11',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '12',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '13',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
    {
        id: '14',
        name: 'Thiết bị tại trạm Ngã tư Mai Thúc Loan - Đinh Tiên Hoàng',
        action: 'cập nhật thiết lập chiến lược',
        dateTime: '2022-04-01 00:00:01'
    },
];

function ItemTitleTableReport() {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '12px',
            width: '936px',
            height: '44px',
            // opacity: '0.8',
            gap: '24px',
            borderBottom: '1px solid #EFF1F1',
        }}>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '42px' }} >#ID</span>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '80px' }} >Loại</span>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '500px' }} >Nội dung</span>
            <span className="item-title-table-report-commonmanagementpage" style={{ width: '210px' }} >Thời gian</span>

        </div>
    );
}

function ItemContentTableReport({ id, name, action, time,index }) {
    return (
        <div className='row-table-report'>
            <span className="item-content-table-report-commonmanagementpage" style={{ width: '42px' }} >{id}</span>
            <span className="item-content-table-report-commonmanagementpage" style={{ width: '80px' }} >{action === 'ACTION' ? 'Hành động' : 'Truy cập'}</span>
            <span className="item-content-table-report-commonmanagementpage" style={{ width: '500px' }} >{name}</span>
            <span className="item-content-table-report-commonmanagementpage" style={{ width: '210px' }} >{time}</span>
        </div>
    )
}

function ReportOption() {
    const [_listContent, _updateListContent] = useState([]);
    const statisticService = new StatisticService();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    useEffect(async () => {
        const res = await statisticService.getNotification();
        if (res && res.data) {
            _updateListContent(res.data.items.filter(item => !item.content.includes('số 9')));
        }
    }, []);

    const isBetweenTime = (time, startTime, endTime) => {
        return moment(moment(time).format('YYYY-MM-DD')).isBetween(moment(startTime).format('YYYY-MM-DD'), moment(endTime).format('YYYY-MM-DD'), undefined, "[]");
    }

    return (
        <>
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                alignItems: 'center',
                paddingRight: '76px',
            }} >
                <CustomCalender
                    height={'44px'}
                    width={'240px'}
                    icon={<div style={{ width: '30px', height: '30px', backgroundColor: 'rgba(55, 81, 255, 0.2)', borderRadius: '5px', display: 'flex', flexDirection: 'column', padding: '4px' }} ><img src={calender} /></div>}
                    dateTime={new Date()}
                    setStartDateFilter={setStartDate}
                    setEndDateFilter={setEndDate}
                    startDateFilter={startDate}
                    endDateFilter={endDate}
                />
                <div style={{ width: '24px' }} ></div>
                <div className='btn-export'
                    onClick={() => exportToCSV(_listContent.filter(item => isBetweenTime(item.dateTime, startDate, endDate)), `reports-${moment().format('DD-MM-YYYY')}`)}
                ><img src={exported} /> Export</div>
            </div>

            <div>
                <CustomTable
                    isDivider={false}
                    style={{ width: '954px', height: '802px'}}
                    titleItem={<ItemTitleTableReport />}
                    children={
                        <div>
                            {_listContent.filter(item => isBetweenTime(item.dateTime, startDate, endDate)).map((content, i) => {
                                return (
                                    <ItemContentTableReport
                                        index={i}
                                        key={i}
                                        id={content.id}
                                        name={content.content}
                                        action={content.type}
                                        time={moment(content.dateTime).format("YYYY-MM-DD HH:mm:ss")}
                                    />
                                );
                            })}
                        </div>
                    }

                />
            </div>
        </>
    );
}

export { ReportOption };