/* eslint-disable */
// source: device_proto.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var common_proto_pb = require('./common_proto_pb.js');
goog.object.extend(proto, common_proto_pb);
goog.exportSymbol('proto.AnnounceErrorDeviceRequest', null, global);
goog.exportSymbol('proto.ControlCycleFormUserDeviceRequest', null, global);
goog.exportSymbol('proto.ControlLaneDeviceRequest', null, global);
goog.exportSymbol('proto.ControlLaneDeviceResponse', null, global);
goog.exportSymbol('proto.DeviceAuthMessage', null, global);
goog.exportSymbol('proto.DeviceLogUpdate', null, global);
goog.exportSymbol('proto.DeviceLoginRequest', null, global);
goog.exportSymbol('proto.DeviceLoginResponse', null, global);
goog.exportSymbol('proto.DeviceMessage', null, global);
goog.exportSymbol('proto.DeviceRegisterRequest', null, global);
goog.exportSymbol('proto.DeviceRegisterResponse', null, global);
goog.exportSymbol('proto.DeviceUpdateConfigureRequest', null, global);
goog.exportSymbol('proto.DeviceUpdateConfigureResponse', null, global);
goog.exportSymbol('proto.DeviceUpdateMessage', null, global);
goog.exportSymbol('proto.DeviceUpdateScheduleRequest', null, global);
goog.exportSymbol('proto.DeviceUpdateScheduleResponse', null, global);
goog.exportSymbol('proto.GetCoordinateDeviceRequest', null, global);
goog.exportSymbol('proto.GetCoordinateDeviceResponse', null, global);
goog.exportSymbol('proto.PingWebsocketDeviceRequest', null, global);
goog.exportSymbol('proto.PingWebsocketDeviceResponse', null, global);
goog.exportSymbol('proto.RequireStreamDeviceRequest', null, global);
goog.exportSymbol('proto.RequireStreamDeviceResponse', null, global);
goog.exportSymbol('proto.SetActiveTimeDeviceRequest', null, global);
goog.exportSymbol('proto.SetActiveTimeDeviceResponse', null, global);
goog.exportSymbol('proto.SetCycleConfigDeviceRequest', null, global);
goog.exportSymbol('proto.StateOnChangeDeviceMessage', null, global);
goog.exportSymbol('proto.SyncTimeDeviceRequest', null, global);
goog.exportSymbol('proto.SyncTimeDeviceResponse', null, global);
goog.exportSymbol('proto.TriggerNotifyUserDeviceRequest', null, global);
goog.exportSymbol('proto.TriggerNotifyUserDeviceResponse', null, global);
goog.exportSymbol('proto.UpdateCoordinateDeviceRequest', null, global);
goog.exportSymbol('proto.UpdateCoordinateDeviceResponse', null, global);
goog.exportSymbol('proto.UpdateScheduleUserDeviceRequest', null, global);
goog.exportSymbol('proto.UpdateScheduleUserDeviceResponse', null, global);
goog.exportSymbol('proto.UserDeviceMessage', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceRegisterRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceRegisterRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceRegisterRequest.displayName = 'proto.DeviceRegisterRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceRegisterResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.DeviceRegisterResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceRegisterResponse.displayName = 'proto.DeviceRegisterResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceLoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceLoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceLoginRequest.displayName = 'proto.DeviceLoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceLoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.DeviceLoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceLoginResponse.displayName = 'proto.DeviceLoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceLogUpdate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceLogUpdate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceLogUpdate.displayName = 'proto.DeviceLogUpdate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.StateOnChangeDeviceMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.StateOnChangeDeviceMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.StateOnChangeDeviceMessage.displayName = 'proto.StateOnChangeDeviceMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceUpdateConfigureRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.DeviceUpdateConfigureRequest.repeatedFields_, null);
};
goog.inherits(proto.DeviceUpdateConfigureRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceUpdateConfigureRequest.displayName = 'proto.DeviceUpdateConfigureRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceUpdateConfigureResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.DeviceUpdateConfigureResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceUpdateConfigureResponse.displayName = 'proto.DeviceUpdateConfigureResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceUpdateScheduleRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceUpdateScheduleRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceUpdateScheduleRequest.displayName = 'proto.DeviceUpdateScheduleRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceUpdateScheduleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.DeviceUpdateScheduleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceUpdateScheduleResponse.displayName = 'proto.DeviceUpdateScheduleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceAuthMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceAuthMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceAuthMessage.displayName = 'proto.DeviceAuthMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PingWebsocketDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.PingWebsocketDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PingWebsocketDeviceRequest.displayName = 'proto.PingWebsocketDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.PingWebsocketDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.PingWebsocketDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.PingWebsocketDeviceResponse.displayName = 'proto.PingWebsocketDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateCoordinateDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateCoordinateDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateCoordinateDeviceRequest.displayName = 'proto.UpdateCoordinateDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateCoordinateDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.UpdateCoordinateDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateCoordinateDeviceResponse.displayName = 'proto.UpdateCoordinateDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.AnnounceErrorDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.AnnounceErrorDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.AnnounceErrorDeviceRequest.displayName = 'proto.AnnounceErrorDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceUpdateMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceUpdateMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceUpdateMessage.displayName = 'proto.DeviceUpdateMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequireStreamDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.RequireStreamDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequireStreamDeviceRequest.displayName = 'proto.RequireStreamDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.RequireStreamDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.RequireStreamDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.RequireStreamDeviceResponse.displayName = 'proto.RequireStreamDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ControlLaneDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ControlLaneDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ControlLaneDeviceRequest.displayName = 'proto.ControlLaneDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ControlLaneDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.ControlLaneDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ControlLaneDeviceResponse.displayName = 'proto.ControlLaneDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetActiveTimeDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SetActiveTimeDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetActiveTimeDeviceRequest.displayName = 'proto.SetActiveTimeDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetActiveTimeDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.SetActiveTimeDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetActiveTimeDeviceResponse.displayName = 'proto.SetActiveTimeDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SetCycleConfigDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.SetCycleConfigDeviceRequest.repeatedFields_, null);
};
goog.inherits(proto.SetCycleConfigDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SetCycleConfigDeviceRequest.displayName = 'proto.SetCycleConfigDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SyncTimeDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.SyncTimeDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SyncTimeDeviceRequest.displayName = 'proto.SyncTimeDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.SyncTimeDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.SyncTimeDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.SyncTimeDeviceResponse.displayName = 'proto.SyncTimeDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetCoordinateDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.GetCoordinateDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetCoordinateDeviceRequest.displayName = 'proto.GetCoordinateDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.GetCoordinateDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.GetCoordinateDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.GetCoordinateDeviceResponse.displayName = 'proto.GetCoordinateDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TriggerNotifyUserDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.TriggerNotifyUserDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TriggerNotifyUserDeviceRequest.displayName = 'proto.TriggerNotifyUserDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.TriggerNotifyUserDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.TriggerNotifyUserDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.TriggerNotifyUserDeviceResponse.displayName = 'proto.TriggerNotifyUserDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateScheduleUserDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UpdateScheduleUserDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateScheduleUserDeviceRequest.displayName = 'proto.UpdateScheduleUserDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UpdateScheduleUserDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.UpdateScheduleUserDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UpdateScheduleUserDeviceResponse.displayName = 'proto.UpdateScheduleUserDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ControlCycleFormUserDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ControlCycleFormUserDeviceRequest.repeatedFields_, null);
};
goog.inherits(proto.ControlCycleFormUserDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ControlCycleFormUserDeviceRequest.displayName = 'proto.ControlCycleFormUserDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.UserDeviceMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.UserDeviceMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.UserDeviceMessage.displayName = 'proto.UserDeviceMessage';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.DeviceMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.DeviceMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.DeviceMessage.displayName = 'proto.DeviceMessage';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceRegisterRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceRegisterRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceRegisterRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceRegisterRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    hardwareid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mantoken: jspb.Message.getFieldWithDefault(msg, 2, ""),
    devicetype: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numphase: jspb.Message.getFieldWithDefault(msg, 4, 0),
    firmwareversion: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cpuimei: jspb.Message.getFieldWithDefault(msg, 6, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    log: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    gatewayversion: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceRegisterRequest}
 */
proto.DeviceRegisterRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceRegisterRequest;
  return proto.DeviceRegisterRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceRegisterRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceRegisterRequest}
 */
proto.DeviceRegisterRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMantoken(value);
      break;
    case 3:
      var value = /** @type {!proto.DeviceType} */ (reader.readEnum());
      msg.setDevicetype(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumphase(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmwareversion(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpuimei(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLog(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setGatewayversion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceRegisterRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceRegisterRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceRegisterRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceRegisterRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHardwareid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMantoken();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDevicetype();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getNumphase();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getFirmwareversion();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCpuimei();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      7,
      f
    );
  }
  f = message.getLog();
  if (f !== 0.0) {
    writer.writeFloat(
      8,
      f
    );
  }
  f = message.getGatewayversion();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string hardwareId = 1;
 * @return {string}
 */
proto.DeviceRegisterRequest.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setHardwareid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string manToken = 2;
 * @return {string}
 */
proto.DeviceRegisterRequest.prototype.getMantoken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setMantoken = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional DeviceType deviceType = 3;
 * @return {!proto.DeviceType}
 */
proto.DeviceRegisterRequest.prototype.getDevicetype = function() {
  return /** @type {!proto.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.DeviceType} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setDevicetype = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 numPhase = 4;
 * @return {number}
 */
proto.DeviceRegisterRequest.prototype.getNumphase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setNumphase = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string firmwareVersion = 5;
 * @return {string}
 */
proto.DeviceRegisterRequest.prototype.getFirmwareversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setFirmwareversion = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cpuIMEI = 6;
 * @return {string}
 */
proto.DeviceRegisterRequest.prototype.getCpuimei = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setCpuimei = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional float lat = 7;
 * @return {number}
 */
proto.DeviceRegisterRequest.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional float log = 8;
 * @return {number}
 */
proto.DeviceRegisterRequest.prototype.getLog = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setLog = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional string gatewayVersion = 9;
 * @return {string}
 */
proto.DeviceRegisterRequest.prototype.getGatewayversion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterRequest} returns this
 */
proto.DeviceRegisterRequest.prototype.setGatewayversion = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceRegisterResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceRegisterResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceRegisterResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceRegisterResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceRegisterResponse}
 */
proto.DeviceRegisterResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceRegisterResponse;
  return proto.DeviceRegisterResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceRegisterResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceRegisterResponse}
 */
proto.DeviceRegisterResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceRegisterResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceRegisterResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceRegisterResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceRegisterResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.DeviceRegisterResponse.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceRegisterResponse} returns this
 */
proto.DeviceRegisterResponse.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeviceRegisterResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeviceRegisterResponse} returns this
*/
proto.DeviceRegisterResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceRegisterResponse} returns this
 */
proto.DeviceRegisterResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceRegisterResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceLoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceLoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceLoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cpuimage: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceLoginRequest}
 */
proto.DeviceLoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceLoginRequest;
  return proto.DeviceLoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceLoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceLoginRequest}
 */
proto.DeviceLoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCpuimage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceLoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceLoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceLoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCpuimage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.DeviceLoginRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceLoginRequest} returns this
 */
proto.DeviceLoginRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cpuImage = 2;
 * @return {string}
 */
proto.DeviceLoginRequest.prototype.getCpuimage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceLoginRequest} returns this
 */
proto.DeviceLoginRequest.prototype.setCpuimage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceLoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceLoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceLoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceLoginResponse}
 */
proto.DeviceLoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceLoginResponse;
  return proto.DeviceLoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceLoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceLoginResponse}
 */
proto.DeviceLoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceLoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceLoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceLoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeviceLoginResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeviceLoginResponse} returns this
*/
proto.DeviceLoginResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceLoginResponse} returns this
 */
proto.DeviceLoginResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceLoginResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceLogUpdate.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceLogUpdate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceLogUpdate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLogUpdate.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceLogUpdate}
 */
proto.DeviceLogUpdate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceLogUpdate;
  return proto.DeviceLogUpdate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceLogUpdate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceLogUpdate}
 */
proto.DeviceLogUpdate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceLogUpdate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceLogUpdate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceLogUpdate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceLogUpdate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.DeviceLogUpdate.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceLogUpdate} returns this
 */
proto.DeviceLogUpdate.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.DeviceLogUpdate.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceLogUpdate} returns this
 */
proto.DeviceLogUpdate.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.StateOnChangeDeviceMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.StateOnChangeDeviceMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.StateOnChangeDeviceMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StateOnChangeDeviceMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    update1second: (f = msg.getUpdate1second()) && common_proto_pb.DeviceState.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.StateOnChangeDeviceMessage}
 */
proto.StateOnChangeDeviceMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.StateOnChangeDeviceMessage;
  return proto.StateOnChangeDeviceMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.StateOnChangeDeviceMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.StateOnChangeDeviceMessage}
 */
proto.StateOnChangeDeviceMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.DeviceState;
      reader.readMessage(value,common_proto_pb.DeviceState.deserializeBinaryFromReader);
      msg.setUpdate1second(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.StateOnChangeDeviceMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.StateOnChangeDeviceMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.StateOnChangeDeviceMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.StateOnChangeDeviceMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUpdate1second();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.DeviceState.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.StateOnChangeDeviceMessage.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.StateOnChangeDeviceMessage} returns this
 */
proto.StateOnChangeDeviceMessage.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceState update1Second = 2;
 * @return {?proto.DeviceState}
 */
proto.StateOnChangeDeviceMessage.prototype.getUpdate1second = function() {
  return /** @type{?proto.DeviceState} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.DeviceState, 2));
};


/**
 * @param {?proto.DeviceState|undefined} value
 * @return {!proto.StateOnChangeDeviceMessage} returns this
*/
proto.StateOnChangeDeviceMessage.prototype.setUpdate1second = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.StateOnChangeDeviceMessage} returns this
 */
proto.StateOnChangeDeviceMessage.prototype.clearUpdate1second = function() {
  return this.setUpdate1second(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.StateOnChangeDeviceMessage.prototype.hasUpdate1second = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.DeviceUpdateConfigureRequest.repeatedFields_ = [14,15];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceUpdateConfigureRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceUpdateConfigureRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceUpdateConfigureRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateConfigureRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    typeCpu: jspb.Message.getFieldWithDefault(msg, 2, 0),
    walkingModeEnable: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    railwayModeEnable: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    powerMetersEnable: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    lockedHardControlEnable: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    isFlashing: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isEnableOut485LampPort: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    railwayDelayOnTime: jspb.Message.getFieldWithDefault(msg, 9, 0),
    railwayDelayOffTime: jspb.Message.getFieldWithDefault(msg, 10, 0),
    walkingTime: jspb.Message.getFieldWithDefault(msg, 11, 0),
    latitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    longitude: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    indexCardList: jspb.Message.toObjectList(msg.getIndexCardList(),
    common_proto_pb.CardConfig.toObject, includeInstance),
    phasePinConfigList: jspb.Message.toObjectList(msg.getPhasePinConfigList(),
    common_proto_pb.MTFCPhaseGateConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceUpdateConfigureRequest}
 */
proto.DeviceUpdateConfigureRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceUpdateConfigureRequest;
  return proto.DeviceUpdateConfigureRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceUpdateConfigureRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceUpdateConfigureRequest}
 */
proto.DeviceUpdateConfigureRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {!proto.DeviceType} */ (reader.readEnum());
      msg.setTypeCpu(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWalkingModeEnable(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRailwayModeEnable(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPowerMetersEnable(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLockedHardControlEnable(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFlashing(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsEnableOut485LampPort(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRailwayDelayOnTime(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRailwayDelayOffTime(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setWalkingTime(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLatitude(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLongitude(value);
      break;
    case 14:
      var value = new common_proto_pb.CardConfig;
      reader.readMessage(value,common_proto_pb.CardConfig.deserializeBinaryFromReader);
      msg.addIndexCard(value);
      break;
    case 15:
      var value = new common_proto_pb.MTFCPhaseGateConfig;
      reader.readMessage(value,common_proto_pb.MTFCPhaseGateConfig.deserializeBinaryFromReader);
      msg.addPhasePinConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceUpdateConfigureRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceUpdateConfigureRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceUpdateConfigureRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateConfigureRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTypeCpu();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getWalkingModeEnable();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getRailwayModeEnable();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getPowerMetersEnable();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLockedHardControlEnable();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getIsFlashing();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsEnableOut485LampPort();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getRailwayDelayOnTime();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getRailwayDelayOffTime();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getWalkingTime();
  if (f !== 0) {
    writer.writeUint32(
      11,
      f
    );
  }
  f = message.getLatitude();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getLongitude();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getIndexCardList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      14,
      f,
      common_proto_pb.CardConfig.serializeBinaryToWriter
    );
  }
  f = message.getPhasePinConfigList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      15,
      f,
      common_proto_pb.MTFCPhaseGateConfig.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.DeviceUpdateConfigureRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceType type_cpu = 2;
 * @return {!proto.DeviceType}
 */
proto.DeviceUpdateConfigureRequest.prototype.getTypeCpu = function() {
  return /** @type {!proto.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.DeviceType} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setTypeCpu = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional bool walking_mode_enable = 3;
 * @return {boolean}
 */
proto.DeviceUpdateConfigureRequest.prototype.getWalkingModeEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setWalkingModeEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool railway_mode_enable = 4;
 * @return {boolean}
 */
proto.DeviceUpdateConfigureRequest.prototype.getRailwayModeEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setRailwayModeEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional bool power_meters_enable = 5;
 * @return {boolean}
 */
proto.DeviceUpdateConfigureRequest.prototype.getPowerMetersEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setPowerMetersEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool locked_hard_control_enable = 6;
 * @return {boolean}
 */
proto.DeviceUpdateConfigureRequest.prototype.getLockedHardControlEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setLockedHardControlEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool is_flashing = 7;
 * @return {boolean}
 */
proto.DeviceUpdateConfigureRequest.prototype.getIsFlashing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setIsFlashing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_enable_out485_lamp_port = 8;
 * @return {boolean}
 */
proto.DeviceUpdateConfigureRequest.prototype.getIsEnableOut485LampPort = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setIsEnableOut485LampPort = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional uint32 railway_delay_on_time = 9;
 * @return {number}
 */
proto.DeviceUpdateConfigureRequest.prototype.getRailwayDelayOnTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setRailwayDelayOnTime = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 railway_delay_off_time = 10;
 * @return {number}
 */
proto.DeviceUpdateConfigureRequest.prototype.getRailwayDelayOffTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setRailwayDelayOffTime = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional uint32 walking_time = 11;
 * @return {number}
 */
proto.DeviceUpdateConfigureRequest.prototype.getWalkingTime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setWalkingTime = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional double latitude = 12;
 * @return {number}
 */
proto.DeviceUpdateConfigureRequest.prototype.getLatitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setLatitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double longitude = 13;
 * @return {number}
 */
proto.DeviceUpdateConfigureRequest.prototype.getLongitude = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.setLongitude = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * repeated CardConfig index_card = 14;
 * @return {!Array<!proto.CardConfig>}
 */
proto.DeviceUpdateConfigureRequest.prototype.getIndexCardList = function() {
  return /** @type{!Array<!proto.CardConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.CardConfig, 14));
};


/**
 * @param {!Array<!proto.CardConfig>} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
*/
proto.DeviceUpdateConfigureRequest.prototype.setIndexCardList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 14, value);
};


/**
 * @param {!proto.CardConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.CardConfig}
 */
proto.DeviceUpdateConfigureRequest.prototype.addIndexCard = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 14, opt_value, proto.CardConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.clearIndexCardList = function() {
  return this.setIndexCardList([]);
};


/**
 * repeated MTFCPhaseGateConfig phase_pin_config = 15;
 * @return {!Array<!proto.MTFCPhaseGateConfig>}
 */
proto.DeviceUpdateConfigureRequest.prototype.getPhasePinConfigList = function() {
  return /** @type{!Array<!proto.MTFCPhaseGateConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.MTFCPhaseGateConfig, 15));
};


/**
 * @param {!Array<!proto.MTFCPhaseGateConfig>} value
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
*/
proto.DeviceUpdateConfigureRequest.prototype.setPhasePinConfigList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 15, value);
};


/**
 * @param {!proto.MTFCPhaseGateConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.MTFCPhaseGateConfig}
 */
proto.DeviceUpdateConfigureRequest.prototype.addPhasePinConfig = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 15, opt_value, proto.MTFCPhaseGateConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.DeviceUpdateConfigureRequest} returns this
 */
proto.DeviceUpdateConfigureRequest.prototype.clearPhasePinConfigList = function() {
  return this.setPhasePinConfigList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceUpdateConfigureResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceUpdateConfigureResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceUpdateConfigureResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateConfigureResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceUpdateConfigureResponse}
 */
proto.DeviceUpdateConfigureResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceUpdateConfigureResponse;
  return proto.DeviceUpdateConfigureResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceUpdateConfigureResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceUpdateConfigureResponse}
 */
proto.DeviceUpdateConfigureResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceUpdateConfigureResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceUpdateConfigureResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceUpdateConfigureResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateConfigureResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeviceUpdateConfigureResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeviceUpdateConfigureResponse} returns this
*/
proto.DeviceUpdateConfigureResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateConfigureResponse} returns this
 */
proto.DeviceUpdateConfigureResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateConfigureResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceUpdateScheduleRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceUpdateScheduleRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceUpdateScheduleRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateScheduleRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numSide: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activeTime: (f = msg.getActiveTime()) && common_proto_pb.ActiveLamp.toObject(includeInstance, f),
    cycle: (f = msg.getCycle()) && common_proto_pb.CycleLampForm.toObject(includeInstance, f),
    time: (f = msg.getTime()) && common_proto_pb.TimeLampForm.toObject(includeInstance, f),
    days: (f = msg.getDays()) && common_proto_pb.DayLampForm.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceUpdateScheduleRequest}
 */
proto.DeviceUpdateScheduleRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceUpdateScheduleRequest;
  return proto.DeviceUpdateScheduleRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceUpdateScheduleRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceUpdateScheduleRequest}
 */
proto.DeviceUpdateScheduleRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumSide(value);
      break;
    case 3:
      var value = new common_proto_pb.ActiveLamp;
      reader.readMessage(value,common_proto_pb.ActiveLamp.deserializeBinaryFromReader);
      msg.setActiveTime(value);
      break;
    case 4:
      var value = new common_proto_pb.CycleLampForm;
      reader.readMessage(value,common_proto_pb.CycleLampForm.deserializeBinaryFromReader);
      msg.setCycle(value);
      break;
    case 5:
      var value = new common_proto_pb.TimeLampForm;
      reader.readMessage(value,common_proto_pb.TimeLampForm.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 6:
      var value = new common_proto_pb.DayLampForm;
      reader.readMessage(value,common_proto_pb.DayLampForm.deserializeBinaryFromReader);
      msg.setDays(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceUpdateScheduleRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceUpdateScheduleRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceUpdateScheduleRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateScheduleRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumSide();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getActiveTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_proto_pb.ActiveLamp.serializeBinaryToWriter
    );
  }
  f = message.getCycle();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_proto_pb.CycleLampForm.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_proto_pb.TimeLampForm.serializeBinaryToWriter
    );
  }
  f = message.getDays();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      common_proto_pb.DayLampForm.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.DeviceUpdateScheduleRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
 */
proto.DeviceUpdateScheduleRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 num_side = 2;
 * @return {number}
 */
proto.DeviceUpdateScheduleRequest.prototype.getNumSide = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
 */
proto.DeviceUpdateScheduleRequest.prototype.setNumSide = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional ActiveLamp active_time = 3;
 * @return {?proto.ActiveLamp}
 */
proto.DeviceUpdateScheduleRequest.prototype.getActiveTime = function() {
  return /** @type{?proto.ActiveLamp} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.ActiveLamp, 3));
};


/**
 * @param {?proto.ActiveLamp|undefined} value
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
*/
proto.DeviceUpdateScheduleRequest.prototype.setActiveTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
 */
proto.DeviceUpdateScheduleRequest.prototype.clearActiveTime = function() {
  return this.setActiveTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateScheduleRequest.prototype.hasActiveTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CycleLampForm cycle = 4;
 * @return {?proto.CycleLampForm}
 */
proto.DeviceUpdateScheduleRequest.prototype.getCycle = function() {
  return /** @type{?proto.CycleLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.CycleLampForm, 4));
};


/**
 * @param {?proto.CycleLampForm|undefined} value
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
*/
proto.DeviceUpdateScheduleRequest.prototype.setCycle = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
 */
proto.DeviceUpdateScheduleRequest.prototype.clearCycle = function() {
  return this.setCycle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateScheduleRequest.prototype.hasCycle = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional TimeLampForm time = 5;
 * @return {?proto.TimeLampForm}
 */
proto.DeviceUpdateScheduleRequest.prototype.getTime = function() {
  return /** @type{?proto.TimeLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.TimeLampForm, 5));
};


/**
 * @param {?proto.TimeLampForm|undefined} value
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
*/
proto.DeviceUpdateScheduleRequest.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
 */
proto.DeviceUpdateScheduleRequest.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateScheduleRequest.prototype.hasTime = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional DayLampForm days = 6;
 * @return {?proto.DayLampForm}
 */
proto.DeviceUpdateScheduleRequest.prototype.getDays = function() {
  return /** @type{?proto.DayLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.DayLampForm, 6));
};


/**
 * @param {?proto.DayLampForm|undefined} value
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
*/
proto.DeviceUpdateScheduleRequest.prototype.setDays = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateScheduleRequest} returns this
 */
proto.DeviceUpdateScheduleRequest.prototype.clearDays = function() {
  return this.setDays(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateScheduleRequest.prototype.hasDays = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceUpdateScheduleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceUpdateScheduleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceUpdateScheduleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateScheduleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceUpdateScheduleResponse}
 */
proto.DeviceUpdateScheduleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceUpdateScheduleResponse;
  return proto.DeviceUpdateScheduleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceUpdateScheduleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceUpdateScheduleResponse}
 */
proto.DeviceUpdateScheduleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceUpdateScheduleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceUpdateScheduleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceUpdateScheduleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateScheduleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.DeviceUpdateScheduleResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.DeviceUpdateScheduleResponse} returns this
*/
proto.DeviceUpdateScheduleResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateScheduleResponse} returns this
 */
proto.DeviceUpdateScheduleResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateScheduleResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceAuthMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceAuthMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceAuthMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceAuthMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceregisterrequest: (f = msg.getDeviceregisterrequest()) && proto.DeviceRegisterRequest.toObject(includeInstance, f),
    deviceregisterresponse: (f = msg.getDeviceregisterresponse()) && proto.DeviceRegisterResponse.toObject(includeInstance, f),
    deviceloginrequest: (f = msg.getDeviceloginrequest()) && proto.DeviceLoginRequest.toObject(includeInstance, f),
    deviceloginresponse: (f = msg.getDeviceloginresponse()) && proto.DeviceLoginResponse.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceAuthMessage}
 */
proto.DeviceAuthMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceAuthMessage;
  return proto.DeviceAuthMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceAuthMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceAuthMessage}
 */
proto.DeviceAuthMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DeviceRegisterRequest;
      reader.readMessage(value,proto.DeviceRegisterRequest.deserializeBinaryFromReader);
      msg.setDeviceregisterrequest(value);
      break;
    case 2:
      var value = new proto.DeviceRegisterResponse;
      reader.readMessage(value,proto.DeviceRegisterResponse.deserializeBinaryFromReader);
      msg.setDeviceregisterresponse(value);
      break;
    case 3:
      var value = new proto.DeviceLoginRequest;
      reader.readMessage(value,proto.DeviceLoginRequest.deserializeBinaryFromReader);
      msg.setDeviceloginrequest(value);
      break;
    case 4:
      var value = new proto.DeviceLoginResponse;
      reader.readMessage(value,proto.DeviceLoginResponse.deserializeBinaryFromReader);
      msg.setDeviceloginresponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceAuthMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceAuthMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceAuthMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceAuthMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceregisterrequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DeviceRegisterRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeviceregisterresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.DeviceRegisterResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeviceloginrequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.DeviceLoginRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeviceloginresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.DeviceLoginResponse.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceRegisterRequest DeviceRegisterRequest = 1;
 * @return {?proto.DeviceRegisterRequest}
 */
proto.DeviceAuthMessage.prototype.getDeviceregisterrequest = function() {
  return /** @type{?proto.DeviceRegisterRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeviceRegisterRequest, 1));
};


/**
 * @param {?proto.DeviceRegisterRequest|undefined} value
 * @return {!proto.DeviceAuthMessage} returns this
*/
proto.DeviceAuthMessage.prototype.setDeviceregisterrequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceAuthMessage} returns this
 */
proto.DeviceAuthMessage.prototype.clearDeviceregisterrequest = function() {
  return this.setDeviceregisterrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceAuthMessage.prototype.hasDeviceregisterrequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceRegisterResponse deviceRegisterResponse = 2;
 * @return {?proto.DeviceRegisterResponse}
 */
proto.DeviceAuthMessage.prototype.getDeviceregisterresponse = function() {
  return /** @type{?proto.DeviceRegisterResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeviceRegisterResponse, 2));
};


/**
 * @param {?proto.DeviceRegisterResponse|undefined} value
 * @return {!proto.DeviceAuthMessage} returns this
*/
proto.DeviceAuthMessage.prototype.setDeviceregisterresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceAuthMessage} returns this
 */
proto.DeviceAuthMessage.prototype.clearDeviceregisterresponse = function() {
  return this.setDeviceregisterresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceAuthMessage.prototype.hasDeviceregisterresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DeviceLoginRequest deviceLoginRequest = 3;
 * @return {?proto.DeviceLoginRequest}
 */
proto.DeviceAuthMessage.prototype.getDeviceloginrequest = function() {
  return /** @type{?proto.DeviceLoginRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeviceLoginRequest, 3));
};


/**
 * @param {?proto.DeviceLoginRequest|undefined} value
 * @return {!proto.DeviceAuthMessage} returns this
*/
proto.DeviceAuthMessage.prototype.setDeviceloginrequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceAuthMessage} returns this
 */
proto.DeviceAuthMessage.prototype.clearDeviceloginrequest = function() {
  return this.setDeviceloginrequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceAuthMessage.prototype.hasDeviceloginrequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DeviceLoginResponse deviceLoginResponse = 4;
 * @return {?proto.DeviceLoginResponse}
 */
proto.DeviceAuthMessage.prototype.getDeviceloginresponse = function() {
  return /** @type{?proto.DeviceLoginResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeviceLoginResponse, 4));
};


/**
 * @param {?proto.DeviceLoginResponse|undefined} value
 * @return {!proto.DeviceAuthMessage} returns this
*/
proto.DeviceAuthMessage.prototype.setDeviceloginresponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceAuthMessage} returns this
 */
proto.DeviceAuthMessage.prototype.clearDeviceloginresponse = function() {
  return this.setDeviceloginresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceAuthMessage.prototype.hasDeviceloginresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PingWebsocketDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.PingWebsocketDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PingWebsocketDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingWebsocketDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PingWebsocketDeviceRequest}
 */
proto.PingWebsocketDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PingWebsocketDeviceRequest;
  return proto.PingWebsocketDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PingWebsocketDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PingWebsocketDeviceRequest}
 */
proto.PingWebsocketDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PingWebsocketDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PingWebsocketDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PingWebsocketDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingWebsocketDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.PingWebsocketDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.PingWebsocketDeviceRequest} returns this
 */
proto.PingWebsocketDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.PingWebsocketDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.PingWebsocketDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.PingWebsocketDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingWebsocketDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.PingWebsocketDeviceResponse}
 */
proto.PingWebsocketDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.PingWebsocketDeviceResponse;
  return proto.PingWebsocketDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.PingWebsocketDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.PingWebsocketDeviceResponse}
 */
proto.PingWebsocketDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.PingWebsocketDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.PingWebsocketDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.PingWebsocketDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.PingWebsocketDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.PingWebsocketDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.PingWebsocketDeviceResponse} returns this
*/
proto.PingWebsocketDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.PingWebsocketDeviceResponse} returns this
 */
proto.PingWebsocketDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.PingWebsocketDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateCoordinateDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateCoordinateDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateCoordinateDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateCoordinateDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    log: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateCoordinateDeviceRequest}
 */
proto.UpdateCoordinateDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateCoordinateDeviceRequest;
  return proto.UpdateCoordinateDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateCoordinateDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateCoordinateDeviceRequest}
 */
proto.UpdateCoordinateDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLog(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateCoordinateDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateCoordinateDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateCoordinateDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateCoordinateDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getLog();
  if (f !== 0.0) {
    writer.writeFloat(
      3,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateCoordinateDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateCoordinateDeviceRequest} returns this
 */
proto.UpdateCoordinateDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional float lat = 2;
 * @return {number}
 */
proto.UpdateCoordinateDeviceRequest.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateCoordinateDeviceRequest} returns this
 */
proto.UpdateCoordinateDeviceRequest.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional float log = 3;
 * @return {number}
 */
proto.UpdateCoordinateDeviceRequest.prototype.getLog = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.UpdateCoordinateDeviceRequest} returns this
 */
proto.UpdateCoordinateDeviceRequest.prototype.setLog = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateCoordinateDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateCoordinateDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateCoordinateDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateCoordinateDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateCoordinateDeviceResponse}
 */
proto.UpdateCoordinateDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateCoordinateDeviceResponse;
  return proto.UpdateCoordinateDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateCoordinateDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateCoordinateDeviceResponse}
 */
proto.UpdateCoordinateDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateCoordinateDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateCoordinateDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateCoordinateDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateCoordinateDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.UpdateCoordinateDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.UpdateCoordinateDeviceResponse} returns this
*/
proto.UpdateCoordinateDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateCoordinateDeviceResponse} returns this
 */
proto.UpdateCoordinateDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateCoordinateDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.AnnounceErrorDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.AnnounceErrorDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.AnnounceErrorDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AnnounceErrorDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.AnnounceErrorDeviceRequest}
 */
proto.AnnounceErrorDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.AnnounceErrorDeviceRequest;
  return proto.AnnounceErrorDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.AnnounceErrorDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.AnnounceErrorDeviceRequest}
 */
proto.AnnounceErrorDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.AnnounceErrorDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.AnnounceErrorDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.AnnounceErrorDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.AnnounceErrorDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.AnnounceErrorDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.AnnounceErrorDeviceRequest} returns this
 */
proto.AnnounceErrorDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.AnnounceErrorDeviceRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.AnnounceErrorDeviceRequest} returns this
 */
proto.AnnounceErrorDeviceRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.AnnounceErrorDeviceRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.AnnounceErrorDeviceRequest} returns this
 */
proto.AnnounceErrorDeviceRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string phone = 4;
 * @return {string}
 */
proto.AnnounceErrorDeviceRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.AnnounceErrorDeviceRequest} returns this
 */
proto.AnnounceErrorDeviceRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceUpdateMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceUpdateMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceUpdateMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicelogupdate: (f = msg.getDevicelogupdate()) && proto.DeviceLogUpdate.toObject(includeInstance, f),
    stateonchange: (f = msg.getStateonchange()) && proto.StateOnChangeDeviceMessage.toObject(includeInstance, f),
    deviceupdateconfigurerequest: (f = msg.getDeviceupdateconfigurerequest()) && proto.DeviceUpdateConfigureRequest.toObject(includeInstance, f),
    deviceupdateconfigureresponse: (f = msg.getDeviceupdateconfigureresponse()) && proto.DeviceUpdateConfigureResponse.toObject(includeInstance, f),
    pingwebsocketdevicerequest: (f = msg.getPingwebsocketdevicerequest()) && proto.PingWebsocketDeviceRequest.toObject(includeInstance, f),
    pingwebsocketdeviceresponse: (f = msg.getPingwebsocketdeviceresponse()) && proto.PingWebsocketDeviceResponse.toObject(includeInstance, f),
    deviceupdateschedulerequest: (f = msg.getDeviceupdateschedulerequest()) && proto.DeviceUpdateScheduleRequest.toObject(includeInstance, f),
    deviceupdatescheduleresponse: (f = msg.getDeviceupdatescheduleresponse()) && proto.DeviceUpdateScheduleResponse.toObject(includeInstance, f),
    updatecoordinatedevicerequest: (f = msg.getUpdatecoordinatedevicerequest()) && proto.UpdateCoordinateDeviceRequest.toObject(includeInstance, f),
    updatecoordinatedeviceresponse: (f = msg.getUpdatecoordinatedeviceresponse()) && proto.UpdateCoordinateDeviceResponse.toObject(includeInstance, f),
    announceerrordevicerequest: (f = msg.getAnnounceerrordevicerequest()) && proto.AnnounceErrorDeviceRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceUpdateMessage}
 */
proto.DeviceUpdateMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceUpdateMessage;
  return proto.DeviceUpdateMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceUpdateMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceUpdateMessage}
 */
proto.DeviceUpdateMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DeviceLogUpdate;
      reader.readMessage(value,proto.DeviceLogUpdate.deserializeBinaryFromReader);
      msg.setDevicelogupdate(value);
      break;
    case 2:
      var value = new proto.StateOnChangeDeviceMessage;
      reader.readMessage(value,proto.StateOnChangeDeviceMessage.deserializeBinaryFromReader);
      msg.setStateonchange(value);
      break;
    case 3:
      var value = new proto.DeviceUpdateConfigureRequest;
      reader.readMessage(value,proto.DeviceUpdateConfigureRequest.deserializeBinaryFromReader);
      msg.setDeviceupdateconfigurerequest(value);
      break;
    case 4:
      var value = new proto.DeviceUpdateConfigureResponse;
      reader.readMessage(value,proto.DeviceUpdateConfigureResponse.deserializeBinaryFromReader);
      msg.setDeviceupdateconfigureresponse(value);
      break;
    case 5:
      var value = new proto.PingWebsocketDeviceRequest;
      reader.readMessage(value,proto.PingWebsocketDeviceRequest.deserializeBinaryFromReader);
      msg.setPingwebsocketdevicerequest(value);
      break;
    case 6:
      var value = new proto.PingWebsocketDeviceResponse;
      reader.readMessage(value,proto.PingWebsocketDeviceResponse.deserializeBinaryFromReader);
      msg.setPingwebsocketdeviceresponse(value);
      break;
    case 7:
      var value = new proto.DeviceUpdateScheduleRequest;
      reader.readMessage(value,proto.DeviceUpdateScheduleRequest.deserializeBinaryFromReader);
      msg.setDeviceupdateschedulerequest(value);
      break;
    case 8:
      var value = new proto.DeviceUpdateScheduleResponse;
      reader.readMessage(value,proto.DeviceUpdateScheduleResponse.deserializeBinaryFromReader);
      msg.setDeviceupdatescheduleresponse(value);
      break;
    case 9:
      var value = new proto.UpdateCoordinateDeviceRequest;
      reader.readMessage(value,proto.UpdateCoordinateDeviceRequest.deserializeBinaryFromReader);
      msg.setUpdatecoordinatedevicerequest(value);
      break;
    case 10:
      var value = new proto.UpdateCoordinateDeviceResponse;
      reader.readMessage(value,proto.UpdateCoordinateDeviceResponse.deserializeBinaryFromReader);
      msg.setUpdatecoordinatedeviceresponse(value);
      break;
    case 11:
      var value = new proto.AnnounceErrorDeviceRequest;
      reader.readMessage(value,proto.AnnounceErrorDeviceRequest.deserializeBinaryFromReader);
      msg.setAnnounceerrordevicerequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceUpdateMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceUpdateMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceUpdateMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceUpdateMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicelogupdate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DeviceLogUpdate.serializeBinaryToWriter
    );
  }
  f = message.getStateonchange();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.StateOnChangeDeviceMessage.serializeBinaryToWriter
    );
  }
  f = message.getDeviceupdateconfigurerequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.DeviceUpdateConfigureRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeviceupdateconfigureresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.DeviceUpdateConfigureResponse.serializeBinaryToWriter
    );
  }
  f = message.getPingwebsocketdevicerequest();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.PingWebsocketDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getPingwebsocketdeviceresponse();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.PingWebsocketDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getDeviceupdateschedulerequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.DeviceUpdateScheduleRequest.serializeBinaryToWriter
    );
  }
  f = message.getDeviceupdatescheduleresponse();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.DeviceUpdateScheduleResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdatecoordinatedevicerequest();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.UpdateCoordinateDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdatecoordinatedeviceresponse();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.UpdateCoordinateDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getAnnounceerrordevicerequest();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.AnnounceErrorDeviceRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional DeviceLogUpdate deviceLogUpdate = 1;
 * @return {?proto.DeviceLogUpdate}
 */
proto.DeviceUpdateMessage.prototype.getDevicelogupdate = function() {
  return /** @type{?proto.DeviceLogUpdate} */ (
    jspb.Message.getWrapperField(this, proto.DeviceLogUpdate, 1));
};


/**
 * @param {?proto.DeviceLogUpdate|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setDevicelogupdate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearDevicelogupdate = function() {
  return this.setDevicelogupdate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasDevicelogupdate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional StateOnChangeDeviceMessage stateOnChange = 2;
 * @return {?proto.StateOnChangeDeviceMessage}
 */
proto.DeviceUpdateMessage.prototype.getStateonchange = function() {
  return /** @type{?proto.StateOnChangeDeviceMessage} */ (
    jspb.Message.getWrapperField(this, proto.StateOnChangeDeviceMessage, 2));
};


/**
 * @param {?proto.StateOnChangeDeviceMessage|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setStateonchange = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearStateonchange = function() {
  return this.setStateonchange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasStateonchange = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional DeviceUpdateConfigureRequest deviceUpdateConfigureRequest = 3;
 * @return {?proto.DeviceUpdateConfigureRequest}
 */
proto.DeviceUpdateMessage.prototype.getDeviceupdateconfigurerequest = function() {
  return /** @type{?proto.DeviceUpdateConfigureRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeviceUpdateConfigureRequest, 3));
};


/**
 * @param {?proto.DeviceUpdateConfigureRequest|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setDeviceupdateconfigurerequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearDeviceupdateconfigurerequest = function() {
  return this.setDeviceupdateconfigurerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasDeviceupdateconfigurerequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DeviceUpdateConfigureResponse deviceUpdateConfigureResponse = 4;
 * @return {?proto.DeviceUpdateConfigureResponse}
 */
proto.DeviceUpdateMessage.prototype.getDeviceupdateconfigureresponse = function() {
  return /** @type{?proto.DeviceUpdateConfigureResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeviceUpdateConfigureResponse, 4));
};


/**
 * @param {?proto.DeviceUpdateConfigureResponse|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setDeviceupdateconfigureresponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearDeviceupdateconfigureresponse = function() {
  return this.setDeviceupdateconfigureresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasDeviceupdateconfigureresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional PingWebsocketDeviceRequest pingWebsocketDeviceRequest = 5;
 * @return {?proto.PingWebsocketDeviceRequest}
 */
proto.DeviceUpdateMessage.prototype.getPingwebsocketdevicerequest = function() {
  return /** @type{?proto.PingWebsocketDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.PingWebsocketDeviceRequest, 5));
};


/**
 * @param {?proto.PingWebsocketDeviceRequest|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setPingwebsocketdevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearPingwebsocketdevicerequest = function() {
  return this.setPingwebsocketdevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasPingwebsocketdevicerequest = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional PingWebsocketDeviceResponse pingWebsocketDeviceResponse = 6;
 * @return {?proto.PingWebsocketDeviceResponse}
 */
proto.DeviceUpdateMessage.prototype.getPingwebsocketdeviceresponse = function() {
  return /** @type{?proto.PingWebsocketDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.PingWebsocketDeviceResponse, 6));
};


/**
 * @param {?proto.PingWebsocketDeviceResponse|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setPingwebsocketdeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearPingwebsocketdeviceresponse = function() {
  return this.setPingwebsocketdeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasPingwebsocketdeviceresponse = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional DeviceUpdateScheduleRequest deviceUpdateScheduleRequest = 7;
 * @return {?proto.DeviceUpdateScheduleRequest}
 */
proto.DeviceUpdateMessage.prototype.getDeviceupdateschedulerequest = function() {
  return /** @type{?proto.DeviceUpdateScheduleRequest} */ (
    jspb.Message.getWrapperField(this, proto.DeviceUpdateScheduleRequest, 7));
};


/**
 * @param {?proto.DeviceUpdateScheduleRequest|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setDeviceupdateschedulerequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearDeviceupdateschedulerequest = function() {
  return this.setDeviceupdateschedulerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasDeviceupdateschedulerequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional DeviceUpdateScheduleResponse deviceUpdateScheduleResponse = 8;
 * @return {?proto.DeviceUpdateScheduleResponse}
 */
proto.DeviceUpdateMessage.prototype.getDeviceupdatescheduleresponse = function() {
  return /** @type{?proto.DeviceUpdateScheduleResponse} */ (
    jspb.Message.getWrapperField(this, proto.DeviceUpdateScheduleResponse, 8));
};


/**
 * @param {?proto.DeviceUpdateScheduleResponse|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setDeviceupdatescheduleresponse = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearDeviceupdatescheduleresponse = function() {
  return this.setDeviceupdatescheduleresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasDeviceupdatescheduleresponse = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional UpdateCoordinateDeviceRequest updateCoordinateDeviceRequest = 9;
 * @return {?proto.UpdateCoordinateDeviceRequest}
 */
proto.DeviceUpdateMessage.prototype.getUpdatecoordinatedevicerequest = function() {
  return /** @type{?proto.UpdateCoordinateDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.UpdateCoordinateDeviceRequest, 9));
};


/**
 * @param {?proto.UpdateCoordinateDeviceRequest|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setUpdatecoordinatedevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearUpdatecoordinatedevicerequest = function() {
  return this.setUpdatecoordinatedevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasUpdatecoordinatedevicerequest = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional UpdateCoordinateDeviceResponse updateCoordinateDeviceResponse = 10;
 * @return {?proto.UpdateCoordinateDeviceResponse}
 */
proto.DeviceUpdateMessage.prototype.getUpdatecoordinatedeviceresponse = function() {
  return /** @type{?proto.UpdateCoordinateDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.UpdateCoordinateDeviceResponse, 10));
};


/**
 * @param {?proto.UpdateCoordinateDeviceResponse|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setUpdatecoordinatedeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearUpdatecoordinatedeviceresponse = function() {
  return this.setUpdatecoordinatedeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasUpdatecoordinatedeviceresponse = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional AnnounceErrorDeviceRequest announceErrorDeviceRequest = 11;
 * @return {?proto.AnnounceErrorDeviceRequest}
 */
proto.DeviceUpdateMessage.prototype.getAnnounceerrordevicerequest = function() {
  return /** @type{?proto.AnnounceErrorDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.AnnounceErrorDeviceRequest, 11));
};


/**
 * @param {?proto.AnnounceErrorDeviceRequest|undefined} value
 * @return {!proto.DeviceUpdateMessage} returns this
*/
proto.DeviceUpdateMessage.prototype.setAnnounceerrordevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceUpdateMessage} returns this
 */
proto.DeviceUpdateMessage.prototype.clearAnnounceerrordevicerequest = function() {
  return this.setAnnounceerrordevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceUpdateMessage.prototype.hasAnnounceerrordevicerequest = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequireStreamDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.RequireStreamDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequireStreamDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    enable: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequireStreamDeviceRequest}
 */
proto.RequireStreamDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequireStreamDeviceRequest;
  return proto.RequireStreamDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequireStreamDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequireStreamDeviceRequest}
 */
proto.RequireStreamDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnable(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequireStreamDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequireStreamDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequireStreamDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnable();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.RequireStreamDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.RequireStreamDeviceRequest} returns this
 */
proto.RequireStreamDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool enable = 2;
 * @return {boolean}
 */
proto.RequireStreamDeviceRequest.prototype.getEnable = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.RequireStreamDeviceRequest} returns this
 */
proto.RequireStreamDeviceRequest.prototype.setEnable = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.RequireStreamDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.RequireStreamDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.RequireStreamDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.RequireStreamDeviceResponse}
 */
proto.RequireStreamDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.RequireStreamDeviceResponse;
  return proto.RequireStreamDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.RequireStreamDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.RequireStreamDeviceResponse}
 */
proto.RequireStreamDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.RequireStreamDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.RequireStreamDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.RequireStreamDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.RequireStreamDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.RequireStreamDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.RequireStreamDeviceResponse} returns this
*/
proto.RequireStreamDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.RequireStreamDeviceResponse} returns this
 */
proto.RequireStreamDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.RequireStreamDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ControlLaneDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ControlLaneDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ControlLaneDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlLaneDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    laneindex: jspb.Message.getFieldWithDefault(msg, 2, 0),
    ispoweron: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ControlLaneDeviceRequest}
 */
proto.ControlLaneDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ControlLaneDeviceRequest;
  return proto.ControlLaneDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ControlLaneDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ControlLaneDeviceRequest}
 */
proto.ControlLaneDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLaneindex(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIspoweron(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ControlLaneDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ControlLaneDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ControlLaneDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlLaneDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLaneindex();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getIspoweron();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.ControlLaneDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ControlLaneDeviceRequest} returns this
 */
proto.ControlLaneDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 laneIndex = 2;
 * @return {number}
 */
proto.ControlLaneDeviceRequest.prototype.getLaneindex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ControlLaneDeviceRequest} returns this
 */
proto.ControlLaneDeviceRequest.prototype.setLaneindex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool isPowerOn = 3;
 * @return {boolean}
 */
proto.ControlLaneDeviceRequest.prototype.getIspoweron = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.ControlLaneDeviceRequest} returns this
 */
proto.ControlLaneDeviceRequest.prototype.setIspoweron = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ControlLaneDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.ControlLaneDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ControlLaneDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlLaneDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ControlLaneDeviceResponse}
 */
proto.ControlLaneDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ControlLaneDeviceResponse;
  return proto.ControlLaneDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ControlLaneDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ControlLaneDeviceResponse}
 */
proto.ControlLaneDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ControlLaneDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ControlLaneDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ControlLaneDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlLaneDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.ControlLaneDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.ControlLaneDeviceResponse} returns this
*/
proto.ControlLaneDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ControlLaneDeviceResponse} returns this
 */
proto.ControlLaneDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ControlLaneDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetActiveTimeDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SetActiveTimeDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetActiveTimeDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    activelamp: (f = msg.getActivelamp()) && common_proto_pb.ActiveLamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetActiveTimeDeviceRequest}
 */
proto.SetActiveTimeDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetActiveTimeDeviceRequest;
  return proto.SetActiveTimeDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetActiveTimeDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetActiveTimeDeviceRequest}
 */
proto.SetActiveTimeDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.ActiveLamp;
      reader.readMessage(value,common_proto_pb.ActiveLamp.deserializeBinaryFromReader);
      msg.setActivelamp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetActiveTimeDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetActiveTimeDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetActiveTimeDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getActivelamp();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.ActiveLamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.SetActiveTimeDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SetActiveTimeDeviceRequest} returns this
 */
proto.SetActiveTimeDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ActiveLamp activeLamp = 2;
 * @return {?proto.ActiveLamp}
 */
proto.SetActiveTimeDeviceRequest.prototype.getActivelamp = function() {
  return /** @type{?proto.ActiveLamp} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.ActiveLamp, 2));
};


/**
 * @param {?proto.ActiveLamp|undefined} value
 * @return {!proto.SetActiveTimeDeviceRequest} returns this
*/
proto.SetActiveTimeDeviceRequest.prototype.setActivelamp = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SetActiveTimeDeviceRequest} returns this
 */
proto.SetActiveTimeDeviceRequest.prototype.clearActivelamp = function() {
  return this.setActivelamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SetActiveTimeDeviceRequest.prototype.hasActivelamp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetActiveTimeDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SetActiveTimeDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetActiveTimeDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetActiveTimeDeviceResponse}
 */
proto.SetActiveTimeDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetActiveTimeDeviceResponse;
  return proto.SetActiveTimeDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetActiveTimeDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetActiveTimeDeviceResponse}
 */
proto.SetActiveTimeDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetActiveTimeDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetActiveTimeDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetActiveTimeDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetActiveTimeDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.SetActiveTimeDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.SetActiveTimeDeviceResponse} returns this
*/
proto.SetActiveTimeDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SetActiveTimeDeviceResponse} returns this
 */
proto.SetActiveTimeDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SetActiveTimeDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.SetCycleConfigDeviceRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SetCycleConfigDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SetCycleConfigDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SetCycleConfigDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetCycleConfigDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    numphase: jspb.Message.getFieldWithDefault(msg, 2, 0),
    yellowtime: jspb.Message.getFieldWithDefault(msg, 3, 0),
    clearancetime: jspb.Message.getFieldWithDefault(msg, 4, 0),
    greentimeList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SetCycleConfigDeviceRequest}
 */
proto.SetCycleConfigDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SetCycleConfigDeviceRequest;
  return proto.SetCycleConfigDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SetCycleConfigDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SetCycleConfigDeviceRequest}
 */
proto.SetCycleConfigDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumphase(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYellowtime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setClearancetime(value);
      break;
    case 5:
      var value = /** @type {!Array<number>} */ (reader.readPackedUint32());
      msg.setGreentimeList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SetCycleConfigDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SetCycleConfigDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SetCycleConfigDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SetCycleConfigDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNumphase();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getYellowtime();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getClearancetime();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getGreentimeList();
  if (f.length > 0) {
    writer.writePackedUint32(
      5,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.SetCycleConfigDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SetCycleConfigDeviceRequest} returns this
 */
proto.SetCycleConfigDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 numPhase = 2;
 * @return {number}
 */
proto.SetCycleConfigDeviceRequest.prototype.getNumphase = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.SetCycleConfigDeviceRequest} returns this
 */
proto.SetCycleConfigDeviceRequest.prototype.setNumphase = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 yellowTime = 3;
 * @return {number}
 */
proto.SetCycleConfigDeviceRequest.prototype.getYellowtime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.SetCycleConfigDeviceRequest} returns this
 */
proto.SetCycleConfigDeviceRequest.prototype.setYellowtime = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 clearanceTime = 4;
 * @return {number}
 */
proto.SetCycleConfigDeviceRequest.prototype.getClearancetime = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.SetCycleConfigDeviceRequest} returns this
 */
proto.SetCycleConfigDeviceRequest.prototype.setClearancetime = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated uint32 greenTime = 5;
 * @return {!Array<number>}
 */
proto.SetCycleConfigDeviceRequest.prototype.getGreentimeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.SetCycleConfigDeviceRequest} returns this
 */
proto.SetCycleConfigDeviceRequest.prototype.setGreentimeList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SetCycleConfigDeviceRequest} returns this
 */
proto.SetCycleConfigDeviceRequest.prototype.addGreentime = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SetCycleConfigDeviceRequest} returns this
 */
proto.SetCycleConfigDeviceRequest.prototype.clearGreentimeList = function() {
  return this.setGreentimeList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SyncTimeDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.SyncTimeDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SyncTimeDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SyncTimeDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SyncTimeDeviceRequest}
 */
proto.SyncTimeDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SyncTimeDeviceRequest;
  return proto.SyncTimeDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SyncTimeDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SyncTimeDeviceRequest}
 */
proto.SyncTimeDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SyncTimeDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SyncTimeDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SyncTimeDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SyncTimeDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.SyncTimeDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.SyncTimeDeviceRequest} returns this
 */
proto.SyncTimeDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.SyncTimeDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.SyncTimeDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.SyncTimeDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SyncTimeDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SyncTimeDeviceResponse}
 */
proto.SyncTimeDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.SyncTimeDeviceResponse;
  return proto.SyncTimeDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SyncTimeDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SyncTimeDeviceResponse}
 */
proto.SyncTimeDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.SyncTimeDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.SyncTimeDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SyncTimeDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.SyncTimeDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.SyncTimeDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.SyncTimeDeviceResponse} returns this
*/
proto.SyncTimeDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.SyncTimeDeviceResponse} returns this
 */
proto.SyncTimeDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.SyncTimeDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetCoordinateDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.GetCoordinateDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetCoordinateDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetCoordinateDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetCoordinateDeviceRequest}
 */
proto.GetCoordinateDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetCoordinateDeviceRequest;
  return proto.GetCoordinateDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetCoordinateDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetCoordinateDeviceRequest}
 */
proto.GetCoordinateDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetCoordinateDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetCoordinateDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetCoordinateDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetCoordinateDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.GetCoordinateDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.GetCoordinateDeviceRequest} returns this
 */
proto.GetCoordinateDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.GetCoordinateDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.GetCoordinateDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.GetCoordinateDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetCoordinateDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    lat: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    log: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.GetCoordinateDeviceResponse}
 */
proto.GetCoordinateDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.GetCoordinateDeviceResponse;
  return proto.GetCoordinateDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.GetCoordinateDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.GetCoordinateDeviceResponse}
 */
proto.GetCoordinateDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLat(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setLog(value);
      break;
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.GetCoordinateDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.GetCoordinateDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.GetCoordinateDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.GetCoordinateDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLat();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
  f = message.getLog();
  if (f !== 0.0) {
    writer.writeFloat(
      2,
      f
    );
  }
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional float lat = 1;
 * @return {number}
 */
proto.GetCoordinateDeviceResponse.prototype.getLat = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GetCoordinateDeviceResponse} returns this
 */
proto.GetCoordinateDeviceResponse.prototype.setLat = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional float log = 2;
 * @return {number}
 */
proto.GetCoordinateDeviceResponse.prototype.getLog = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.GetCoordinateDeviceResponse} returns this
 */
proto.GetCoordinateDeviceResponse.prototype.setLog = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.GetCoordinateDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.GetCoordinateDeviceResponse} returns this
*/
proto.GetCoordinateDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.GetCoordinateDeviceResponse} returns this
 */
proto.GetCoordinateDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.GetCoordinateDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TriggerNotifyUserDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.TriggerNotifyUserDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TriggerNotifyUserDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TriggerNotifyUserDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    phone: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TriggerNotifyUserDeviceRequest}
 */
proto.TriggerNotifyUserDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TriggerNotifyUserDeviceRequest;
  return proto.TriggerNotifyUserDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TriggerNotifyUserDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TriggerNotifyUserDeviceRequest}
 */
proto.TriggerNotifyUserDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPhone(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TriggerNotifyUserDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TriggerNotifyUserDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TriggerNotifyUserDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TriggerNotifyUserDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPhone();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.TriggerNotifyUserDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.TriggerNotifyUserDeviceRequest} returns this
 */
proto.TriggerNotifyUserDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string phone = 2;
 * @return {string}
 */
proto.TriggerNotifyUserDeviceRequest.prototype.getPhone = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.TriggerNotifyUserDeviceRequest} returns this
 */
proto.TriggerNotifyUserDeviceRequest.prototype.setPhone = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.TriggerNotifyUserDeviceRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.TriggerNotifyUserDeviceRequest} returns this
 */
proto.TriggerNotifyUserDeviceRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.TriggerNotifyUserDeviceRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.TriggerNotifyUserDeviceRequest} returns this
 */
proto.TriggerNotifyUserDeviceRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.TriggerNotifyUserDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.TriggerNotifyUserDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.TriggerNotifyUserDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TriggerNotifyUserDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.TriggerNotifyUserDeviceResponse}
 */
proto.TriggerNotifyUserDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.TriggerNotifyUserDeviceResponse;
  return proto.TriggerNotifyUserDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.TriggerNotifyUserDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.TriggerNotifyUserDeviceResponse}
 */
proto.TriggerNotifyUserDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.TriggerNotifyUserDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.TriggerNotifyUserDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.TriggerNotifyUserDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.TriggerNotifyUserDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.TriggerNotifyUserDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.TriggerNotifyUserDeviceResponse} returns this
*/
proto.TriggerNotifyUserDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.TriggerNotifyUserDeviceResponse} returns this
 */
proto.TriggerNotifyUserDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.TriggerNotifyUserDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateScheduleUserDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateScheduleUserDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleUserDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    timelampform: (f = msg.getTimelampform()) && common_proto_pb.TimeLampForm.toObject(includeInstance, f),
    cycle: (f = msg.getCycle()) && common_proto_pb.CycleLampForm.toObject(includeInstance, f),
    days: (f = msg.getDays()) && common_proto_pb.DayLampForm.toObject(includeInstance, f),
    activeTime: (f = msg.getActiveTime()) && common_proto_pb.ActiveLamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateScheduleUserDeviceRequest}
 */
proto.UpdateScheduleUserDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateScheduleUserDeviceRequest;
  return proto.UpdateScheduleUserDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateScheduleUserDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateScheduleUserDeviceRequest}
 */
proto.UpdateScheduleUserDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = new common_proto_pb.TimeLampForm;
      reader.readMessage(value,common_proto_pb.TimeLampForm.deserializeBinaryFromReader);
      msg.setTimelampform(value);
      break;
    case 3:
      var value = new common_proto_pb.CycleLampForm;
      reader.readMessage(value,common_proto_pb.CycleLampForm.deserializeBinaryFromReader);
      msg.setCycle(value);
      break;
    case 4:
      var value = new common_proto_pb.DayLampForm;
      reader.readMessage(value,common_proto_pb.DayLampForm.deserializeBinaryFromReader);
      msg.setDays(value);
      break;
    case 5:
      var value = new common_proto_pb.ActiveLamp;
      reader.readMessage(value,common_proto_pb.ActiveLamp.deserializeBinaryFromReader);
      msg.setActiveTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateScheduleUserDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateScheduleUserDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleUserDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTimelampform();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      common_proto_pb.TimeLampForm.serializeBinaryToWriter
    );
  }
  f = message.getCycle();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      common_proto_pb.CycleLampForm.serializeBinaryToWriter
    );
  }
  f = message.getDays();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_proto_pb.DayLampForm.serializeBinaryToWriter
    );
  }
  f = message.getActiveTime();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      common_proto_pb.ActiveLamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.UpdateScheduleUserDeviceRequest} returns this
 */
proto.UpdateScheduleUserDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional TimeLampForm timeLampForm = 2;
 * @return {?proto.TimeLampForm}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.getTimelampform = function() {
  return /** @type{?proto.TimeLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.TimeLampForm, 2));
};


/**
 * @param {?proto.TimeLampForm|undefined} value
 * @return {!proto.UpdateScheduleUserDeviceRequest} returns this
*/
proto.UpdateScheduleUserDeviceRequest.prototype.setTimelampform = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateScheduleUserDeviceRequest} returns this
 */
proto.UpdateScheduleUserDeviceRequest.prototype.clearTimelampform = function() {
  return this.setTimelampform(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.hasTimelampform = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CycleLampForm cycle = 3;
 * @return {?proto.CycleLampForm}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.getCycle = function() {
  return /** @type{?proto.CycleLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.CycleLampForm, 3));
};


/**
 * @param {?proto.CycleLampForm|undefined} value
 * @return {!proto.UpdateScheduleUserDeviceRequest} returns this
*/
proto.UpdateScheduleUserDeviceRequest.prototype.setCycle = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateScheduleUserDeviceRequest} returns this
 */
proto.UpdateScheduleUserDeviceRequest.prototype.clearCycle = function() {
  return this.setCycle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.hasCycle = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional DayLampForm days = 4;
 * @return {?proto.DayLampForm}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.getDays = function() {
  return /** @type{?proto.DayLampForm} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.DayLampForm, 4));
};


/**
 * @param {?proto.DayLampForm|undefined} value
 * @return {!proto.UpdateScheduleUserDeviceRequest} returns this
*/
proto.UpdateScheduleUserDeviceRequest.prototype.setDays = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateScheduleUserDeviceRequest} returns this
 */
proto.UpdateScheduleUserDeviceRequest.prototype.clearDays = function() {
  return this.setDays(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.hasDays = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ActiveLamp active_time = 5;
 * @return {?proto.ActiveLamp}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.getActiveTime = function() {
  return /** @type{?proto.ActiveLamp} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.ActiveLamp, 5));
};


/**
 * @param {?proto.ActiveLamp|undefined} value
 * @return {!proto.UpdateScheduleUserDeviceRequest} returns this
*/
proto.UpdateScheduleUserDeviceRequest.prototype.setActiveTime = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateScheduleUserDeviceRequest} returns this
 */
proto.UpdateScheduleUserDeviceRequest.prototype.clearActiveTime = function() {
  return this.setActiveTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateScheduleUserDeviceRequest.prototype.hasActiveTime = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UpdateScheduleUserDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.UpdateScheduleUserDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UpdateScheduleUserDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleUserDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statuscode: (f = msg.getStatuscode()) && common_proto_pb.StatusCode.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UpdateScheduleUserDeviceResponse}
 */
proto.UpdateScheduleUserDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UpdateScheduleUserDeviceResponse;
  return proto.UpdateScheduleUserDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UpdateScheduleUserDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UpdateScheduleUserDeviceResponse}
 */
proto.UpdateScheduleUserDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new common_proto_pb.StatusCode;
      reader.readMessage(value,common_proto_pb.StatusCode.deserializeBinaryFromReader);
      msg.setStatuscode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UpdateScheduleUserDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UpdateScheduleUserDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UpdateScheduleUserDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UpdateScheduleUserDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatuscode();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      common_proto_pb.StatusCode.serializeBinaryToWriter
    );
  }
};


/**
 * optional StatusCode statusCode = 1000;
 * @return {?proto.StatusCode}
 */
proto.UpdateScheduleUserDeviceResponse.prototype.getStatuscode = function() {
  return /** @type{?proto.StatusCode} */ (
    jspb.Message.getWrapperField(this, common_proto_pb.StatusCode, 1000));
};


/**
 * @param {?proto.StatusCode|undefined} value
 * @return {!proto.UpdateScheduleUserDeviceResponse} returns this
*/
proto.UpdateScheduleUserDeviceResponse.prototype.setStatuscode = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UpdateScheduleUserDeviceResponse} returns this
 */
proto.UpdateScheduleUserDeviceResponse.prototype.clearStatuscode = function() {
  return this.setStatuscode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UpdateScheduleUserDeviceResponse.prototype.hasStatuscode = function() {
  return jspb.Message.getField(this, 1000) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ControlCycleFormUserDeviceRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ControlCycleFormUserDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.ControlCycleFormUserDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ControlCycleFormUserDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlCycleFormUserDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    total: jspb.Message.getFieldWithDefault(msg, 2, 0),
    cycleformList: jspb.Message.toObjectList(msg.getCycleformList(),
    common_proto_pb.AdvanceCycleForm.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ControlCycleFormUserDeviceRequest}
 */
proto.ControlCycleFormUserDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ControlCycleFormUserDeviceRequest;
  return proto.ControlCycleFormUserDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ControlCycleFormUserDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ControlCycleFormUserDeviceRequest}
 */
proto.ControlCycleFormUserDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotal(value);
      break;
    case 3:
      var value = new common_proto_pb.AdvanceCycleForm;
      reader.readMessage(value,common_proto_pb.AdvanceCycleForm.deserializeBinaryFromReader);
      msg.addCycleform(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ControlCycleFormUserDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ControlCycleFormUserDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ControlCycleFormUserDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ControlCycleFormUserDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getCycleformList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      common_proto_pb.AdvanceCycleForm.serializeBinaryToWriter
    );
  }
};


/**
 * optional string deviceId = 1;
 * @return {string}
 */
proto.ControlCycleFormUserDeviceRequest.prototype.getDeviceid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ControlCycleFormUserDeviceRequest} returns this
 */
proto.ControlCycleFormUserDeviceRequest.prototype.setDeviceid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 total = 2;
 * @return {number}
 */
proto.ControlCycleFormUserDeviceRequest.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ControlCycleFormUserDeviceRequest} returns this
 */
proto.ControlCycleFormUserDeviceRequest.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated AdvanceCycleForm cycleForm = 3;
 * @return {!Array<!proto.AdvanceCycleForm>}
 */
proto.ControlCycleFormUserDeviceRequest.prototype.getCycleformList = function() {
  return /** @type{!Array<!proto.AdvanceCycleForm>} */ (
    jspb.Message.getRepeatedWrapperField(this, common_proto_pb.AdvanceCycleForm, 3));
};


/**
 * @param {!Array<!proto.AdvanceCycleForm>} value
 * @return {!proto.ControlCycleFormUserDeviceRequest} returns this
*/
proto.ControlCycleFormUserDeviceRequest.prototype.setCycleformList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.AdvanceCycleForm=} opt_value
 * @param {number=} opt_index
 * @return {!proto.AdvanceCycleForm}
 */
proto.ControlCycleFormUserDeviceRequest.prototype.addCycleform = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.AdvanceCycleForm, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ControlCycleFormUserDeviceRequest} returns this
 */
proto.ControlCycleFormUserDeviceRequest.prototype.clearCycleformList = function() {
  return this.setCycleformList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.UserDeviceMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.UserDeviceMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.UserDeviceMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserDeviceMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    requirestreamdevicerequest: (f = msg.getRequirestreamdevicerequest()) && proto.RequireStreamDeviceRequest.toObject(includeInstance, f),
    requirestreamdeviceresponse: (f = msg.getRequirestreamdeviceresponse()) && proto.RequireStreamDeviceResponse.toObject(includeInstance, f),
    controllanedevicerequest: (f = msg.getControllanedevicerequest()) && proto.ControlLaneDeviceRequest.toObject(includeInstance, f),
    controllanedeviceresponse: (f = msg.getControllanedeviceresponse()) && proto.ControlLaneDeviceResponse.toObject(includeInstance, f),
    setactivetimedevicerequest: (f = msg.getSetactivetimedevicerequest()) && proto.SetActiveTimeDeviceRequest.toObject(includeInstance, f),
    setactivetimedeviceresponse: (f = msg.getSetactivetimedeviceresponse()) && proto.SetActiveTimeDeviceResponse.toObject(includeInstance, f),
    setcycleconfigdevicerequest: (f = msg.getSetcycleconfigdevicerequest()) && proto.SetCycleConfigDeviceRequest.toObject(includeInstance, f),
    synctimedevicerequest: (f = msg.getSynctimedevicerequest()) && proto.SyncTimeDeviceRequest.toObject(includeInstance, f),
    synctimedeviceresponse: (f = msg.getSynctimedeviceresponse()) && proto.SyncTimeDeviceResponse.toObject(includeInstance, f),
    getcoordinatedevicerequest: (f = msg.getGetcoordinatedevicerequest()) && proto.GetCoordinateDeviceRequest.toObject(includeInstance, f),
    getcoordinatedeviceresponse: (f = msg.getGetcoordinatedeviceresponse()) && proto.GetCoordinateDeviceResponse.toObject(includeInstance, f),
    triggernotifyuserdevicerequest: (f = msg.getTriggernotifyuserdevicerequest()) && proto.TriggerNotifyUserDeviceRequest.toObject(includeInstance, f),
    triggernotifyuserdeviceresponse: (f = msg.getTriggernotifyuserdeviceresponse()) && proto.TriggerNotifyUserDeviceResponse.toObject(includeInstance, f),
    updatescheduleuserdevicerequest: (f = msg.getUpdatescheduleuserdevicerequest()) && proto.UpdateScheduleUserDeviceRequest.toObject(includeInstance, f),
    updatescheduleuserdeviceresponse: (f = msg.getUpdatescheduleuserdeviceresponse()) && proto.UpdateScheduleUserDeviceResponse.toObject(includeInstance, f),
    controlcycleformuserdevicerequest: (f = msg.getControlcycleformuserdevicerequest()) && proto.ControlCycleFormUserDeviceRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.UserDeviceMessage}
 */
proto.UserDeviceMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.UserDeviceMessage;
  return proto.UserDeviceMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.UserDeviceMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.UserDeviceMessage}
 */
proto.UserDeviceMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.RequireStreamDeviceRequest;
      reader.readMessage(value,proto.RequireStreamDeviceRequest.deserializeBinaryFromReader);
      msg.setRequirestreamdevicerequest(value);
      break;
    case 2:
      var value = new proto.RequireStreamDeviceResponse;
      reader.readMessage(value,proto.RequireStreamDeviceResponse.deserializeBinaryFromReader);
      msg.setRequirestreamdeviceresponse(value);
      break;
    case 3:
      var value = new proto.ControlLaneDeviceRequest;
      reader.readMessage(value,proto.ControlLaneDeviceRequest.deserializeBinaryFromReader);
      msg.setControllanedevicerequest(value);
      break;
    case 4:
      var value = new proto.ControlLaneDeviceResponse;
      reader.readMessage(value,proto.ControlLaneDeviceResponse.deserializeBinaryFromReader);
      msg.setControllanedeviceresponse(value);
      break;
    case 7:
      var value = new proto.SetActiveTimeDeviceRequest;
      reader.readMessage(value,proto.SetActiveTimeDeviceRequest.deserializeBinaryFromReader);
      msg.setSetactivetimedevicerequest(value);
      break;
    case 8:
      var value = new proto.SetActiveTimeDeviceResponse;
      reader.readMessage(value,proto.SetActiveTimeDeviceResponse.deserializeBinaryFromReader);
      msg.setSetactivetimedeviceresponse(value);
      break;
    case 9:
      var value = new proto.SetCycleConfigDeviceRequest;
      reader.readMessage(value,proto.SetCycleConfigDeviceRequest.deserializeBinaryFromReader);
      msg.setSetcycleconfigdevicerequest(value);
      break;
    case 10:
      var value = new proto.SyncTimeDeviceRequest;
      reader.readMessage(value,proto.SyncTimeDeviceRequest.deserializeBinaryFromReader);
      msg.setSynctimedevicerequest(value);
      break;
    case 11:
      var value = new proto.SyncTimeDeviceResponse;
      reader.readMessage(value,proto.SyncTimeDeviceResponse.deserializeBinaryFromReader);
      msg.setSynctimedeviceresponse(value);
      break;
    case 12:
      var value = new proto.GetCoordinateDeviceRequest;
      reader.readMessage(value,proto.GetCoordinateDeviceRequest.deserializeBinaryFromReader);
      msg.setGetcoordinatedevicerequest(value);
      break;
    case 13:
      var value = new proto.GetCoordinateDeviceResponse;
      reader.readMessage(value,proto.GetCoordinateDeviceResponse.deserializeBinaryFromReader);
      msg.setGetcoordinatedeviceresponse(value);
      break;
    case 14:
      var value = new proto.TriggerNotifyUserDeviceRequest;
      reader.readMessage(value,proto.TriggerNotifyUserDeviceRequest.deserializeBinaryFromReader);
      msg.setTriggernotifyuserdevicerequest(value);
      break;
    case 15:
      var value = new proto.TriggerNotifyUserDeviceResponse;
      reader.readMessage(value,proto.TriggerNotifyUserDeviceResponse.deserializeBinaryFromReader);
      msg.setTriggernotifyuserdeviceresponse(value);
      break;
    case 16:
      var value = new proto.UpdateScheduleUserDeviceRequest;
      reader.readMessage(value,proto.UpdateScheduleUserDeviceRequest.deserializeBinaryFromReader);
      msg.setUpdatescheduleuserdevicerequest(value);
      break;
    case 17:
      var value = new proto.UpdateScheduleUserDeviceResponse;
      reader.readMessage(value,proto.UpdateScheduleUserDeviceResponse.deserializeBinaryFromReader);
      msg.setUpdatescheduleuserdeviceresponse(value);
      break;
    case 18:
      var value = new proto.ControlCycleFormUserDeviceRequest;
      reader.readMessage(value,proto.ControlCycleFormUserDeviceRequest.deserializeBinaryFromReader);
      msg.setControlcycleformuserdevicerequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.UserDeviceMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.UserDeviceMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.UserDeviceMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.UserDeviceMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequirestreamdevicerequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.RequireStreamDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getRequirestreamdeviceresponse();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.RequireStreamDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getControllanedevicerequest();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ControlLaneDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getControllanedeviceresponse();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ControlLaneDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getSetactivetimedevicerequest();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.SetActiveTimeDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getSetactivetimedeviceresponse();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.SetActiveTimeDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getSetcycleconfigdevicerequest();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.SetCycleConfigDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getSynctimedevicerequest();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.SyncTimeDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getSynctimedeviceresponse();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.SyncTimeDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getGetcoordinatedevicerequest();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.GetCoordinateDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getGetcoordinatedeviceresponse();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.GetCoordinateDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getTriggernotifyuserdevicerequest();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.TriggerNotifyUserDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getTriggernotifyuserdeviceresponse();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.TriggerNotifyUserDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getUpdatescheduleuserdevicerequest();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      proto.UpdateScheduleUserDeviceRequest.serializeBinaryToWriter
    );
  }
  f = message.getUpdatescheduleuserdeviceresponse();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.UpdateScheduleUserDeviceResponse.serializeBinaryToWriter
    );
  }
  f = message.getControlcycleformuserdevicerequest();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      proto.ControlCycleFormUserDeviceRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional RequireStreamDeviceRequest requireStreamDeviceRequest = 1;
 * @return {?proto.RequireStreamDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getRequirestreamdevicerequest = function() {
  return /** @type{?proto.RequireStreamDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.RequireStreamDeviceRequest, 1));
};


/**
 * @param {?proto.RequireStreamDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setRequirestreamdevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearRequirestreamdevicerequest = function() {
  return this.setRequirestreamdevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasRequirestreamdevicerequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional RequireStreamDeviceResponse requireStreamDeviceResponse = 2;
 * @return {?proto.RequireStreamDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getRequirestreamdeviceresponse = function() {
  return /** @type{?proto.RequireStreamDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.RequireStreamDeviceResponse, 2));
};


/**
 * @param {?proto.RequireStreamDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setRequirestreamdeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearRequirestreamdeviceresponse = function() {
  return this.setRequirestreamdeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasRequirestreamdeviceresponse = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ControlLaneDeviceRequest controlLaneDeviceRequest = 3;
 * @return {?proto.ControlLaneDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getControllanedevicerequest = function() {
  return /** @type{?proto.ControlLaneDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.ControlLaneDeviceRequest, 3));
};


/**
 * @param {?proto.ControlLaneDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setControllanedevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearControllanedevicerequest = function() {
  return this.setControllanedevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasControllanedevicerequest = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ControlLaneDeviceResponse controlLaneDeviceResponse = 4;
 * @return {?proto.ControlLaneDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getControllanedeviceresponse = function() {
  return /** @type{?proto.ControlLaneDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.ControlLaneDeviceResponse, 4));
};


/**
 * @param {?proto.ControlLaneDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setControllanedeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearControllanedeviceresponse = function() {
  return this.setControllanedeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasControllanedeviceresponse = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional SetActiveTimeDeviceRequest setActiveTimeDeviceRequest = 7;
 * @return {?proto.SetActiveTimeDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getSetactivetimedevicerequest = function() {
  return /** @type{?proto.SetActiveTimeDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.SetActiveTimeDeviceRequest, 7));
};


/**
 * @param {?proto.SetActiveTimeDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setSetactivetimedevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearSetactivetimedevicerequest = function() {
  return this.setSetactivetimedevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasSetactivetimedevicerequest = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional SetActiveTimeDeviceResponse setActiveTimeDeviceResponse = 8;
 * @return {?proto.SetActiveTimeDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getSetactivetimedeviceresponse = function() {
  return /** @type{?proto.SetActiveTimeDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.SetActiveTimeDeviceResponse, 8));
};


/**
 * @param {?proto.SetActiveTimeDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setSetactivetimedeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearSetactivetimedeviceresponse = function() {
  return this.setSetactivetimedeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasSetactivetimedeviceresponse = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional SetCycleConfigDeviceRequest setCycleConfigDeviceRequest = 9;
 * @return {?proto.SetCycleConfigDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getSetcycleconfigdevicerequest = function() {
  return /** @type{?proto.SetCycleConfigDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.SetCycleConfigDeviceRequest, 9));
};


/**
 * @param {?proto.SetCycleConfigDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setSetcycleconfigdevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearSetcycleconfigdevicerequest = function() {
  return this.setSetcycleconfigdevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasSetcycleconfigdevicerequest = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional SyncTimeDeviceRequest syncTimeDeviceRequest = 10;
 * @return {?proto.SyncTimeDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getSynctimedevicerequest = function() {
  return /** @type{?proto.SyncTimeDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.SyncTimeDeviceRequest, 10));
};


/**
 * @param {?proto.SyncTimeDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setSynctimedevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearSynctimedevicerequest = function() {
  return this.setSynctimedevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasSynctimedevicerequest = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional SyncTimeDeviceResponse syncTimeDeviceResponse = 11;
 * @return {?proto.SyncTimeDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getSynctimedeviceresponse = function() {
  return /** @type{?proto.SyncTimeDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.SyncTimeDeviceResponse, 11));
};


/**
 * @param {?proto.SyncTimeDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setSynctimedeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearSynctimedeviceresponse = function() {
  return this.setSynctimedeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasSynctimedeviceresponse = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional GetCoordinateDeviceRequest getCoordinateDeviceRequest = 12;
 * @return {?proto.GetCoordinateDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getGetcoordinatedevicerequest = function() {
  return /** @type{?proto.GetCoordinateDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.GetCoordinateDeviceRequest, 12));
};


/**
 * @param {?proto.GetCoordinateDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setGetcoordinatedevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearGetcoordinatedevicerequest = function() {
  return this.setGetcoordinatedevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasGetcoordinatedevicerequest = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional GetCoordinateDeviceResponse getCoordinateDeviceResponse = 13;
 * @return {?proto.GetCoordinateDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getGetcoordinatedeviceresponse = function() {
  return /** @type{?proto.GetCoordinateDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.GetCoordinateDeviceResponse, 13));
};


/**
 * @param {?proto.GetCoordinateDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setGetcoordinatedeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearGetcoordinatedeviceresponse = function() {
  return this.setGetcoordinatedeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasGetcoordinatedeviceresponse = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional TriggerNotifyUserDeviceRequest triggerNotifyUserDeviceRequest = 14;
 * @return {?proto.TriggerNotifyUserDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getTriggernotifyuserdevicerequest = function() {
  return /** @type{?proto.TriggerNotifyUserDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.TriggerNotifyUserDeviceRequest, 14));
};


/**
 * @param {?proto.TriggerNotifyUserDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setTriggernotifyuserdevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearTriggernotifyuserdevicerequest = function() {
  return this.setTriggernotifyuserdevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasTriggernotifyuserdevicerequest = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional TriggerNotifyUserDeviceResponse triggerNotifyUserDeviceResponse = 15;
 * @return {?proto.TriggerNotifyUserDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getTriggernotifyuserdeviceresponse = function() {
  return /** @type{?proto.TriggerNotifyUserDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.TriggerNotifyUserDeviceResponse, 15));
};


/**
 * @param {?proto.TriggerNotifyUserDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setTriggernotifyuserdeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearTriggernotifyuserdeviceresponse = function() {
  return this.setTriggernotifyuserdeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasTriggernotifyuserdeviceresponse = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional UpdateScheduleUserDeviceRequest updateScheduleUserDeviceRequest = 16;
 * @return {?proto.UpdateScheduleUserDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getUpdatescheduleuserdevicerequest = function() {
  return /** @type{?proto.UpdateScheduleUserDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.UpdateScheduleUserDeviceRequest, 16));
};


/**
 * @param {?proto.UpdateScheduleUserDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setUpdatescheduleuserdevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearUpdatescheduleuserdevicerequest = function() {
  return this.setUpdatescheduleuserdevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasUpdatescheduleuserdevicerequest = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional UpdateScheduleUserDeviceResponse updateScheduleUserDeviceResponse = 17;
 * @return {?proto.UpdateScheduleUserDeviceResponse}
 */
proto.UserDeviceMessage.prototype.getUpdatescheduleuserdeviceresponse = function() {
  return /** @type{?proto.UpdateScheduleUserDeviceResponse} */ (
    jspb.Message.getWrapperField(this, proto.UpdateScheduleUserDeviceResponse, 17));
};


/**
 * @param {?proto.UpdateScheduleUserDeviceResponse|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setUpdatescheduleuserdeviceresponse = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearUpdatescheduleuserdeviceresponse = function() {
  return this.setUpdatescheduleuserdeviceresponse(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasUpdatescheduleuserdeviceresponse = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional ControlCycleFormUserDeviceRequest controlCycleFormUserDeviceRequest = 18;
 * @return {?proto.ControlCycleFormUserDeviceRequest}
 */
proto.UserDeviceMessage.prototype.getControlcycleformuserdevicerequest = function() {
  return /** @type{?proto.ControlCycleFormUserDeviceRequest} */ (
    jspb.Message.getWrapperField(this, proto.ControlCycleFormUserDeviceRequest, 18));
};


/**
 * @param {?proto.ControlCycleFormUserDeviceRequest|undefined} value
 * @return {!proto.UserDeviceMessage} returns this
*/
proto.UserDeviceMessage.prototype.setControlcycleformuserdevicerequest = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.UserDeviceMessage} returns this
 */
proto.UserDeviceMessage.prototype.clearControlcycleformuserdevicerequest = function() {
  return this.setControlcycleformuserdevicerequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.UserDeviceMessage.prototype.hasControlcycleformuserdevicerequest = function() {
  return jspb.Message.getField(this, 18) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.DeviceMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.DeviceMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.DeviceMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceauthmessage: (f = msg.getDeviceauthmessage()) && proto.DeviceAuthMessage.toObject(includeInstance, f),
    deviceupdatemessage: (f = msg.getDeviceupdatemessage()) && proto.DeviceUpdateMessage.toObject(includeInstance, f),
    userdevicemessage: (f = msg.getUserdevicemessage()) && proto.UserDeviceMessage.toObject(includeInstance, f),
    hardwareid: jspb.Message.getFieldWithDefault(msg, 100, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.DeviceMessage}
 */
proto.DeviceMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.DeviceMessage;
  return proto.DeviceMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.DeviceMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.DeviceMessage}
 */
proto.DeviceMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.DeviceAuthMessage;
      reader.readMessage(value,proto.DeviceAuthMessage.deserializeBinaryFromReader);
      msg.setDeviceauthmessage(value);
      break;
    case 2:
      var value = new proto.DeviceUpdateMessage;
      reader.readMessage(value,proto.DeviceUpdateMessage.deserializeBinaryFromReader);
      msg.setDeviceupdatemessage(value);
      break;
    case 3:
      var value = new proto.UserDeviceMessage;
      reader.readMessage(value,proto.UserDeviceMessage.deserializeBinaryFromReader);
      msg.setUserdevicemessage(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setHardwareid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.DeviceMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.DeviceMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.DeviceMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.DeviceMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceauthmessage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.DeviceAuthMessage.serializeBinaryToWriter
    );
  }
  f = message.getDeviceupdatemessage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.DeviceUpdateMessage.serializeBinaryToWriter
    );
  }
  f = message.getUserdevicemessage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.UserDeviceMessage.serializeBinaryToWriter
    );
  }
  f = message.getHardwareid();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
};


/**
 * optional DeviceAuthMessage deviceAuthMessage = 1;
 * @return {?proto.DeviceAuthMessage}
 */
proto.DeviceMessage.prototype.getDeviceauthmessage = function() {
  return /** @type{?proto.DeviceAuthMessage} */ (
    jspb.Message.getWrapperField(this, proto.DeviceAuthMessage, 1));
};


/**
 * @param {?proto.DeviceAuthMessage|undefined} value
 * @return {!proto.DeviceMessage} returns this
*/
proto.DeviceMessage.prototype.setDeviceauthmessage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceMessage} returns this
 */
proto.DeviceMessage.prototype.clearDeviceauthmessage = function() {
  return this.setDeviceauthmessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceMessage.prototype.hasDeviceauthmessage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional DeviceUpdateMessage deviceUpdateMessage = 2;
 * @return {?proto.DeviceUpdateMessage}
 */
proto.DeviceMessage.prototype.getDeviceupdatemessage = function() {
  return /** @type{?proto.DeviceUpdateMessage} */ (
    jspb.Message.getWrapperField(this, proto.DeviceUpdateMessage, 2));
};


/**
 * @param {?proto.DeviceUpdateMessage|undefined} value
 * @return {!proto.DeviceMessage} returns this
*/
proto.DeviceMessage.prototype.setDeviceupdatemessage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceMessage} returns this
 */
proto.DeviceMessage.prototype.clearDeviceupdatemessage = function() {
  return this.setDeviceupdatemessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceMessage.prototype.hasDeviceupdatemessage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional UserDeviceMessage userDeviceMessage = 3;
 * @return {?proto.UserDeviceMessage}
 */
proto.DeviceMessage.prototype.getUserdevicemessage = function() {
  return /** @type{?proto.UserDeviceMessage} */ (
    jspb.Message.getWrapperField(this, proto.UserDeviceMessage, 3));
};


/**
 * @param {?proto.UserDeviceMessage|undefined} value
 * @return {!proto.DeviceMessage} returns this
*/
proto.DeviceMessage.prototype.setUserdevicemessage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.DeviceMessage} returns this
 */
proto.DeviceMessage.prototype.clearUserdevicemessage = function() {
  return this.setUserdevicemessage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.DeviceMessage.prototype.hasUserdevicemessage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string hardwareId = 100;
 * @return {string}
 */
proto.DeviceMessage.prototype.getHardwareid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.DeviceMessage} returns this
 */
proto.DeviceMessage.prototype.setHardwareid = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};


goog.object.extend(exports, proto);
