import React from "react";
import { columnGraph } from "../../assets";
import './styles';

export default function ActivityTable({ data }) {
  return (
    <div className="station-table-homepage">
      <div className="item-title-homepage" >
          <div className="d-flex align-items-center">
            <img src={columnGraph} />
            <span className="ml-2">Hoạt động</span>
          </div>
          <span className="title-num">{ data?.nonline + data?.noffline + data?.nwarning }</span>
      </div>
      <div className="item-homepage-content scrollbar-custom-homepage">
        <div className="item-content-border-homepage" >
          <div className="d-flex align-items-center">
            <div className="label-green"></div>
            <div style={{ width: '12px' }} ></div>
            <span>Đang kết nối</span>
          </div>
          <span className="text-blue">{ data?.nonline }</span>
        </div> 
        <div className="item-content-border-homepage" >
          <div className="d-flex align-items-center">
            <div className="label-red"></div>
            <div style={{ width: '12px' }} ></div>
            <span>Cảnh báo</span>
          </div>
          <span className="text-blue">{ data?.nwarning }</span>
        </div>
        <div className="item-content-border-homepage" >
          <div className="d-flex align-items-center">
            <div className="label-yellow"></div>
            <div style={{ width: '12px' }} ></div>
            <span>Ngắt kết nối</span>
          </div>
          <span className="text-blue">{ data?.noffline }</span>
        </div>
      </div>
    </div>
  );
}
