import { useState } from "react";
import { Form, Image } from "react-bootstrap";
import { infoRed } from "../../../assets";
import './style.css';

export const FormInput = ({ title, hintText, value, onChange, isRequire = false }) => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  return (
    <Form.Group>
      <div className="d-flex justify-content-between pr-2">
        <Form.Label className="input-label">
          {title}
          {isRequire && (<span style={{ color: '#F5222D'}}>*</span>)}
        </Form.Label>
        {isRequire && 
          <div className="position-relative">
            <Image
              src={infoRed}
              style={{ cursor: 'pointer' }}
              onClick={() => setIsShowPopup(!isShowPopup)}
            ></Image>
            {isShowPopup && <span className="input-info">Vui lòng không để trống !</span>}
          </div>
        }
      </div>
      <Form.Control
        className="input-form-loginpage"
        type="text"
        placeholder={hintText}
        value={value}
        onChange={onChange}
      />
    </Form.Group>
  );
}