import ServiceBase from './service-base';

export class StatisticService extends ServiceBase {
  baseUri = '';

  async getAllStatistic() {
    return (await this.get('/api/statistic/v1/getAllStatistic')).data;
  }

  async getNotification(page = 0, size = 50) {
    return (await this.get(`/api/statistic/v1/getNotification?page=${page}&size=${size}`)).data;
  }
}
