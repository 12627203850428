import './styles';

function ItemMenuOption({icon, title, idx, isActive} ){
    return(
        <>
            <div className={`item-menu-option-commonmanagementpage ${isActive ? 'active': ''}`} >
                <img src={icon} />
                <span className='item-menu-option-content-commonmanagementpage'>{title}</span>
            </div>
        </>
    )
}

export { ItemMenuOption };
