import React from 'react';
import './styles';
import moment from 'moment';

const ActivityNotifyList = ({ data }) => {

    return (
        <div className='scrollbar-homepage scrollbar-custom-homepage'
            style={{ maxWidth: '434px' }}>
            {data?.map(item => {
                return (
                    <ItemNotify
                        key={item.id}
                        id={item.id}
                        content={item.content}
                        date={item.dateTime}
                    />
                );
            })}
        </div>
    );
}

const ItemNotify = ({idx, id, date, content }) => {
    return (
        <div key={idx} className='item-notify-homepage'>
            <div className="item-notify-header">
                <span className="item-notify-id" >
                    ID #{id}
                </span>
                <span className="item-notify-time" >
                    {moment(date).format('YYYY-MM-DD | HH:mm:ss')}
                </span>
            </div>
            <div>
                <p className="item-notify-description" >{content}</p>
            </div>
        </div>
    );
}

export default ActivityNotifyList;
