import React, {useState} from 'react';
import {Container, Row, Col, Image, Button} from 'react-bootstrap';
import '../../styles/style.css';
import Cookies from 'js-cookie';
import "../../styles/login_page_style.css";
import sha256 from "crypto-js/sha256";

import {
  bannerLeftLogin,
  sdkdLogo,
} from "../../assets";
import { FormInput } from '../../components/shared/FormInput';
import { FormPassWord } from '../../components/shared/FormPassword';
import { ERROR_USER_NAME, ERROR_LOGIN } from '../../contants';
import { ErrorLabel } from '../../components/shared/ErrorLabel';
import { AccountForgot } from '../../components/LoginPage/AccountForgot';
import { AuthService } from '../../services/AuthService';
import { AppContext } from '../../contexts/AppContext';
import * as ProtoCreator from '../ProtoCreator';
import {SignalType} from "../ControlPanelPage";


function LicensePage({onSubmit}) {

  const [license, setLicense] = useState('');

  const onHandleSubmit = async () => {
    onSubmit(license);
  }

  return (
    <Container fluid className="login-page-loginpage">
      <Row style={{ margin: "0px", padding: "0px" }}>
        <Col className="col-banner-loginpage">
          <Image
            src={bannerLeftLogin}
            className="col-banner-loginpage"
          ></Image>
        </Col>
        <Col className="col-login-form-loginpage">
          <Container className="login-form-loginpage">
            <Container className="logo-loginpage">
              <Image src={sdkdLogo} className="logo-loginpage"></Image>
            </Container>
            <Container className="login-title">
              <span>Key bản quyền</span>
            </Container>
            <Container className="login-des">
              <span>Vui lòng nhập license từ nhà cung cấp để kích hoạt phần mềm</span>
            </Container>
            <FormInput
              title={"LICENSE"}
              hintText={"NHẬP KEY Ở ĐÂY"}
              onChange={(event) => setLicense(event.target.value)}
              value={license}
              isRequire={true}
            />
            <Button
              className="submit-button-loginpage"
              type="submit"
              onClick={onHandleSubmit}
            >
              <span>Kiểm tra</span>
            </Button>
          </Container>
        </Col>
      </Row>
    </Container>
  );
}

export default LicensePage;