import {BehaviorSubject} from 'rxjs';
export class AppSession {
    streamStationState = new BehaviorSubject(null);
    streamStationRealtimeState = new BehaviorSubject(null);
    streamAccountList = new BehaviorSubject(null);
    streamStationList = new BehaviorSubject(null);
    streamOnOff = new BehaviorSubject(null);
    streamCheckDevice = new BehaviorSubject(null);
    streamSchedule = new BehaviorSubject(null);
    closeAll(){
        this.streamStationState.complete();
        this.streamStationRealtimeState.complete();
        this.streamAccountList.complete();
        this.streamStationList.complete();
        this.streamOnOff.complete();
        this.streamCheckDevice.complete();
        this.streamSchedule.complete();
    }
}