import React, { useEffect } from 'react'
import { useState } from 'react';
import { close } from '../../../assets';
import { Button } from '../../../components/shared/Button';
import { ReactSVG } from 'react-svg';
import { DocumentService } from '../../../services/DocumentService';
import { NoteService } from '../../../services/NoteService';

export default function UserManual() {
  const [fileList, setFileList] = useState([]);
  const documentService = new DocumentService();
  const noteService = new NoteService();

  useEffect(async () => {
    const res = await documentService.getDocuments();
    if (res && res.data) {
      setFileList(res.data.documents);
    }
  }, []);

  return (
    <div className='p-4'>
      <div className='content-table-profilepage w-100 h-auto p-4'>
        <span className='heading-profile-info-profilepage mb-5' style={{textAlign:'start'}} >
          Hướng dẫn sử dụng
        </span>
        <InputFormAddFile title={"Upload file hướng dẫn"} onChangeValue={async (file) => {
          const res = await noteService.uploadImage(file);
          console.log("RES", res);
          if (res && res.data) {
            const result = await documentService.createDocument({
              name: file.name,
              url: res.data.url
            })
            if (result && result.data) {
              setFileList(result.data.documents);
            }
          }
        }} />
        <div className='mt-5 w-100'>
          {fileList.map((item, index) => (
            <div key={item.id} className='manual-item'>
              <span>{item.name}</span>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '24px' }}>
                <Button text={'Tải về'} onClick={() => {
                  fetch(item.url, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/pdf',
                    },
                  })
                  .then((response) => response.blob())
                  .then((blob) => {
                    // Create blob link to download
                    const url = window.URL.createObjectURL(
                      new Blob([blob]),
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                      'download',
                      item.name,
                    );
                
                    // Append to html link element page
                    document.body.appendChild(link);
                
                    // Start download
                    link.click();
                
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                  });
                }} />
                <div className='d-flex align-items-center' onClick={() => {
                  documentService.deleteDocument(item.id);
                  fileList.splice(index, 1);
                  setFileList([...fileList]);
                }} style={{ cursor: 'pointer' }}>
                  <ReactSVG src={close} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function InputFormAddFile({title,value,onChangeValue,fileName}){
  // const [_filename,_getFileName] = useState(fileName);
  return(
    <div style={{display:'flex',flexDirection:'row',width:'100%',justifyContent:'start'}}>
        <span className='title-overview-info-systemsettingpage' style={{width:'250px',textAlign:'start'}}>
            {title}
        </span>
        <div style={{width:'10px'}}></div>

        <div className='custom-file-input-systemsettingpage'>
            <label className='upload'>
                <input type="file" accept="application/pdf" id="image_uploads" name="image_uploads" onChange={(e)=>{ if (e.target.files[0]) onChangeValue(e.target.files[0])}} />
                Chọn tệp
            </label>
        </div>
        {/* <div style={{width:'10px'}}></div> */}
        {/* <span style={{
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'start',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '133%',
            textAlign: 'center',
            textDecorationLine: 'underline',
            color: '#3751FF',
        }}>
            {_filename}
        </span> */}
    </div>
  );
}
