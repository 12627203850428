import {useEffect, useState} from "react";
import { InputTimer } from "../../../components/SchedulePage/InputTimer";
import {useAppBloc} from "../../../contexts/AppContext";
import { StationService } from "../../../services/StationService";


function ItemConfigActionTime({idStation, onTapDone, onTapCancel}){
    console.log(`reach action time`, idStation);
    const { appBloc } = useAppBloc();
    const [timeFromStream, setTimeFromStream] = useState('11:20');
    const [timeToStream, setTimeToStream] = useState('13:40');
    const [timeFrom, setTimeFrom] = useState(null);
    const [timeTo, setTimeTo] = useState(null);
    const stationService = new StationService();

    useEffect(() => {
        appBloc.session.streamStationRealtimeState.subscribe((v) => {
            console.log(`stream n`)
            if (v && v.activeTimeBegin !== timeFromStream) {
                setTimeFromStream(v.activeTimeBegin);
            }
            if (v && v.activeTimeEnd !== timeToStream) {
                setTimeToStream(v.activeTimeEnd);
            }
        });
    }, []);

    useEffect(async () => {
        if (idStation) {
            const result = await stationService.getActiveTime(idStation);
            if (result && result.data) {
                setTimeFrom(result.data.start);
                setTimeTo(result.data.end);
            }
        }
    }, [idStation]);

    return(
        <div className="modal-overlap">
            <div className="config-time-container">
                <span className="config-time-title">
                    Thời gian hoạt động
                </span>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '62px', justifyContent: 'center' }} >
                    <InputTimer title={"Từ"} value={timeFrom} onChange={(time) => setTimeFrom(time)} />
                    <InputTimer title={"Đến"} value={timeTo} onChange={(time) => setTimeTo(time)} />
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '80px', justifyContent: 'center' }} >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '12px 16px',
                            gap: '10px',
                            height: '42px',
                            background: '#FFFFFF',
                            borderRadius: '4px',
                            border: '1px solid #3751FF',
                            width: '121px',
                            cursor: 'pointer',
                        }}
                        onClick={onTapCancel}
                    >
                        <span style={{
                            fontStyle: 'normal',
                            fontSize: '16px',
                            lineHeight: '20px',
                            textSlign: 'center',
                            color: '#3751FF',
                        }}>Hủy bỏ</span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '8px 16px',
                            gap: '10px',
                            height: '42px',
                            background: '#3751FF',
                            borderRadius: '4px',
                            width: '121px',
                            cursor: 'pointer',
                        }}
                        onClick={() => onTapDone(timeFrom || timeFromStream, timeTo || timeToStream)}
                    >
                        <span style={{
                            fontStyle: 'normal',
                            fontSize: '16px',
                            lineHeight: '20px',
                            textSlign: 'center',
                            color: '#FFFFFF',
                        }}>Áp dụng</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { ItemConfigActionTime };