import { AlertReceiveOption, ItemMenuOption, ReportOption, StationManagement, AccountManagement } from './components';

import React from 'react';
import '../../styles/profile_page_style.css';

import {settingCollapse, menuBurger,blackBoardChart,remoteControl,singleUserSquare,soundCloud,warning2 } from '../../assets';
import { SoftwareOption } from './components/SoftwareOption';


const listItemMenuOptionCommonManagementPage=[
  {
    title:'Quản lý tài khoản',
    icon: singleUserSquare,
    child: (<AccountManagement/>),
  },
  {
    title: 'Tiếp nhận cảnh báo',
    icon:  warning2 ,
    child: (<AlertReceiveOption/>),
  },
  {
    title: 'Quản lý trạm',
    icon:  remoteControl ,
    child: (<StationManagement/>),
  },
  {
    title: 'Phần mềm',
    icon:  soundCloud ,
    child: (<SoftwareOption/>),
  },
  {
    title: 'Báo cáo',
    icon:  blackBoardChart ,
    child: (<ReportOption/>),
  }
];


class CommonManagementPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      optionIndex: 0,
    };
    this.onSelectOption = this.onSelectOption.bind(this);

  }


  onSelectOption(index) {
    this.setState(state => state.optionIndex = index);
  }

  render() {
    return (
      <>
        <div style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          marginTop: '30px',
          marginBottom: '30px'

        }}>

          <div className='drawer-option-managementcommonpage'>
            <div style={{ padding: '0px 12px 10px 12px' ,display:'flex',flexDirection:'row',width:'100%',justifyContent:'space-between'}}>
              <div style={{ display: 'flex', flexDirection: 'row'}}>
                <img src={menuBurger} />
                <div style={{ width: '8px' }} ></div>
                <span className='drawer-option-managementcommonpage-label'>Menu</span>
              </div>
              <img src={settingCollapse} />
            </div>

            {/* List option */}

            {listItemMenuOptionCommonManagementPage.map((option, index) => {
              return (
                <div key={index} onClick={() => {this.onSelectOption(index)}} style={{width:'100%'}} >
                  <ItemMenuOption isActive={this.state.optionIndex === index} icon={option.icon} title={option.title} idx={index} />
                </div>
                
              );
            })
            }

          </div>

          <div style={{ width: '20px' }} ></div>

          <div className='content-table-managementcommonpage'>
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: '600',
                fontSize: '24px',
                lineHeight: '133%',
                textAlign: 'center',
                color: '#3751FF',
              }}
            >{listItemMenuOptionCommonManagementPage[this.state.optionIndex].title}</span>

          
            {listItemMenuOptionCommonManagementPage[this.state.optionIndex].child}
          

          </div>

        </div>
      </>

    );
  }
}

export default CommonManagementPage;