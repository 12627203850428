import {useEffect, useState} from "react";
import {BottomButtom} from '../../../components';
import './styles';
import {StatisticService} from "../../../services/StatisticService";
import {SettingService} from "../../../services/SettingService";

function FormInputReceiveOption({hint,title,value,onChange}){
    // const [value, onChangeValue] = useState('');
    return(
        <div style={{display:'flex',flexDirection:'column',justifyContent:'start',alignItems:'start'}} >
            <span
                style={{
                    fontStyle:'normal',
                    fontSize:'14px',
                    fontWeight:'500',
                    lineHeight:'133%',
                    textAlign:'center',
                    color:'#172B4D',
                }}
            >{title}</span>
            <div style={{height:'12px'}} ></div>
            <input type={'text'} value={value} onChange={(event) => onChange(event.target.value)} className='input-form-profile-info-commonmanagementpage' style={{width:'307px'}} placeholder={hint} color={'#172B4D'} />
        </div>
    );
}

function AlertReceiveOption(){

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const settingService = new SettingService();

  useEffect(async () => {
    const res = await settingService.getContact();
    if (res && res.data) {
      setEmail(res.data.email);
      setPhone(res.data.phone);
    }
  }, []);

    return(
        <>
        <div style={{display:'flex',flexDirection:'row',gap:'24px',justifyContent:'start',alignItems:'center',width:'100%'}} >
            <FormInputReceiveOption title={"Email nhận cảnh báo :"} hint={"Nhập email"} value={email} onChange={(data) => setEmail(data)} />
            <FormInputReceiveOption title={"Số điện thoại nhận cảnh báo :"} hint={"Nhập phone"} value={phone} onChange={(data) => setPhone(data)} />
        </div>
        <BottomButtom
            title={"Lưu lại"}
            style={{
                color:'#FFFFFF',
                width:'132px',
                height:'42px',
                fontSize:'16px',
                fontStyle:'normal',
                fontWeight:'700',
                lineHeight:'133%',
            }}
            onTap={() => settingService.updateContact(email, phone)}
         />

        </>
    );
}
export { AlertReceiveOption };