import { useState } from 'react';
import { ClearInput } from '../../../assets';
import './style.css';

export const InputSearch = ({ value, onChange }) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <div className="input-search">
      <input onFocus={() => setIsFocus(true)} onBlur={() => setIsFocus(false)} type="text" value={value} onChange={e => onChange(e.target.value)} placeholder="Search" />
      {value && <div className="cursor-pointer d-flex" onClick={() => onChange("")}>
        <ClearInput color={isFocus ? '#1C64F2' : '#6B7280'} />
      </div>}
    </div>
  );
}
