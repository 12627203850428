function Checkbox({value, onChange, text = ""}) {
  return (
    <div className="d-flex align-items-center">
      <input value={value} onChange={onChange} style={{ width: '16px', height: '16px' }} type="checkbox" />
      {text && <span style={{ color: '#262626', fontSize: '18px', fontWeight: '500', marginLeft: '14px' }}>{text}</span>}
    </div>
  );
}

export default Checkbox;
