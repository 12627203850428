import Table from 'react-bootstrap/Table';
import './table-status.css';
import { useEffect, useState } from 'react';
import { SignalService } from '../../services/SignalService';

export const TableStatus = ({ stationId, dataSensor, dataDevideSocket }) => {
    const [actions, setActions] = useState([]);
    const signalService = new SignalService();

    useEffect(() => {
        if (dataSensor?.length) {
            let temp = [];
            for (const item of dataSensor) {
                temp.push({
                    action: false
                })           
            }
            setActions([...temp]);
        }
    }, [dataSensor]);

    console.log("ACTIONS", dataSensor, actions);

    return (
      <Table className="table-status">
        <thead>
          <tr className="table-status-tr">
            <td className="table-status-td">Ngõ</td>
            <td className="table-status-td">Tín hiệu</td>
            <td className="table-status-td">Hành động</td>
            <td className="table-status-td">Tên</td>
          </tr>
        </thead>
        <tbody>
          {dataSensor?.map((item, idx) => (
            <tr key={idx} className="table-status-tr">
              <td className="table-status-td">{idx + 1}</td>
              <td className="table-status-td">
                <div className="d-flex justify-content-center">
                  <span
                    className={
                      dataDevideSocket?.outputSensorList[idx] === 0
                        ? "status-green"
                        : "status-red"
                    }
                  ></span>
                </div>
              </td>
              <td className="table-status-td">
                {dataDevideSocket?.outputSensorList[idx] === 0 ? (
                  <span className="color-gray">----</span>
                ) : actions.length && actions[idx].action ? (
                  <span className="color-gray">Đã ghi nhận</span>
                ) : (
                  <input
                    type="checkbox"
                    onChange={() => {
                      signalService.check(stationId);
                      actions[idx].action = true;
                      setActions([...actions]);
                    }}
                  />
                )}
              </td>
              <td className="table-status-td">
                <nav className="w-auto status-input">{item.name}</nav>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
}
