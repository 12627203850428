import React from 'react'

export default function Support() {
  return (
    <div className='p-4'>
      <div className='content-table-profilepage w-100 h-auto p-4'>
        <h3 className='font-weight-bold mx-auto mb-4'>CÔNG TY TNHH GIẢI PHÁP CÔNG NGHỆ VÀ ĐẦU TƯ XÂY DỰNG SDKD</h3>
        <h4 className='mb-4 font-weight-bold'>Thông tin chung về Công ty TNHH giải pháp công nghệ và đầu tư xây dựng SDKD</h4>
        <ul>
          <li>Tên công ty: Công ty TNHH giải pháp công nghệ và đầu tư xây dựng SDKD</li>
          <li>Tên giao dịch: SDKD CONTRUCTION INVESTMENT AND TECHNOLOGY SOLUTIONS COMPANY LIMITE</li>
          <li>Tên viết tắt: SDKD Cits Co.,LTD</li>
          <li>Trụ sở: Số 801/3B đường Hà Huy Giáp, Khu Phố 3, Phường Thạnh Xuân, Quận 12, Tp.Hồ Chí Minh</li>
          <li>Điện thoại: 0961.333.599/ 0984.345.399</li>
          <li>Giám đốc: Bà Ngô Thị Mỹ Hạnh</li>
          <li>Website: www.Sdkd.com.vn</li>
          <li>Email: Sdkd.citsco@gmail.com</li>
        </ul>

        <h4 className='mb-4 mt-4 font-weight-bold'>Giới thiệu chung về Công ty TNHH giải pháp công nghệ và đầu tư xây dựng SDKD</h4>

        <nav className='ml-4 mb-4'>Công ty TNHH Giải Pháp Công Nghệ Và Đầu Tư Xây Dựng SDKD là một trong những đơn vị đi đầu trong ngành công nghệ đèn Tín hiệu giao thông, đèn led chiếu sáng và các giải pháp điều khiển thông minh tại Việt Nam. Chuyên sản xuất và cung cấp, thi công, lắp đặt các sản phẩm đèn Tín hiệu giao thông, đèn led chiếu sáng, đèn chiếu sáng trang trí nghệ thuật và các giải pháp điều khiển thông minh, ứng dụng IOT trong lĩnh vực công nghiệp, nông nghiệp và dịch vụ ... Các sản phẩm của Công ty chúng tôi được ứng dụng rộng rãi trong các lĩnh vực An toàn giao thông, chiếu sáng công cộng, chiếu sáng cho đường cao tốc, đèn chiếu sáng thông minh, trung tâm điều khiển thông minh, chiếu sáng trang trí nghệ thuật phục vụ các lễ hội, du lịch, các văn phòng, tòa nhà thông minh, các nhà máy sản xuất, các nông trại, các trang trại…</nav>

        <nav className='ml-4 mb-4'>Tất cả các sản phẩm của Công ty chúng tôi được sản xuất với quy trình kiểm soát nghiêm ngặt theo đúng tiêu chuẩn ISO 9001-2015. Các sản phẩm được sản xuất bằng các linh kiện cao cấp như: Chip LED của Nichia (Japan), OsRam (Germany) Cree (USA), Bridgelux (USA)… là những chip LED đứng đầu thế giới về tính ổn định và độ bền cũng như chất lượng; Meanwell–thương hiệu nguồn tốt nhất thế giới cũng là linh kiện mà chúng tôi chọn lựa cho các sản phẩm đèn của Công ty.</nav>

        <nav className='ml-4 mb-4'>Cùng với đội ngũ cán bộ kỹ thuật có bề dày kinh nghiệm trên 10 năm, đội ngũ chuyên gia có chuyên môn kỹ thuật cao, Công ty TNHH giải pháp công nghệ và đầu tư xây dựng SDKD không ngừng nghiên cứu và phát triển các sản phầm của Công ty, mang đến cho đối tác và khách hàng các sản phẩm có CHẤT LƯỢNG CAO NHẤT, GIÁ THÀNH HỢP LÝ NHẤT.</nav>
      </div>
    </div>
  )
}
