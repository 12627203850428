import { Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import './styles';

function ItemOption({ title, isSelected,path,onTap }) {
    return (
        <Nav.Link onClick={onTap} as={Link} to={path} className={`item-option-profile-info-profilepage ${isSelected ? 'active' : ''}`}>
            {title}
        </Nav.Link>
    )
}

export { ItemOption };
