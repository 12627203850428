import { useState } from "react";
import { Image } from "react-bootstrap";
import { bellGreen, closeModal } from "../../assets";
import ModelBottomSheet from "../ModelBottomSheet/ModelBottomSheet";
import "./account-forgot.style.css";


function ModalBottomSheetForgotAccount({ onTapClose }) {
  return (
    <div className="bottom-sheet-forgot-account-commonmanagementpage">
      <Image
        src={closeModal}
        className="modal-close"
        onClick={onTapClose}
      ></Image>
      <Image src={bellGreen}></Image>
      <span className="forgot-title">Cấp lại tài khoản</span>
      <div className="forgot-account-text">Vui lòng liên hệ quản trị viên để được hỗ trợ :</div>
      <div className="forgot-account-text">Hotline : <span>1900 1111</span></div>
      <div className="forgot-account-text">Email : <span>cskh.admin@gmail.com</span></div>

      <div className="h-line"></div>
      <div className="ml-auto mt-3">
        <div className="forgot-btn" onClick={onTapClose}>
          Đã hiểu
        </div>
      </div>
    </div>
  );
}

export const AccountForgot = () => {
  const [show, setShow] = useState(false);
  const [showSusscess, setShowSuccess] = useState(false);

  return (
    <>
      <div>
        <button
          className="forget-password-button-loginpage"
          onClick={() => {
            setShow(true);
          }}
        >
          <span>Quên mật khẩu ?</span>
        </button>
      </div>

      <ModelBottomSheet
        key={"ItemContentTableStationManagement"}
        animation={true}
        centered={true}
        size={"md"}
        show={show}
        children={
          <ModalBottomSheetForgotAccount
            onTapClose={() => {
              setShow(false);
            }}
          />
        }
      />
    </>
  );
}
