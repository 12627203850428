import React,{ useState }  from "react";
import { Select } from "../../../components/shared/Select";
import './styles';

const listRoleSetting = [
    {
        key: '1',
        text: 'Giám sát',
    },
    {
        key: '2',
        text: 'Không giám sát',
    }
];

const data =[
    {
        isCheck:false,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:true,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:true,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:false,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:true,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:false,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:true,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:false,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:true,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:true,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:false,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:true,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:false,
        title: 'Nội dung quyền tại đây...'
    },
    {
        isCheck:true,
        title: 'Nội dung quyền tại đây...'
    }
];

function SelectorForm({ title, roles }) {
    const [isSelect, onSelect] = useState(false);
    return (
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'start' }}>
            <span className='title-overview-info-systemsettingpage mr-5'>
                {title}
            </span>
            <div style={{ width: '10px' }}></div>
            <Select className="w-272 gray-5" options={roles} onChange={() => {}} />
        </div>
    );
}

function RoleSetting() {
    function onTapSave() {

    }


    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <SelectorForm title={'Vai trò'} roles={listRoleSetting} />
            <div style={{ height: '20px' }} ></div>
            <div className="table-role-setting-systemsettingpage" >
                <div className='scrollbar-homepage scrollbar-custom-homepage' style={{ width: '100%' }}>
                    {data.map((datum, idx) => {
                        return (
                            <div key={idx} className="input-checkbox-systemsettingpage" >
                                <input type="checkbox" style={{display:'block',width:'18px',height:'18px'}}/>
                                <div style={{width:'32px'}} ></div>
                                <label className="label-title-systemsettingpage">{datum.title}</label><br></br>
                            </div>
                        );
                    })}
                </div>

            </div>

            <div style={{ height: '20px' }} ></div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <button
                    onClick={onTapSave}
                    type='button'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '12px 71px',
                        backgroundColor: '#3751FF',
                        borderRadius: '4px',
                        borderWidth: '0px',
                    }}
                >
                    <span
                        style={{
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '133%',
                            color: '#FFFFFF',
                        }}
                    >Lưu lại</span>
                </button>
            </div>
        </div>
    );
}

export { RoleSetting };