import { CloseGround } from "../../../assets";
import './style.css';

export const ErrorLabel = ({ label, onClose }) => {
  return (
    <div
      className="error-label-loginpage"
      style={{ display: label === "" ? "none" : "block" }}
    >
      <button
        onClick={onClose}
        type="button"
        style={{
          backgroundColor: "#00000000",
          borderWidth: "0px",
          borderColor: "#00000000",
          marginRight: "10px",
        }}
      >
        <CloseGround />
      </button>
      <span
        style={{
          color: "#F5222D",
          margin: "0px",
          padding: "12px 0px 12px 0px",
        }}
      >
        {label}
      </span>
    </div>
  );
}
