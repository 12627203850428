import React, {useContext, useEffect, useState} from "react";
import { StationDetail } from './components';

import { listParagraph, settingCollapse } from '../../assets';
import {AppContext, useAppBloc} from "../../contexts/AppContext";
import * as ProtoCreator from "../ProtoCreator";
import { SideBarCollased } from "../../components/SideBarCollapsed";
import { ListStationControlPanel } from "../ControlPanelPage/components";
import { ListStationOption } from "../../components/ControlPannelPage/ListStationOption";
import { LabelStatus } from "../../components/ControlPannelPage/LabelStatus";
import { StationService } from "../../services/StationService";


function StrategyPage({ appBloc }) {
    const [isAll, setIsAll] = useState(0);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [currentStation, setCurrentStation] = useState(0);
    const [currentStationId, setCurrentStationId] = useState('');
    const [isExpanded, setIsExpanded] = useState(true);
    const [textSearch, setTextSearch] = useState('');
    const stationService = new StationService();

    useEffect(async () => {
        const data = await stationService.getStations();
        if (data?.data?.stations) {
            setData(data?.data?.stations);
            setFilterData(data?.data?.stations);
        }
    }, []);

    const onSelectItem = (idx, stationId) => {
        console.log(`selected station: ${stationId}`);
        if (stationId) {
            appBloc.websocket.send(ProtoCreator.getScheduleUserRequest(stationId));
        }

        setCurrentStation(idx);
        setCurrentStationId(stationId);
    }

    const onSetItemOnList = (idx, value) => {
        if (value === true){
            data[idx].online = true;
            filterData[idx] = null;
        } else {
            data[idx].online = false;
            filterData[idx] = data[idx];
        }
        
        setData([...data]);
        setFilterData([...filterData]);
    }

    const onSelectOpionAll = (value, textSearch) => {
        if (value === 0) {
            setIsAll(value);
            setFilterData(data.filter(item => item.stationName.includes(textSearch)));
        } else {
            setIsAll(value);
            setFilterData(data.filter(item => item.online === (value === 1) && item.stationName.includes(textSearch)));
        }
    }

    const getCurrentStatus = () => {
        return currentStation === null ? "" : (data[currentStation]?.online); 
    }

    const handleSearch = (value) => {
        onSelectOpionAll(isAll, value);
        setTextSearch(value);
    }

    const onConfirm = async (data) => {
        const result = await stationService.addStation(data);
        if (result?.data?.stations) {
            setData(result?.data?.stations);
            setFilterData(result?.data?.stations);
        }
        return true;
    }

    return (
        <>
            <div className="controlpannel-container">
                <SideBarCollased isExpanded={isExpanded} setIsExpanded={setIsExpanded} 
                    children={<>
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex" >
                                <img src={listParagraph} />
                                <span className="controlpannel-hero">Danh sách trạm</span>
                            </div>
                            <img src={settingCollapse} style={{ cursor: 'pointer' }} onClick={() => { setIsExpanded(false) }} />
                        </div>
                        <ListStationOption onConfirm={onConfirm} isChoosen={isAll} onSelect={(value) => onSelectOpionAll(value, textSearch)} value={textSearch} setValue={handleSearch} />
                        <ListStationControlPanel highlightText={textSearch} currentStation={currentStation} onSelectItem={onSelectItem} listData={filterData} onSetItemList={onSetItemOnList} />
                    </>} 
                />
                <div className='content-table-strategypage'>
                    {currentStation !== null && 
                        <div className="d-flex align-items-center">
                            <span className="subtitle-station-name-strategypage mr-4">{currentStation === null ?"" :(data[currentStation]?.stationName)}</span>
                            <LabelStatus value={getCurrentStatus()} />
                        </div>
                    }
                    <StationDetail station={data[currentStation]} idx={currentStation}/>
                </div>
            </div>
        </>
    );
}

export { StrategyPage };
