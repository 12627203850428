import React from "react";
import { user } from "../../assets";
import './styles';

export default function AccountTable({ data }) {
  return (
    <div className="station-table-homepage">
      <div className="item-title-homepage">
        <div className="d-flex align-items-center">
          <img src={user} />
          <span className="ml-2">Tài khoản</span>
        </div>
        <span className="title-num">
          {data?.noperator + data?.nviewer + data?.nadmin}
        </span>
      </div>
      <div className="item-homepage-content scrollbar-custom-homepage">
        <div className="item-content-border-homepage">
          <span>Vận hành</span>
          <span className="text-blue">{data?.noperator}</span>
        </div>
        <div className="item-content-border-homepage">
          <span>Giám sát</span>
          <span className="text-blue">{data?.nviewer}</span>
        </div>
        <div className="item-content-border-homepage">
          <span>Quản trị</span>
          <span className="text-blue">{data?.nadmin}</span>
        </div>
      </div>
    </div>
  );
}
