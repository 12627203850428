import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import '../../styles/style.css';
import Cookies from 'js-cookie';
import "../../styles/login_page_style.css";
import sha256 from "crypto-js/sha256";

import {
  bannerLeftLogin,
  sdkdLogo,
} from "../../assets";
import { FormInput } from '../../components/shared/FormInput';
import { FormPassWord } from '../../components/shared/FormPassword';
import { ERROR_USER_NAME, ERROR_LOGIN } from '../../contants';
import { ErrorLabel } from '../../components/shared/ErrorLabel';
import { AccountForgot } from '../../components/LoginPage/AccountForgot';
import { AuthService } from '../../services/AuthService';
import { AppContext } from '../../contexts/AppContext';
import * as ProtoCreator from '../ProtoCreator';


class LoginPage extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.onSubmitLoginForm = props.onSubmitLoginForm || null;
    this.state = {
      values: {
        name: "",
        userName: "",
        password: "",
      },
      isHidePass: true,
      isFocusPass: false,
      error: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onPressforgetPassword = this.onPressforgetPassword.bind(this);
    this.onTapHidePass = this.onTapHidePass.bind(this);
    this.onBlurPass = this.onBlurPass.bind(this);
    this.onFocusPass = this.onFocusPass.bind(this);
    this.onCloseError = this.onCloseError.bind(this);
    this.validate = this.validate.bind(this);
  }

  componentDidMount() {}

  onChangeName(event) {
    this.setState((state) => (state.values.name = event.target.value));
  }

  onChangeUserName(event) {
    this.setState((state) => (state.values.userName = event.target.value));
  }

  onChangePassword(event) {
    this.setState((state) => (state.values.password = event.target.value));
  }

  onPressforgetPassword(event) {
    console.log("Forget password");
  }

  onTapHidePass(event) {
    let value = this.state.isHidePass;
    this.setState((state) => (state.isHidePass = value != true));
  }
  onFocusPass(event) {
    this.setState((state) => (state.isFocusPass = true));
  }
  onBlurPass(event) {
    this.setState((state) => (state.isFocusPass = false));
  }
  onCloseError(event) {
    this.setState((state) => (state.error = ""));
  }

  validate() {
    if (this.state.values.name.replaceAll(/\s/g, "") === "") {
      return ERROR_USER_NAME;
    }
    return "";
  }

  async handleSubmit(event) {
    event.preventDefault();
    let error = this.validate();
    if (error !== "") {
      return this.setState((state) => (state.error = error));
    }

    this.setState((state) => {
      if (state.values.name === "") {
        state.error = ERROR_USER_NAME;
      } else {
        state.error = "";
      }
    });
    this.validate();

    const authService = new AuthService();
    const data = await authService.login({ username: this.state.values.userName, password: this.state.values.password });

    // if (this.ws) {
    //   this.ws.send(ProtoCreator.CreateLoginMessage(userName, password, null, null, process.env.REACT_APP_MODE === 'web'));
    // }

    if (data.data?.token) {
      Cookies.set('token', data.data?.token);
      Cookies.set('refreshToken', data.data?.refreshToken);
      const { setIsAuthenticated } = this.context;

      this.onSubmitLoginForm(data.data?.token);

      // appProps.websocket.send(ProtoCreator.CreateLoginMessage("sdkd2", sha256("Sdkd@123").toString(), null, null, process.env.REACT_APP_MODE === 'web'));

      setIsAuthenticated(true);
    } else {
      this.setState(state => state.error = ERROR_LOGIN)
    }
  }

  render() {
    return (
      <Container fluid className="login-page-loginpage">
        <Row style={{ margin: "0px", padding: "0px", height: "100%" }}>
          <Col className="col-banner-loginpage">
            <Image
              src={bannerLeftLogin}
              className="col-banner-loginpage"
            ></Image>
          </Col>
          <Col className="col-login-form-loginpage">
            <Container className="login-form-loginpage">
              <Container className="logo-loginpage">
                <Image src={sdkdLogo} className="logo-loginpage"></Image>
              </Container>
              <Container className="login-title">
                <span>Đăng nhập</span>
              </Container>
              <Container className="login-des">
                <span>Vui lòng nhập tài khoản và mật khẩu để tiếp tục</span>
              </Container>
              <ErrorLabel
                label={this.state.error}
                onClose={this.onCloseError}
              ></ErrorLabel>
              <FormInput
                title={"TÊN NGƯỜI DÙNG"}
                hintText={"NGUYỄN VĂN A"}
                onChange={this.onChangeName}
                value={this.state.values.name}
                isRequire={true}
              />
              <FormInput
                title={"TÀI KHOẢN"}
                hintText={"Nhập tài khoản"}
                onChange={this.onChangeUserName}
                value={this.state.values.userName}
              />
              <FormPassWord
                title={"MẬT KHẨU"}
                hintText={"Nhập mật khẩu"}
                onChange={this.onChangePassword}
                value={this.state.values.password}
                isHide={this.state.isHidePass}
                onTapHidePass={this.onTapHidePass}
                onBlur={this.onBlurPass}
                onFocus={this.onFocusPass}
                isFocus={this.state.isFocusPass}
              />
              <button
                className="submit-button-loginpage"
                type="submit"
                onClick={this.handleSubmit}
              >
                <span>Đăng nhập</span>
              </button>
              <AccountForgot />
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default LoginPage;