import { useEffect, useState } from "react";
import './styles';
import { Plus, sendMessage, close, repair, warning, check, exported } from '../../../assets';
import { CustomTable, ModelBottomSheet } from '../../../components';
import { ChildConfirm } from "../../../components/ModelBottomSheet/ChildConfirm";
import { AccessoryService } from "../../../services/AccessoryService";
import moment from "moment";
import { NoteService } from "../../../services/NoteService";
import { CSVLink } from "react-csv";
import { exportToCSV } from "../../../utils";
// import { Modal } from "react-bootstrap";

let listMessage=[
  {
    dateTime: "13/04/22 03:47:43",
    tag : 'SDKD',
    content: "Kiểm tra hoạt động",
  },

]

let ListStationManagement=[
  {
    index:1,
    name: 'MDC100',
    code:'C100',
    from :'SDKD',
    number:'Trụ 1',
    location:'Trên rìa',
    createAt :'22/05/2022',
    status:'Hoạt động',
  },
  {
    index: 2,
    name: 'MAC99',
    code: 'AC99',
    from: 'SDKD',
    number: 'Trụ 2',
    location: 'Trên rìa',
    createAt: '01/04/2022',
    status: 'Đã ngừng',
  },
  {
    index: 3,
    name: 'MAC98',
    code: 'AC99',
    from: 'SDKD',
    number: 'Trụ 3',
    location: 'Trên rìa',
    createAt: '01/04/2022',
    status: 'Đã ngừng',
  },
  {
    index: 4,
    name: 'MAC97',
    code: 'AC99',
    from: 'SDKD',
    number: 'Trụ 4',
    location: 'Trên rìa',
    createAt: '01/04/2022',
    status: 'Đã ngừng',
  },
  {
    index: 5,
    name: 'MAC96',
    code: 'AC99',
    from: 'SDKD',
    number: 'Trụ 5',
    location: 'Trên rìa',
    createAt: '01/04/2022',
    status: 'Đã ngừng',
  },
  {
    index: 6,
    name: 'MAC95',
    code: 'AC99',
    from: 'SDKD',
    number: 'Trụ 6',
    location: 'Trên rìa',
    createAt: '01/04/2022',
    status: 'Đã ngừng',
  }
];

function TitleTableDevice(){
  return(
      <div className="item-form-title-table-managementdevicepage">
        <span className="item-title-table-managementdevicepage" style={{width:'42px'}}>STT</span>
        <span className="item-title-table-managementdevicepage" style={{ width: '120px' }}>Tên trạm</span>
        <span className="item-title-table-managementdevicepage" style={{ width: '120px' }}>Mã thiết bị</span>
        <span className="item-title-table-managementdevicepage"  style={{ width: '100px' }}>Xuất xứ</span>
        <span className="item-title-table-managementdevicepage"  style={{ width: '80px' }}>Trụ số</span>
        <span className="item-title-table-managementdevicepage"  style={{ width: '80px' }}>Vị trí lắp</span>
        <span className="item-title-table-managementdevicepage"  style={{ width: '80px' }}>Ngày lắp</span>
        <span className="item-title-table-managementdevicepage"  style={{ width: '80px' }}>Tình trạng</span>
        <span style={{ width: '70px' }}></span>
      </div>
  );

}

function NewItemContentTableDevice({ onRepair, idxKey, onDelete }) {
  const [_index, _setIndex] = useState('');
  const [_stationName, _setStationName] = useState('');
  const [_code, _setCode] = useState('');
  const [_from, _setFrom] = useState('');
  const [_number, _setNumber] = useState('');
  const [_location, _setLocation] = useState('');

  function addnewDevice(param){
    onRepair(
      {
        stationName: _stationName,
        model: _code,
        madeBy: _from,
        pillar: parseInt(_number),
        position: _location,
        installDate: new Date(),
        status: "NORMAL"
      }, idxKey);
  }

  return (
    <>
      <div className="item-form-content-table-managementdevicepage">
        <input type="text" value={idxKey + 1} className="input-content-table-managementdevicepage" style={{ width: '42px' }}/>
        <input type="text" value={_stationName} onChange={(e) => { _setStationName(e.target.value) }} className="input-content-table-managementdevicepage" style={{ width: '120px' }}/>
        <input type="text" value={_code} onChange={(e) => { _setCode(e.target.value) }} className="input-content-table-managementdevicepage" style={{ width: '120px' }}/>
        <input type="text" value={_from} onChange={(e) => { _setFrom(e.target.value) }} className="input-content-table-managementdevicepage" style={{ width: '100px' }}/>
        <input type="text" value={_number} onChange={(e) => { _setNumber(e.target.value) }} className="input-content-table-managementdevicepage" style={{ width: '80px' }}/>
        <input type="text" value={_location} onChange={(e) => { _setLocation(e.target.value) }} className="input-content-table-managementdevicepage" style={{ width: '80px' }}/>
        <span className="item-content-table-managementdevicepage" style={{ width: '80px' }}></span>
        <div className="item-content-table-managementdevicepage" style={{ width: '80px' }}></div>
        <div style={{ width: '70px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '14px' }}>
          <img src={check} onClick={addnewDevice}  style={{ cursor: 'pointer' }} />
          <img src={close} onClick={onDelete} style={{ cursor: 'pointer' }} />
        </div>
      </div>
    </>
  );
}

function ItemContentTableDevice({id,idxKey,index,stationName,code,from,number,location,createAt,status,onRepair,onDelete}){
  const [show, setShow] = useState(false);
  const [fix,setfix] = useState(false);
  const [_index, _setIndex] = useState(index);
  const [_stationName, _setStationName] = useState(stationName);
  const [_code, _setCode] = useState(code);
  const [_from, _setFrom] = useState(from);
  const [_number, _setNumber] = useState(number);
  const [_location, _setLocation] = useState(location);
  function retDefault(){
    _setIndex(index);
    _setStationName(stationName);
    _setCode(code);
    _setFrom(from);
    _setNumber(number);
    _setLocation(location);
  }
  function setNewValue(){
    onRepair(
      {
        id: id,
        stationName: _stationName,
        model: _code,
        madeBy: _from,
        pillar: parseInt(_number),
        position: _location,
        installDate: createAt,
        status: status,
      }, idxKey);
  }

    if(fix){
      return (
        <>
          <div className="item-form-content-table-managementdevicepage">
            <input type="text" value={_index} className="input-content-table-managementdevicepage" style={{ width: '42px' }} />
            <input type="text" value={_stationName} onChange={(e) => { _setStationName(e.target.value) }} className="input-content-table-managementdevicepage" style={{ width: '120px' }} />
            <input type="text" value={_code} onChange={(e) => { _setCode(e.target.value) }} className="input-content-table-managementdevicepage" style={{ width: '120px' }} />
            <input type="text" value={_from} onChange={(e) => { _setFrom(e.target.value) }} className="input-content-table-managementdevicepage" style={{ width: '100px' }} />
            <input type="text" value={_number} onChange={(e) => { _setNumber(e.target.value) }} className="input-content-table-managementdevicepage" style={{ width: '80px' }} />
            <input type="text" value={_location} onChange={(e) => { _setLocation(e.target.value) }} className="input-content-table-managementdevicepage" style={{ width: '80px' }} />
            <span className="item-content-table-managementdevicepage" style={{ width: '80px' }}>{moment(createAt).format("DD/MM/YYYY")}</span>
            <div className="item-content-table-managementdevicepage" style={{ width: '80px' }}>{status === 'NORMAL' ? 'Hoạt động' : 'Đã ngừng'}</div>
            <div style={{ width: '70px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '14px' }}>
              <img src={check} onClick={() => { setfix(false); setNewValue()}} style={{ cursor: 'pointer' }} />
              <img src={close} onClick={() => { setfix(false); retDefault() }} style={{ cursor: 'pointer' }} />
            </div>
          </div>
        </>
      );
    }else{
      return (
        <>
          <div className="item-form-content-table-managementdevicepage">
            {<span className="item-content-table-managementdevicepage" style={{ width: '42px' }}>{index}</span>}
            <span className="item-content-table-managementdevicepage" style={{ width: '120px' }}>{stationName}</span>
            <span className="item-content-table-managementdevicepage" style={{ width: '120px' }}>{code}</span>
            <span className="item-content-table-managementdevicepage" style={{ width: '100px' }}>{from}</span>
            <span className="item-content-table-managementdevicepage" style={{ width: '80px' }}>{number}</span>
            <span className="item-content-table-managementdevicepage" style={{ width: '80px' }}>{location}</span>
            <span className="item-content-table-managementdevicepage" style={{ width: '80px' }}>{moment(createAt).format("DD/MM/YYYY")}</span>
            <span className="item-content-table-managementdevicepage" style={{ width: '80px' }}>{status === 'NORMAL' ? 'Hoạt động' : 'Đã ngừng'}</span>
            <div style={{ width: '70px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '14px' }}>
              <img src={repair} onClick={() => { setfix(true) }} style={{ cursor: 'pointer' }} />
              <img src={close} onClick={() => {
                setShow(true)
              }} style={{ cursor: 'pointer' }} />
            </div>
          </div>

          <ModelBottomSheet
            animation={true}
            centered={true}
            size={"md"}
            show={show}
            children={
              <ChildConfirm
                title={'Xóa thiết bị'}
                description={'Bạn có chắc chắn rằng muốn xóa thiết bị này ra khỏi danh sách quản lý?'}
                onTapClose={() => { setShow(false) }}
                onTapDoit={() => {
                  onDelete();
                  setShow(false);
                }}
              />
            }
          />
        </>
      );
    }
}

function DetailManagementDevice() {
  const [_listmessage, _setListMessage] = useState([]);
  const [_contentMessage, _onChangeContentMessage] = useState('');
  const [_listStationDetail, _onUpdateStationDetail] = useState([] || ListStationManagement);
  const accessoryService = new AccessoryService();
  const noteService = new NoteService();
  const [textSearch, setTextSearch] = useState('');

  useEffect(() => {
    accessoryService.getList().then(res => {
      if (res && res.data) {
        _onUpdateStationDetail(res.data.accessories);
      }
    })

    noteService.getList().then(res => {
      if (res && res.data) _setListMessage(res.data.notes);
    })
  }, []);

  const repaired = async (data, index) => {
    let temp = [..._listStationDetail];
    if (data===null){
      temp.pop();
      _onUpdateStationDetail(temp);
    } else{
      console.log(data);
      if (data.id) {
        const res = await accessoryService.update(data);
        console.log(res);
        if (res && res.data) {
          _onUpdateStationDetail(res.data.accessories);
        }
      } else {
        const res = await accessoryService.create(data);
        if (res && res.data) {
          _onUpdateStationDetail(res.data.accessories);
        }
      }
    }
  }

  function createNewDevice(){
    let tempNewList = [..._listStationDetail];
    tempNewList.push(null);
    _onUpdateStationDetail(tempNewList);
  }

  const handleDeleteDevice = async (idx) => {
    if (_listStationDetail[idx] && _listStationDetail[idx].id) {
      await accessoryService.deleteById(_listStationDetail[idx].id);
      _listStationDetail.splice(idx, 1);
      _onUpdateStationDetail([..._listStationDetail]);
    } else {
      _listStationDetail.splice(idx, 1);
      _onUpdateStationDetail([..._listStationDetail]);
    }
  }

  const handleSendMessage = async () => {
    if (_contentMessage) {
      const result = await noteService.createNote({ message: _contentMessage});
      _onChangeContentMessage("");
      if (result && result.data) _setListMessage(result.data.notes);
    }
  }
  
  return (
    <>
        <span className="item-heading-managementdevicepage">Quản lý thiết bị</span>

        <div style={{display:'flex',flexDirection:'row',width:"100%",gap:'12px', alignItems: 'center'}}>
        <input value={textSearch} onChange={(e)=>{setTextSearch(e.target.value)}} type={'text'} className='input-placerhold-station-search-commonmanagementpage' placeholder={"Nhập tên trạm"} color={'#172B4D'} />
        <div className='btn-export'
          onClick={() => exportToCSV(_listStationDetail.filter(item => item === null || item.stationName.toLowerCase().includes(textSearch.toLowerCase())), `devices-${moment().format('DD-MM-YYYY')}`)}
        ><img src={exported} /> Export</div>

          <div 
          onClick={() => { createNewDevice();}}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '8px 16px',
            gap: '10px',
            width: '138px',
            height: '42px',
            background: '#3751FF',
            borderRadius: '4px',
            cursor:'pointer',
            flexShrink: '0',
          }}>
            <Plus fill={"#FFFFFF"} />
            <span
              style={{
                fontStyle: 'normal',
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '133%',
                color: '#FFFFFF',
              }}
            >Thêm mới</span>
          </div>
        </div>

        {/* table */}
        <div style={{width:'100%'}}>
          <CustomTable
            key={"DetailManagementDevice"}
            isDivider={false}
            style={{
              height: '263px',
            }}
            titleItem={<TitleTableDevice/>}
            children={
              _listStationDetail.filter(item => item === null || item.stationName.toLowerCase().includes(textSearch.toLowerCase())).map((item,idx)=>{
                if (item!==null){
                  return (
                    <ItemContentTableDevice
                      id={item.id}
                      key={item.id}
                      idxKey={idx}
                      code={item.model}
                      createAt={item.installDate}
                      from={item.madeBy}
                      index={idx + 1}
                      location={item.position}
                      number={item.pillar}
                      stationName={item.stationName}
                      status={item.status}
                      onDelete={() => handleDeleteDevice(idx)}
                      onRepair={repaired}
                    />
                  );
                }else{
                  return (
                    <NewItemContentTableDevice key={idx} onRepair={repaired} idxKey={idx} onDelete={() => handleDeleteDevice(idx)} />
                  );
                }
                
              })
            }
          />
        </div>
        
        {/* table */}

        <span className="item-heading-managementdevicepage">Nhật kí vận hành</span>
        <div className="item-operation-history-managementdevicepage">
          <div className="item-operation-history-content-managementdevicepag">
            {_listmessage.map((messageForm, index)=>{
              return(
                <div key={messageForm.dateTime.toString+index}>
                  <span>{
                    moment(messageForm.dateTime).format("DD/MM/YY HH:mm:ss") + ": " + "[" + messageForm.user + "] " + messageForm.message
                  }</span>
                </div>
              );
            })}
          </div>

          <div className="item-operation-history-chat-managementdevicepage ">
            <input type="text" placeholder="Nhập nhật kí" className="input-form-operation-history-chat-managementdevicepage" color={'#FFFFFF'} value={_contentMessage} onChange={(e) => { _onChangeContentMessage(e.target.value)}} />
            <img src={sendMessage} style={{cursor:'pointer'}} onClick={handleSendMessage} />
          </div>
        </div>
    </>
  );
}

export { DetailManagementDevice };
