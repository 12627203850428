import { TrafficLightColor } from "./TrafficLightColor";
import './item-traffic.css';
import { EditIcon, GoCrossTraffic, StopCrossTraffic } from "../../assets";
import { useState } from "react";

export const ItemTraffic = ({ idx, name, count, type, phaseNum, stationId, updateNamePhase, dataSocket }) => {
    const [isEdit, setIsEdit] = useState(false);

    const handleUpdatePhase = (e) => {
        if (e.target.value.trim()) {
            updateNamePhase({
                stationId: stationId,
                phase: phaseNum,
                phaseName: e.target.value
            })
        }
        setIsEdit(false);
    }

    return (
      <div className="item-traffic-container">
        <div className="item-traffic-header">
          {!isEdit ? (
            <>
              <span className="item-traffic-header-label">Pha {idx + 1} :</span>
              <div className="d-flex align-items-center">
                <span className="item-traffic-header-name mr-3">
                  {name || "Name Pha"}
                </span>
                <div className="cursor-pointer" onClick={() => setIsEdit(true)}>
                  <EditIcon />
                </div>
              </div>
            </>
          ) : (
            <input
              onBlur={handleUpdatePhase}
              className="item-traffic-header-input"
            />
          )}
        </div>
        <div className="traffic-light-group">
          <TrafficLightColor value={dataSocket?.signstate === 2} color="red" />
          <TrafficLightColor
            value={dataSocket?.signstate === 3}
            color="yellow"
          />
          <TrafficLightColor
            value={dataSocket?.signstate === 1}
            color="green"
          />
        </div>

        <div className="d-flex justify-content-center align-items-center">
          <div className="countdown-content">
            <span className="countdown-label">Đếm ngược</span>
            <div className="countdown-input">
              <input
                className="input-form-channel-output-controlpanel"
                value={
                  dataSocket?.counter === 255 ? "---" : dataSocket?.counter
                }
                type={"text"}
                readOnly={true}
              />
            </div>
          </div>
          {/* <SignatureTraffic walking={walking}/> */}
          <div className="signature-content">
            <span className="signature-label">Tín hiệu</span>
            <div
              className={`traffic-${
                dataSocket?.signstate === 2 ? "green" : "red"
              }`}
            >
              {dataSocket?.signstate === 2 ? (
                <GoCrossTraffic color="#fff" />
              ) : (
                <StopCrossTraffic />
              )}
            </div>
          </div>
        </div>
      </div>
    );
}
