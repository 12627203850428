import React, { useEffect, useState } from "react";
import './components';

import { listParagraph, settingCollapse } from '../../assets';
import { ListStation, DetailManagementDevice } from './components';
import { SideBarCollased } from "../../components/SideBarCollapsed";
import { ListStationControlPanel } from "../ControlPanelPage/components";
import { ListStationOption } from "../../components/ControlPannelPage/ListStationOption";
import { StationService } from "../../services/StationService";


function ManagementDevicePage({}) {
    const [isAll, setIsAll] = useState(0);
    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [currentStation, setCurrentStation] = useState(null);
    const [isExpanded, setIsExpanded] = useState(true);
    const [textSearch, setTextSearch] = useState('');
    const stationService = new StationService();

    useEffect(async () => {
        const data = await stationService.getStations();
        if (data?.data?.stations) {
            setData(data?.data?.stations);
            setFilterData(data?.data?.stations);
            setCurrentStation(data?.data?.stations.length > 0 ? 0 : null);
        }
    }, []);

    const onSelectItem = (idx) => {
        setCurrentStation(idx);
    }

    const onSetItemOnList = (idx, value) => {
        let tempData = [...data];
        if (value === true) {
            tempData[idx].online = true;
            filterData[idx] = null;
        } else {
            tempData[idx].online = false;
            filterData[idx] = data[idx];
        }

        setData(tempData);
        setFilterData([...filterData]);
    }

    const onSelectOpionAll = (value, textSearch) => {
        if (value === 0) {
            setIsAll(value);
            setFilterData(data.filter(item => item.stationName.includes(textSearch)));
        } else {
            setIsAll(value);
            setFilterData(data.filter(item => item.online === (value === 1) && item.stationName.includes(textSearch)));
        }
    }

    const handleSearch = (value) => {
        onSelectOpionAll(isAll, value);
        setTextSearch(value);
    }

    const onConfirm = async (data) => {
        const result = await stationService.addStation(data);
        if (result?.data?.stations) {
            setData(result?.data?.stations);
            setFilterData(result?.data?.stations);
        }
        return true;
    }

    return (
        <>
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '30px',
                marginBottom: '30px'

            }}>
                <SideBarCollased isExpanded={isExpanded} setIsExpanded={setIsExpanded} 
                    children={<>
                        <div className="d-flex justify-content-between align-items-center w-100">
                            <div className="d-flex" >
                                <img src={listParagraph} />
                                <span className="controlpannel-hero">Danh sách trạm</span>
                            </div>
                            <img src={settingCollapse} style={{ cursor: 'pointer' }} onClick={() => { setIsExpanded(false) }} />
                        </div>
                        <ListStationOption onConfirm={onConfirm} isChoosen={isAll} onSelect={(value) => onSelectOpionAll(value, textSearch)} value={textSearch} setValue={handleSearch} />
                        <ListStationControlPanel highlightText={textSearch} currentStation={currentStation} onSelectItem={onSelectItem} listData={filterData} onSetItemList={onSetItemOnList} />
                    </>} 
                />

                <div style={{ width: '20px' }} ></div>

                <div className='content-table-managementdevicepage'>
                    <DetailManagementDevice/>
                </div>
            </div>
        </>
    );
}

export { ManagementDevicePage };